import { storage } from '../../../utils/storage';
import { STORAGE } from '../../../constants/storage';
import { MAP_MARKER_TYPES } from '../../../constants/map';

export const setUser = (state, data) => {
    state.user = data;
    storage.setItem(STORAGE.USER, JSON.stringify(data));
};

export const setToken = (state, token) => {
    state.token = token;
};

export const unsetToken = (state) => {
    state.token = null;
};

export const unsetUser = (state) => {
    // TODO stop using empty objects.. use null instead
    state.user = {};
    storage.removeItem(STORAGE.USER);
};

export const setUserMarkers = (state, markers) => {
    state.user[MAP_MARKER_TYPES.SINGLE] = markers;
};

export const setUserPaths = (state, paths) => {
    state.user[MAP_MARKER_TYPES.PATH] = paths;
};

export const setUserPolylines = (state, polylines) => {
    state.user[MAP_MARKER_TYPES.POLYLINE] = polylines;
};

export const setUserPolygons = (state, polygons) => {
    state.user[MAP_MARKER_TYPES.POLYGON] = polygons;
};

export const updateStore = (state) => {
    storage.setItem(STORAGE.USER, JSON.stringify(state.user));
};
