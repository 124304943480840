import axios from 'axios';
import loadUser from '@/js/mixins/loadUser';
import { API } from '../constants/api';

/**
 * Centralized logout method mixin with isolated business logic
 */
export default {
    mixins: [loadUser],
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        logout() {
            if (this.isLoading) return;
            this.isLoading = true;

            axios({
                url: API.LOGOUT,
                method: 'post',
            })
                .then(() => {
                    this.$store.dispatch('user/doLogout', this.$t('user.successfulLogout'));

                    this.registerSyntheticUser();
                })
                .catch((error) => {
                    if (error.response.status === 401) return;

                    this.$store.dispatch('doAlert', {
                        text: this.errorMessage(error),
                        type: 'error',
                    });
                })
                .then(() => {
                    this.isLoading = false;
                });
        },
    },
};
