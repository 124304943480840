<template>
    <div class="layout__list">
        <list
            :data="questions"
            :params="{cid: campaign.id}"
            class="list--question list--even"
            type="question"
            title-property="shortDescription"
        >
            <template #no-results>
                {{ $t('feelings.campaign.noQuestionsFound') }}
            </template>

            <template #prepend>
                <div
                    class="list__tile list__tile--dark-grey"
                >
                    <div class="list__wrapper list__wrapper--top align-center">
                        <div class="list__action">
                            <v-icon>
                                mdi-calendar
                            </v-icon>
                        </div>

                        <div class="list__content">
                            {{ $t('feelings.campaign.endsIn') }}
                            <strong>
                                {{ campaign.daysLeft }} {{ $t(daysWording(campaign.daysLeft)) }}
                            </strong>
                        </div>

                        <div class="list__content">
                            {{ $t('feelings.campaign.answers') }}
                            <strong>
                                {{ campaign.questionsAnswered }} / {{ campaign.questionsTotal }}
                            </strong>
                        </div>
                    </div>
                </div>
            </template>
        </list>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { API, getUrlWithParams } from '@/js/constants/api';
import { ROUTE } from '@/js/constants/route';

export default {
    props: {
        campaign: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            questions: [],
        };
    },
    computed: {
        ...mapState(['route']),
    },
    mounted() {
        this.getQuestions();
        this.$emit('on-set-title', this.campaign.name);
        this.$emit('on-refresh-campaign-details');
    },
    methods: {
        daysWording(value) {
            if (!value) {
                return '';
            }

            switch (+value) {
            case 1:
                return 'wording.day.day';

            case 2 || 3 || 4:
                return 'wording.day.days234';

            default:
                return 'wording.day.days';
            }
        },
        openDemographyScreen() {
            this.$nextTick(() => {
                if (!this.campaign.demographyAnswered
                    && this.campaign.questionsAnswered === 1
                    && this.route.from.name !== 'demography') {
                    this.$router.push({ name: ROUTE.DEMOGRAPHY });
                }
            });
        },
        async getQuestions() {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;
            this.$store.commit('addLayoutLoadingCount');

            const apiUrl = getUrlWithParams(API.QUESTIONS, this.$route.params);

            try {
                const { data } = await axios.get(apiUrl);
                this.questions = data;
            } catch (error) {
                this.$store.dispatch('doAlert', {
                    text: this.errorMessage(error),
                    type: 'error',
                });
            }

            this.isLoading = false;
            this.$store.commit('decreaseLayoutLoadingCount');
        },
    },
};
</script>
