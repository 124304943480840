import axios from 'axios';
import { storage } from '../../../utils/storage';
import { STORAGE } from '../../../constants/storage';

function setAuthorizationHeader(token, skipStorage = false) {
    if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        if (!skipStorage) {
            storage.setItem(STORAGE.TOKEN, token);
        }
    } else {
        delete axios.defaults.headers.common.Authorization;
        if (!skipStorage) {
            storage.removeItem(STORAGE.TOKEN);
        }
    }
}

setAuthorizationHeader(storage.getItem(STORAGE.TOKEN), true);

export function doLogin({ dispatch }, { token }) {
    setAuthorizationHeader(token);

    dispatch('doSetToken', token);
}

export function doSetToken({ commit }, token) {
    commit('setToken', token);
}

export function doUnsetToken({ commit }) {
    commit('unsetToken');
}

export function doSetUser({ commit }, data) {
    commit('setUser', data);
}

export function doLogout({ commit, dispatch }, message) {
    commit('unsetUser');
    dispatch('doUnsetToken');
    setAuthorizationHeader(null);

    if (message) {
        dispatch('doAlert', { text: message }, { root: true });
    }
}
