<template>
    <div class="progress">
        <div
            v-for="(n, index) in max"
            :key="index"
            class="progress__wrapper"
        >
            <div
                class="progress__item"
                :class="{'progress__item--active': n <= current}"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        max: {
            type: Number,
            default: 5,
        },

        current: {
            type: Number,
            default: 0,
        },
    },
};
</script>
