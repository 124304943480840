<template>
    <v-layout
        column
        fill-height
    >
        <v-flex
            shrink
            class="relative"
        >
            <header-component
                :back-route="backRoute"
                :title="title"
                :has-search="isQuestion"
                :campaign="campaign"
                @on-search-confirmation="onSearchConfirmation"
            >
                <template
                    v-if="this.$route.name === $options.ROUTE.QUESTION
                        && isQuestionDetailVisible"
                    #append
                >
                    <div
                        class="header__popup-toggle elevation-4"
                        @click.prevent="setQuestionDetailVisibility"
                    >
                        <v-icon
                            class="header__icon"
                            color="white"
                        >
                            mdi-chevron-down
                        </v-icon>
                    </div>
                </template>
            </header-component>

            <v-progress-linear
                v-if="layoutLoadingCount || localLoadingCount"
                class="my-0 progress--absolute"
                indeterminate
            ></v-progress-linear>
        </v-flex>

        <router-view
            v-if="campaign"
            v-show="!layoutLoadingCount"
            ref="routerView"
            :campaign="campaign"
            :selected-place="selectedPlace"
            @on-question-detail-visibility-change="onQuestionDetailVisibilityChange"
            @on-set-title="setTitle"
            @on-refresh-campaign-details="getCampaignDetails"
            @on-search-confirmation="onSearchConfirmation"
        ></router-view>
    </v-layout>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { API, getUrlWithParams } from '@/js/constants/api';
import { ROUTE } from '@/js/constants/route';
import daysLeft from '@/js/helpers/daysLeft';

export default {
    ROUTE,

    data() {
        return {
            campaign: null,
            isLoading: false,
            isQuestionDetailVisible: false,
            selectedPlace: null,
            title: null,
        };
    },
    computed: {
        ...mapState([
            'layoutLoadingCount',
            'localLoadingCount',
        ]),
        isQuestion() {
            return this.$route.name === ROUTE.QUESTION;
        },
        backRoute() {
            switch (this.$route.name) {
            default:
            case ROUTE.CAMPAIGN:
                return {
                    name: ROUTE.CAMPAIGNS,
                };
            case ROUTE.QUESTIONS:
            case ROUTE.DEMOGRAPHY:
                return {
                    name: ROUTE.CAMPAIGN,
                    params: this.$route.params,
                };
            case ROUTE.QUESTION:
                return {
                    name: ROUTE.QUESTIONS,
                    params: this.$route.params,
                };
            }
        },
    },
    mounted() {
        this.getCampaignDetails();
    },
    methods: {
        onSearchConfirmation(item) {
            this.selectedPlace = item;
        },
        setTitle(title) {
            this.title = title;
        },
        onQuestionDetailVisibilityChange(n) {
            this.isQuestionDetailVisible = n;
        },
        setQuestionDetailVisibility() {
            this.$refs.routerView.onSetQuestionDetilOpened(true);
        },
        async getCampaignDetails() {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;
            this.$store.commit('addLayoutLoadingCount');
            const apiUrl = getUrlWithParams(API.CAMPAIGN, this.$route.params);

            try {
                const { data } = await axios.get(apiUrl);
                data.daysLeft = daysLeft(data.endDate);

                if (data) {
                    this.campaign = data;

                    this.$nextTick(() => {
                        if (this.$refs.routerView.openDemographyScreen) {
                            this.$refs.routerView.openDemographyScreen();
                        }
                    });
                }
            } catch (error) {
                this.$store.dispatch('doAlert', {
                    text: this.errorMessage(error),
                    type: 'error',
                });
            }

            this.isLoading = false;
            this.$store.commit('decreaseLayoutLoadingCount');
        },
    },
};
</script>
