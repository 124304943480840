<template>
    <div class="alerts">
        <transition-group name="scroll-x-transition">
            <alert
                v-for="alert in alerts"
                :id="alert.id"
                :key="`alert-${alert.id}`"
                :text="alert.text"
                :type="alert.type"
                :multi="alert.multi"
                :html="alert.html"
                :timeout="alert.timeout"
            ></alert>
        </transition-group>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Alert from './Alert';

export default {
    components: {
        Alert,
    },
    computed: {
        ...mapState([
            'alerts',
        ]),
        isEmpty() {
            return Object.keys(this.alerts).length === 0;
        },
    },
};
</script>

<style lang="less">
@import '../../../less/variables';

.alerts {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10000;

    width: 100%;
    max-width: 550px;

    @media(max-width: @break-t-max) {
        bottom: auto;
        top: 60px;
    }
}
</style>
