export default {
    data() {
        return {
            formData: null,
            formErrors: {},
            formErrorMessage: null,
            formMessage: null,
            formIsLoading: false,
            formKey: 0,
        };
    },
    created() {
        this.formInit();
    },
    methods: {
        formInit() {
            // Overwrite this in component
            // this.formData = this.formGetDataValues(FORM.ITEMS);
        },
        formGetDataValues(items, model = null, defaults = {}) {
            return Object.values(items)
                .reduce((obj, item) => {
                    const { name: key } = item;

                    let value = '';
                    if (item.value != null) {
                        value = item.value;
                    }

                    if (defaults[key] != null) {
                        value = defaults[key];
                    }

                    if (model != null) {
                        if (model[key] != null) {
                            value = model[key];
                        }

                        const singular = key.replace('_id', '');
                        if (model[singular] && model[singular].id) {
                            value = model[singular].id;
                        }
                    }

                    obj[key] = value;
                    return obj;
                }, {});
        },
        formClearErrors() {
            this.formErrors = {};
            this.formErrorMessage = null;
        },
        formReset() {
            this.formKey += 1;
            this.formInit();
        },
    },
};
