<template>
    <div>
        <section class="wrapper intro wow fadeIn">
            <div class="container wow fadeInDown">
                <h1 class="intro__title">
                    {{ $t('pages.about.title') }}
                </h1>
                <!--                <p class="intro__content">-->
                <!--                    {{ $t('pages.about.subtitle') }}-->
                <!--                </p>-->
            </div>
        </section>

        <section class="wrapper section section--00">
            <div class="container">
                <div class="section__row section__row--reverse">
                    <div class="bubble wow fadeInDown bubble--extreme"></div>

                    <div class="section__column">
                        <div class="section__container">
                            <h2 class="section__title">
                                {{ $t('pages.about.titleDagmar') }}
                            </h2>
                            <!--eslint-disable-next-line vue/no-v-html-->
                            <div v-html="$t('pages.about.contentDagmar')"></div>
                        </div>
                    </div>
                    <div class="section__column">
                        <div class="bubble wow fadeInDown bubble--small bubble--bottom"></div>

                        <div
                            class="section__image wow fadeInUp"
                            data-wow-offset="50"
                            data-wow-duration="700"
                        >
                            <img
                                src="@/assets/about/dagmar.jpeg"
                                alt=""
                            >
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="wrapper section section--01">
            <div class="container">
                <div class="section__row">
                    <div class="bubble wow fadeInDown bubble--big bubble--left"></div>

                    <div class="section__column">
                        <div class="section__container">
                            <h2 class="section__title">
                                {{ $t('pages.about.titleBurak') }}
                            </h2>
                            <!--eslint-disable-next-line vue/no-v-html-->
                            <div v-html="$t('pages.about.contentBurak')"></div>
                        </div>
                    </div>
                    <div class="section__column">
                        <div class="bubble wow fadeInDown bubble--small bubble--bottom"></div>

                        <div
                            class="section__image wow fadeInUp"
                            data-wow-offset="50"
                            data-wow-duration="700"
                        >
                            <img
                                src="@/assets/about/burak.jpeg"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="wrapper section section--02">
            <div class="container">
                <div class="section__row section__row--reverse">
                    <div class="bubble wow fadeInDown bubble--extreme"></div>

                    <div class="section__column">
                        <div class="section__container">
                            <h2 class="section__title">
                                {{ $t('pages.about.titleNovacek') }}
                            </h2>
                            <!--eslint-disable-next-line vue/no-v-html-->
                            <div v-html="$t('pages.about.contentNovacek')"></div>
                        </div>
                    </div>
                    <div class="section__column">
                        <div class="bubble wow fadeInDown bubble--small bubble--bottom"></div>

                        <div
                            class="section__image wow fadeInUp"
                            data-wow-offset="50"
                            data-wow-duration="700"
                        >
                            <img
                                src="@/assets/about/Novacek 2.jpeg"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ecarewave from '@/js/mixins/ecarewave';

export default {
    name: 'EcarewaveAbout',
    mixins: [ecarewave],
};
</script>

<style
    lang="less"
    scoped
>
@import (reference) '../../less/variables';
@import '../../less/pages/home/app';

.main-wrapper {
    width: 100%;

    color: #728695;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 1.67;
    -webkit-font-smoothing: antialiased;

    @media (min-width: @break-t-min) {
        font-size: 18px;
    }
}

a.v-btn {
    text-decoration: none !important;
}

.section__image {
    max-width: 700px;

    img {
        width: 100%;
        display: block;
    }
}
</style>
