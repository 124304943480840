<template>
    <v-layout
        fill-height
        class="home"
        :class="{'home--menu-opened': isMenuOpened}"
    >
        <v-flex>
            <div class="home__header-home">
                <header-home
                    :embed="embedLayout"
                    @on-search-confirmation="onSearchConfirmation"
                ></header-home>
            </div>

            <div class="home__map d-flex">
                <map-layer-control
                    :subjective-data="subjectiveData"
                    :objective-data="objectiveData"
                    :active-layers="activeLayers"
                    :has-layers="hasLayers"
                >
                    <v-progress-circular
                        v-if="isLoading"
                        size="25"
                        indeterminate
                    ></v-progress-circular>
                    <v-icon
                        v-else
                        size="18"
                    >
                        icon-layers-icon
                    </v-icon>
                </map-layer-control>

                <map-timeline
                    :date-from="query.dateFrom"
                    :date-to="query.dateTo"
                    :has-layers="hasLayers"
                    @set-timeline="updateLayers"
                ></map-timeline>

                <leaflet-map
                    :layers="layers"
                    :active-layers="activeLayers"
                    :selected-place="selectedPlace"
                    :data="mapData"
                    class="leaflet--home"
                    @open-menu="onMenuOpen"
                    @ready="fetchData"
                ></leaflet-map>
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
import {
    MAP_OPTIONS,
    MAP_CONTROL_ITEMS_DEFAULT,
    MAP_INSTANCE, LAYER_TAGS,
} from '@/js/constants/map';
import { API, getApi } from '@/js/constants/api';
import axios from 'axios';
import mapLayerMixin from '@/js/mixins/mapLayerMixin';
import MapLayerControl from '@/js/components/map/MapLayerControl';
import MapTimeline from '@/js/components/map/MapTimeline';
import qs from 'qs';

/**
 * Map view
 *
 * Query params:
 * embed {boolean} - Will change design to embed template
 *                   without menu and other action buttons
 * layerIds {Array} - Ids of layers separated by "," that will
 *              build the layers menu
 * layerDataIds {Array} - Ids of layers separated by ","
 *                  that should show data on map on init
 * center {Number}- "latitude,longitude" in this definition
 */
export default {
    MAP_OPTIONS,
    MAP_CONTROL_ITEMS_DEFAULT,
    MAP_INSTANCE,
    components: {
        MapLayerControl,
        MapTimeline,
    },
    mixins: [mapLayerMixin],
    data() {
        const query = { ...this.$route.query };

        return {
            mapData: null,
            selectedPlace: null,
            embedLayout: false,
            isMenuOpened: false,
            subjectiveData: null,
            objectiveData: null,
            query,
            layers: null,
            isLoading: false,
            activeLayers: {},
        };
    },
    computed: {
        hasLayers() {
            return this.activeLayers
                && Object.keys(this.activeLayers).length !== 0;
        },
    },
    created() {
        this.embedLayout = !!this.query.embed;
        this.$root.$on('show-layer', this.showLayer);
    },
    beforeDestroy() {
        this.$root.$off('show-layer', this.showLayer);
    },
    methods: {
        fetchData() {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;

            const axiosData = getApi(API.LAYERS);
            axiosData.params = this.query;

            axios(axiosData)
                .then(({ data: { layers } }) => {
                    if (layers) {
                        this.layers = layers;
                        this.setSubjectiveData(layers);
                        this.setObjectiveData(layers);
                    }

                    if (this.query.layerDataIds) {
                        this.updateLayers(null, true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    if (!this.query.layerDataIds) {
                        this.isLoading = false;
                    }
                });
        },
        updateLayers(dates, init = false) {
            this.isLoading = true;

            this.query = {
                ...this.query,
                ...dates,
            };
            const { path } = this.$route;

            if (!init) {
                const newUrl = `${path}?${qs.stringify(this.query)}`;
                const oldUrl = `${path}?${qs.stringify(this.$route.query)}`;

                if (newUrl === oldUrl) {
                    this.isLoading = false;
                    return;
                }

                this.updateQuery(this.query);
            }

            this.loadLayers(
                this.getLayers(this.query.layerDataIds),
                this.query,
                !init,
            );
        },
        setSubjectiveData(layers) {
            this.subjectiveData = layers.filter(
                (item) => item.tags.find((tag) => tag === LAYER_TAGS.SUBJECTIVE),
            );
        },
        setObjectiveData(layers) {
            this.objectiveData = layers.filter(
                (item) => item.tags.find((tag) => tag === LAYER_TAGS.OBJECTIVE),
            );
        },
        onMenuOpen(value) {
            this.isMenuOpened = value;
        },
        onSearchConfirmation(item) {
            this.selectedPlace = item;
        },
        localizeMapControls(options) {
            options.filter((item) => item.name === 'Zoom')
                .forEach((item) => {
                    // TODO: Toto riesenie nie je dostatocne robustne a
                    //  pri prepinani jazykov vypisuje chybu:
                    //  [vue-i18n] Value of key 'Zoom out' is not a string!
                    item.options.forEach((o) => {
                        if (o && o.titles && o.titles.length > 0) {
                            o.titles = o.titles.map((txt) => this.$t(txt));
                        }
                    });
                });

            return options;
        },
    },
};
</script>
