<template>
    <v-speed-dial
        v-model="toggler"
        color="primary"
        direction="top"
        transition="slide-y-reverse-transition"
    >
        <template #activator>
            <!--eslint-disable max-len-->
            <v-btn
                v-if="canSave"
                color="secondary"
                dark
                fab
                v-on="!canSave
                    ? { click: preventAnyAction }
                    : { click: showDialog }"
            >
                <v-icon>{{ $options.MISC_ICONS.MARKER_CONTROLS_CONFIRM }}</v-icon>
            </v-btn>
            <!--eslint-enable max-len-->

            <v-btn
                v-else
                color="primary"
                class="speed-dial__button"
                :class="{
                    'v-btn--disabled reset-pointer-events cursor-default': speedDialDisabled
                }"
                dark
                fab
                v-on="speedDialDisabled
                    ? { click: preventAnyAction }
                    : null"
            >
                <v-icon v-if="!toggler">
                    {{ getIconByActiveType }}
                </v-icon>
                <v-icon v-else>
                    mdi-arrow-down
                </v-icon>
            </v-btn>
        </template>

        <v-btn
            v-for="(item, index) in question.markers"
            :key="index"
            :color="isButtonEnabled($options.MAP_MARKER_TYPE_API_PAIRINGS[item.type].TYPE)
                ? 'secondary'
                : 'primary'"
            :disabled="isButtonEnabled($options.MAP_MARKER_TYPE_API_PAIRINGS[item.type].TYPE)"
            class="mb-4"
            fab
            small
            @click="prepareMarkerType(
                $options.MAP_MARKER_TYPE_API_PAIRINGS[item.type].TYPE
            )"
        >
            <v-icon
                color="white"
                size="18"
            >
                {{ $options.MAP_MARKER_TYPE_API_PAIRINGS[item.type].ICON }}
            </v-icon>
        </v-btn>
    </v-speed-dial>
</template>

<script>
import { MARKER_ICONS, MISC_ICONS } from '@/js/constants/icons';
import { MAP_MARKER_TYPES, MAP_MARKER_TYPE_API_PAIRINGS } from '../constants/map';

export default {
    MAP_MARKER_TYPE_API_PAIRINGS,
    MAP_MARKER_TYPES,
    MARKER_ICONS,
    MISC_ICONS,

    props: {
        map: {
            type: Object,
            default: null,
        },
        mapMethods: {
            type: Object,
            default: () => ({}),
        },
        markers: {
            type: Array,
            default: () => ([]),
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 0,
        },
        question: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: null,
        },
        activeMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isSelecting: false,
            toggler: false,
        };
    },
    computed: {
        canSave() {
            const markersLength = this.markers.length;

            return markersLength >= this.min && markersLength !== 0;
        },
        isButtonEnabled() {
            return type => (this.type === type)
                && this.activeMode;
        },
        getIconByActiveType() {
            const icon = this.canSave
                ? MISC_ICONS.MARKER_CONTROLS_CONFIRM
                : MARKER_ICONS[this.type && this.type.toUpperCase()];

            return this.type
                ? icon
                : MISC_ICONS.MARKER_CONTROLS_DEFAULT;
        },
        speedDialDisabled() {
            return this.markers.length > 0;
        },
    },
    watch: {
        speedDialDisabled(state) {
            this.$parent.$emit('on-speed-dial-disabled-change', state);
        },
        canSave(n) {
            if (n) {
                this.toggler = false;
            }
        },
    },
    methods: {
        createMarker(ev) {
            this.$store.dispatch('markers/prepareCreateMarker', ev);

            this.toggler = false;
        },
        prepareMarkerType(type) {
            this.$nextTick(() => {
                this.$emit('ready-marker-type', type);
            });
        },
        showDialog(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            this.$emit('submit-answer-markers', {
                openDialogue: true,
                saveAnswer: true,
                markers: this.markers,
            });
        },
        closeSpeedDial(ev) {
            this.toggler = ev;
        },
        preventAnyAction(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            this.toggler = false;
        },
        resetSpeedDial() {
            this.isSelecting = false;
            this.toggler = false;
        },
    },
};
</script>
