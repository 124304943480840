import { storage } from '../../../utils/storage';
import { STORAGE } from '../../../constants/storage';

export const setPosition = (state, data) => {
    state.position = { ...state.position, ...data };
    storage.setItem(STORAGE.MAP_POS, JSON.stringify(state.position));
};

export const setZoom = (state, data) => {
    state.zoom = { ...state.zoom, ...data };
    storage.setItem(STORAGE.MAP_ZOOM, JSON.stringify(state.zoom));
};
