import { storage } from '@/js/utils/storage';
import { STORAGE } from '@/js/constants/storage';
import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';

const state = {
    position: JSON.parse(storage.getItem(STORAGE.MAP_POS)) || null,
    zoom: JSON.parse(storage.getItem(STORAGE.MAP_ZOOM)) || null,
};

export default {
    state,
    getters,
    actions,
    mutations,
};
