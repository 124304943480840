<template>
    <div
        v-if="map"
        class="marker-controls"
        data-test="marker-controls"
    >
        <speed-dial
            v-if="controls"
            v-show="!isDisabled"
            ref="speed-dial"
            :map="map"
            :map-methods="mapMethods"
            :markers="activeMarkers || []"
            :min="activeMin"
            :max="activeMax"
            :type="markerType"
            :active-mode="activeMode"
            :question="question"
            data-test="speed-dial"
            @ready-marker-type="readyMarkerType"
            @submit-answer-markers="onSubmitAnswer"
        ></speed-dial>

        <div class="marker-controls__stop">
            <v-btn
                v-show="isDeleteMode && !isDisabled"
                :color="'error'"
                fab
                small
                data-test="marker-controls-delete-button"
                @click.prevent="deletePoints"
            >
                <v-icon
                    size="22"
                >
                    mdi-delete
                </v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import MarkerSingle from '@/js/components/markers/Marker';
import MarkerPath from '@/js/components/markers/MarkerPath';
import MarkerPolyline from '@/js/components/markers/MarkerPolyline';
import MarkerPolygon from '@/js/components/markers/MarkerPolygon';
import { MAP_MARKER_TYPES, MAP_MARKERS_COLORS } from '@/js/constants/map';
import { ALERT_TYPES } from '@/js/constants/alert';

const MARKER_PAIRING = {
    point: MarkerSingle,
    path: MarkerPath,
    linestring: MarkerPolyline,
    polygon: MarkerPolygon,
};

export default {
    props: {
        map: {
            type: Object,
            default: null,
        },
        mapMethods: {
            type: Object,
            default: () => ({}),
        },
        geometryLayer: {
            type: Object,
            default: () => ({}),
        },
        answers: {
            type: Array,
            default: () => ([]),
        },
        markerType: {
            type: String,
            default: null,
        },
        question: {
            type: Object,
            default: () => ({}),
        },
        searchLayerEnable: {
            type: Boolean,
            default: false,
        },
        controls: {
            type: Boolean,
            default: true,
        },
        answerDialogue: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isDisabled: false,
            isInitialized: false,
            activeId: null,
            activeMode: false,
            point: {
                array: [],
                min: null,
                max: null,
            },
            linestring: {
                array: [],
                min: null,
                max: null,
            },
            polygon: {
                array: [],
                min: null,
                max: null,
            },
            signal: null,
            markerLayer: null,
            geometryLayerById: null,
            activeGeometryLayer: null,
            search: {
                layer: null,
                marker: null,
            },
        };
    },
    computed: {
        ...mapState(['alertCount']),
        activeMarkers() {
            const type = this.markerType !== '' && this.markerType;
            const singleArrayObject = type && this[type].array
                .find((item) => item.id === this.activeId);

            return singleArrayObject && singleArrayObject.markers;
        },
        activeMin() {
            const type = this.markerType !== '' && this.markerType;

            return type && this[type].min;
        },
        activeMax() {
            const type = this.markerType !== '' && this.markerType;

            return type && this[type].max;
        },
        isDeleteMode() {
            return !!(this.activeMarkers
                && this.activeMarkers.length > 0);
        },
        cantAddMorePoints() {
            return this.activeMarkers
                && this.activeMarkers.length >= this.activeMax;
        },
    },
    watch: {
        activeId(n) {
            this.$emit('on-active-id-change', n);
        },
    },
    mounted() {
        if (!this.isInitialized) {
            this.isInitialized = true;
            this.initControls();
            this.showAvailableMarkers();
        }
    },
    beforeDestroy() {
        if (this.signal) {
            this.signal.removeListener(this.signal.id);
        }

        this.$off('remove-marker', this.removeMarker);
        this.$off('change-answer-instance', this.onChangeInstance);
    },
    methods: {
        initControls() {
            if (this.mapMethods) {
                this.$on('change-answer-instance', this.onChangeInstance);
                this.$on('remove-marker', this.removeMarker);

                this.setMarkerSettings();
                this.createMarkerLayer();
                this.createActiveGeometryLayer();

                if (this.searchLayerEnable) {
                    this.createSearchLayer();
                }
            }
        },
        cantAddPointsMethod() {
            return !!this.activeMarkers
                && (this.activeMarkers.length >= this.activeMax);
        },
        onSubmitAnswer(ev) {
            if (ev.saveAnswer) {
                this.$emit('submit-answer-markers', ev);

                this.activeMode = true;
            } else {
                this.setButtonDisabled();
            }
        },
        setMarkerSettings() {
            if (this.question.markers) {
                this.question.markers.forEach((marker) => {
                    this[marker.type.toLowerCase()].min = marker.minVerts;
                    this[marker.type.toLowerCase()].max = marker.maxVerts;
                });
            }
        },
        setButtonDisabled() {
            this.activeMode = false;
            this.isDisabled = true;
            this.$emit('update:markerType', null);
        },
        resetButton() {
            this.isDisabled = false;
        },
        onSetNewInstance() {
            this.removeActiveGeometry({ id: this.activeId, active: true });
            this.resetButton();

            this.activeId = null;
            this.activeMode = true;

            this.$emit('update:markerType', null);
            this.$refs['speed-dial'].resetSpeedDial();
        },
        createMarkerLayer() {
            this.markerLayer = new SMap.Layer.Marker();

            this.mapMethods.addAdditionalLayer(this.markerLayer);
            this.markerLayer.enable();
        },
        createActiveGeometryLayer() {
            this.activeGeometryLayer = new SMap.Layer.Geometry();

            this.mapMethods.addAdditionalLayer(this.activeGeometryLayer);
            this.activeGeometryLayer.enable();
        },
        onEnableMarkerControls() {
            this.isDisabled = false;
            // this.activeId = id;

            this.$nextTick(() => {
                this.activeMode = !this.cantAddPointsMethod();
            });
        },
        showAvailableMarkers() {
            this.answers.forEach((answer) => {
                const { entity } = answer;

                if (entity) {
                    const type = entity && entity.locationType.toLowerCase();
                    const { id } = answer;
                    const coordsRaw = entity.coordinates;

                    coordsRaw.forEach((coord) => {
                        const coords = SMap.Coords.fromWGS84(coord.x, coord.y);

                        this.createPoint({
                            coords, type, id, initial: true,
                        });
                    });
                }
            });
        },
        createPoint({
            coords,
            initial,
            id,
            type,
        }) {
            const position = coords;
            const markerObjectArray = this[type].array;
            const processedId = id || this[type].activeId;
            let singleArrayObject = markerObjectArray.find((item) => item.id === processedId);

            if (!singleArrayObject) {
                if (type === MAP_MARKER_TYPES.PATH) {
                    markerObjectArray.push({
                        points: [],
                        markers: [],
                        id: processedId,
                    });

                    singleArrayObject = markerObjectArray.find((item) => item.id === processedId);

                    this.createMarker({
                        pos: position,
                        component: MARKER_PAIRING[type],
                        type,
                        parentId: processedId,
                        singleArrayObject,
                        initial,
                    });
                } else if (type === MAP_MARKER_TYPES.POLYLINE) {
                    markerObjectArray.push({
                        points: [],
                        markers: [],
                        id: processedId,
                    });

                    singleArrayObject = markerObjectArray.find((item) => item.id === processedId);
                } else if (type === MAP_MARKER_TYPES.POLYGON) {
                    markerObjectArray.push({
                        points: [],
                        markers: [],
                        id: processedId,
                    });

                    singleArrayObject = markerObjectArray.find((item) => item.id === processedId);
                } else {
                    markerObjectArray.push({
                        markers: [],
                        id: processedId,
                    });

                    singleArrayObject = markerObjectArray.find((item) => item.id === processedId);
                }
            } else if (singleArrayObject.markers.length === 0
                && type === MAP_MARKER_TYPES.PATH) {
                this.createMarker({
                    pos: position,
                    component: MARKER_PAIRING[type],
                    type,
                    parentId: processedId,
                    singleArrayObject,
                    initial,
                });
            }

            if (type === MAP_MARKER_TYPES.PATH) {
                singleArrayObject.points.push(position);

                if (singleArrayObject.points.length > 1) {
                    this.setPath({
                        id,
                        singleArrayObject,
                        initial,
                    });
                }

                return;
            }

            if (type === MAP_MARKER_TYPES.POLYLINE) {
                this.preparePolyline({
                    singleArrayObject,
                    id,
                    position,
                    initial,
                });
            }

            if (type === MAP_MARKER_TYPES.POLYGON) {
                this.preparePolygon({
                    singleArrayObject,
                    id,
                    position,
                    initial,
                });
            }

            this.createMarker({
                pos: position,
                component: MARKER_PAIRING[type],
                type,
                parentId: processedId,
                singleArrayObject,
                initial,
            });

            if (type === MAP_MARKER_TYPES.POLYLINE) {
                this.createPolyline({
                    singleArrayObject,
                    id,
                    initial,
                });
            }

            if (type === MAP_MARKER_TYPES.POLYGON) {
                this.createPolygon({
                    singleArrayObject,
                    id,
                    initial,
                });
            }
        },
        preparePolyline({
            singleArrayObject,
            position,
        }) {
            if (singleArrayObject) {
                singleArrayObject.points.push(position);
            }
        },
        createPolyline({
            isRemoval = false,
            id = null,
            singleArrayObject,
            initial,
        }) {
            const { points } = singleArrayObject;
            const getActivePoly = this.geometryLayer.getGeometries()[id];
            const getActivePolyDupl = this.activeGeometryLayer.getGeometries()[id];
            const options = {
                color: MAP_MARKERS_COLORS.ACTIVE,
                outlineWidth: 0,
                width: 4,
            };
            const optionsInactive = {
                color: MAP_MARKERS_COLORS.INACTIVE,
                outlineWidth: 0,
                width: 4,
            };

            if (!isRemoval) {
                const polyline = new SMap.Geometry(
                    SMap.GEOMETRY_POLYLINE,
                    id,
                    points,
                    optionsInactive,
                );

                if (initial) {
                    this.geometryLayer.addGeometry(polyline);
                } else {
                    const newPolyline = new SMap.Geometry(
                        SMap.GEOMETRY_POLYLINE,
                        id,
                        points,
                        options,
                    );

                    this.activeGeometryLayer.addGeometry(newPolyline);
                    this.geometryLayer.addGeometry(polyline);
                }
            } else if (getActivePolyDupl && getActivePoly) {
                getActivePolyDupl.setCoords(points);
                getActivePoly.setCoords(points);
            }
        },
        preparePolygon({
            singleArrayObject,
            position,
        }) {
            if (singleArrayObject) {
                singleArrayObject.points.push(position);
            }
        },
        createPolygon({
            isRemoval = false,
            id = null,
            singleArrayObject,
            initial,
        }) {
            const { points } = singleArrayObject;
            const getActivePoly = this.geometryLayer.getGeometries()[id];
            const getActivePolyDupl = this.activeGeometryLayer.getGeometries()[id];
            const options = {
                color: MAP_MARKERS_COLORS.ACTIVE,
                outlineColor: MAP_MARKERS_COLORS.ACTIVE,
            };
            const optionsInactive = {
                color: MAP_MARKERS_COLORS.INACTIVE,
                outlineColor: MAP_MARKERS_COLORS.INACTIVE,
            };

            if (!isRemoval) {
                const polygon = new SMap.Geometry(
                    SMap.GEOMETRY_POLYGON,
                    id,
                    points,
                    optionsInactive,
                );

                if (initial) {
                    this.geometryLayer.addGeometry(polygon);
                } else {
                    const newPolygon = new SMap.Geometry(
                        SMap.GEOMETRY_POLYGON,
                        id,
                        points,
                        options,
                    );

                    this.activeGeometryLayer.addGeometry(newPolygon);
                    this.geometryLayer.addGeometry(polygon);
                }
            } else if (getActivePolyDupl && getActivePoly) {
                getActivePolyDupl.setCoords(points);
                getActivePoly.setCoords(points);
            }
        },
        setPath({
            id,
            isRemoval = false,
            singleArrayObject,
            initial,
        }) {
            const type = MAP_MARKER_TYPES.PATH;

            SMap.Route.route(
                singleArrayObject.points, {
                    geometry: true,
                    criterion: 'turist1',
                },
            )
                .then((route) => {
                    this.setRoute({
                        singleArrayObject,
                        id,
                        route,
                        isRemoval,
                        type,
                        initial,
                    });
                })
                .catch((er) => {
                    // eslint-disable-next-line no-console
                    console.log(er);
                });
        },
        setRoute({
            route,
            id = null,
            isRemoval = false,
            singleArrayObject,
            type,
            initial,
        }) {
            const coords = route.getResults().geometry;

            if (route.getResults().error) {
                return;
            }
            if (!isRemoval) {
                this.createMarker({
                    pos: coords[coords.length - 1],
                    component: MARKER_PAIRING[type],
                    type,
                    parentId: id,
                    singleArrayObject,
                    initial,
                });
            }

            if (!isRemoval && singleArrayObject.markers.length >= 2) {
                const geometry = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, id, coords);
                const activeGeometry = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, id, coords);

                if (initial) {
                    geometry.setOptions({ color: MAP_MARKERS_COLORS.INACTIVE });

                    this.geometryLayer.addGeometry(geometry);
                } else {
                    geometry.setOptions({ color: MAP_MARKERS_COLORS.INACTIVE });
                    activeGeometry.setOptions({ color: MAP_MARKERS_COLORS.ACTIVE });

                    this.geometryLayer.addGeometry(geometry);
                    this.activeGeometryLayer.addGeometry(activeGeometry);
                }
            } else {
                const getActivePath = this.activeGeometryLayer.getGeometries()[id];
                const getInactivePathDuplicate = this.geometryLayer.getGeometries()[id];

                if (getActivePath && getInactivePathDuplicate) {
                    if (singleArrayObject.markers.length < 2) {
                        this.activeGeometryLayer.removeGeometry(getActivePath);
                        this.geometryLayer.removeGeometry(getActivePath);
                    } else {
                        getActivePath.setCoords(coords);
                        getInactivePathDuplicate.setCoords(coords);
                    }
                }
            }

            if (singleArrayObject.markers.length === 2) {
                singleArrayObject.markers[0].setCoords(coords[0]);
            }
        },
        createMarker({
            pos,
            type,
            id = null,
            parentId = null,
            component = null,
            plain = false,
            singleArrayObject,
            initial = false,
        }) {
            let position = pos;

            if (plain) {
                position = SMap.Coords.fromWGS84(pos.x, pos.y);
            }

            const options = {
                type,
                parentId,
                activeId: this.activeId,
                activeMode: this.activeMode,
                canDelete: !initial,
            };

            const marker = this.createMarkerComponent(position, id, component, options);

            this.markerLayer.addMarker(marker);

            singleArrayObject.markers.push(marker);
        },
        readyMarkerType(type) {
            const id = this.activeId || `${type}-${Math.floor(Math.random() * 999) + 100}`;

            this.activeMode = true;
            this.activeId = id;
            this.$emit('update:markerType', type);

            this.removeActiveGeometry({ id: this.activeId });
            this.removeActiveGeometry({ id: this.activeId, active: true });

            this.setSignals();
        },
        setSignals() {
            if (!this.signal) {
                this.signal = this.map.getSignals();
            }

            if (!this.signal.id) {
                this.$nextTick(() => {
                    this.signal.id = this.signal.addListener(
                        window, 'map-click',
                        this.initCreateMarker,
                    );
                });
            }
        },
        initCreateMarker(ev) {
            if (this.answerDialogue) {
                return;
            }

            if (this.activeMode && this.cantAddMorePoints) {
                this.activeMode = false;

                if (this.alertCount) {
                    return;
                }

                this.$store.dispatch('doAlert', {
                    text: this.$t('feelings.marker.limitReached'),
                    type: ALERT_TYPES.INFO,
                });

                return;
            }

            this.prepareCreateMarker(ev);
            this.$refs['speed-dial'].closeSpeedDial();
        },
        prepareCreateMarker(ev) {
            if (!this.activeMode) return;

            const coords = SMap.Coords.fromEvent(ev.data.event, this.map);

            this.createPoint({
                coords,
                id: this.activeId,
                type: this.markerType,
            });
        },
        onChangeInstance(ev) {
            const geometries = this.geometryLayer.getGeometries();

            this.$emit('change-answer-instance-up', ev);

            this.removeActiveGeometry({
                id: this.activeId,
                active: true,
            });

            this.activeId = ev.id;

            this.activeMode = ev.isNotSaved;
            this.isDisabled = !ev.isNotSaved;
            this.$emit('update:markerType', ev.type);

            const activeGeometry = geometries[this.activeId];

            if (activeGeometry) {
                const coords = activeGeometry.getCoords();
                let geometry = null;

                if (ev.type === MAP_MARKER_TYPES.PATH) {
                    geometry = new SMap.Geometry(
                        SMap.GEOMETRY_POLYLINE,
                        this.activeId,
                        coords,
                    );
                } if (ev.type === MAP_MARKER_TYPES.POLYLINE) {
                    geometry = new SMap.Geometry(
                        SMap.GEOMETRY_POLYLINE,
                        this.activeId,
                        coords,
                    );
                } else {
                    geometry = new SMap.Geometry(
                        SMap.GEOMETRY_POLYGON,
                        this.activeId,
                        coords,
                    );
                }

                geometry.setOptions({ color: MAP_MARKERS_COLORS.ACTIVE });
                this.activeGeometryLayer.addGeometry(geometry);

                if (ev.type === MAP_MARKER_TYPES.POLYLINE) {
                    geometry.setOptions({
                        color: MAP_MARKERS_COLORS.ACTIVE,
                        outlineWidth: 0,
                        width: 4,
                    });
                }

                if (ev.type === MAP_MARKER_TYPES.POLYGON) {
                    geometry.setOptions({ outlineColor: MAP_MARKERS_COLORS.ACTIVE });
                }
            }
        },
        createMarkerComponent(position, id, component, options) {
            const markerWrapper = document.createElement('div');
            const marker = new SMap.Marker(position, id, { url: markerWrapper });

            // Create component instance
            const container = marker.getContainer();
            const Component = Vue.extend(component);
            const theData = {
                ...options,
                marker,
                activeId: this.activeId,
            };

            const instance = new Component({
                propsData: theData,

                parent: this,
            });

            instance.$mount();
            container['3'].appendChild(instance.$el);

            return marker;
        },
        removeMarker({
            id,
            parentId,
            type,
        }) {
            const singleArray = this[type].array;
            const singleArrayObjectIndex = singleArray.findIndex((item) => item.id === parentId);
            const singleArrayObject = singleArray[singleArrayObjectIndex];
            const markerIndex = singleArrayObject.markers
                .findIndex((item) => item.getId() === id);

            this.markerLayer.removeMarker(singleArrayObject.markers[markerIndex]);
            singleArrayObject.markers.splice(markerIndex, 1);

            if (type === MAP_MARKER_TYPES.PATH
                || type === MAP_MARKER_TYPES.POLYLINE
                || type === MAP_MARKER_TYPES.POLYGON) {
                singleArrayObject.points.splice(markerIndex, 1);

                if (this.activeMarkers.length <= 1) {
                    this.removeActiveGeometry({ id: this.activeId });
                    this.removeActiveGeometry({ id: this.activeId, active: true });
                } else if (type === MAP_MARKER_TYPES.PATH) {
                    this.setPath({ id: parentId, isRemoval: true, singleArrayObject });
                } else if (type === MAP_MARKER_TYPES.POLYLINE) {
                    this.createPolyline({ isRemoval: true, id: parentId, singleArrayObject });
                } else if (type === MAP_MARKER_TYPES.POLYGON) {
                    this.createPolygon({ isRemoval: true, id: parentId, singleArrayObject });
                }
            }

            if (!this.cantAddMorePoints) {
                this.activeMode = true;
            }
        },
        setMarkersFromBe(data) {
            const geometries = this.geometryLayer.getGeometries();
            const activeGeometries = this.activeGeometryLayer.getGeometries();
            const geometry = geometries[this.activeId];
            const activeGeometry = activeGeometries[this.activeId];
            const singleArrayObject = this[data.entity.locationType.toLowerCase()];
            const singleArrayItem = singleArrayObject.array.find(
                (item) => item.id === this.activeId,
            );

            singleArrayItem.id = data.id;

            if (geometry && activeGeometry) {
                this.geometryLayer
                    .removeGeometry(geometry);
                this.activeGeometryLayer
                    .removeGeometry(activeGeometry);

                // eslint-disable-next-line no-underscore-dangle
                geometry._id = data.id;
                // eslint-disable-next-line no-underscore-dangle
                activeGeometry._id = data.id;

                this.geometryLayer.addGeometry(geometry);
                this.activeGeometryLayer.addGeometry(activeGeometry);
            }

            this.$children.forEach((child) => {
                if (child.parentId === this.activeId) {
                    child.state.parentId = data.id;
                    child.state.canDelete = false;
                }
            });

            this.activeId = data.id;
        },
        removeActiveGeometry({ id, active }) {
            const geometryObject = active ? this.activeGeometryLayer : this.geometryLayer;
            const geometries = geometryObject.getGeometries();
            const activeGeometry = geometries[id || this.activeId];

            if (activeGeometry) {
                geometryObject
                    .removeGeometry(activeGeometry);
            }
        },
        deletePoints() {
            const markerObject = this[this.markerType].array;
            const singleArrayObjectIndex = markerObject
                .findIndex((item) => item.id === this.activeId);

            if (singleArrayObjectIndex !== -1) {
                this.$children.filter((item) => item.isActive)
                    .forEach((item) => {
                        item.removeMarker();
                    });

                markerObject.splice(singleArrayObjectIndex, 1);
            }
        },
        addSearchMarker(options) {
            let marker = null;

            if (options) {
                const point = new SMap.Coords(options.longitude, options.latitude);
                marker = new SMap.Marker(point, null, {
                    title: options.title,
                });
            }

            if (this.search.marker) {
                this.search.layer.removeMarker(this.search.marker);
            }

            if (marker) {
                this.search.layer.addMarker(marker);
                this.map.setCenterZoom(marker.getCoords(), 16, true);
            }
            this.search.marker = marker;
        },
        createSearchLayer() {
            this.search.layer = new SMap.Layer.Marker();
            this.mapMethods.addAdditionalLayer(this.search.layer);
            this.search.layer.enable();
        },
    },
};
</script>
