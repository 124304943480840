<template>
    <div
        class="marker"
        :class="{
            [`marker--${type}`]: true,
            'is-active': isActive,
        }"
        v-on="!answerDialogue && activeMode && (isActive
            ? state.canDelete ? { click: removeMarker } : null
            : { click: setCurrentAnswerInstance })"
    >
        <div
            class="marker__inner"
        >
            <v-icon
                class="marker__icon"
                :color="isActive ? 'primary' : 'grey darken-3'"
            >
                mdi-map-marker
            </v-icon>
        </div>
    </div>
</template>

<script>
import { MAP_MARKER_TYPES } from '@/js/constants/map';

export default {
    MAP_MARKER_TYPES,

    props: {
        type: {
            type: String,
            default: null,
        },
        marker: {
            type: Object,
            required: true,
        },
        parentId: {
            type: [String, Number],
            default: null,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        const id = this.marker.getId();
        const coords = this.marker.getCoords();
        const { parentId, canDelete } = this;

        return {
            id,
            coords,
            state: {
                parentId,
                canDelete,
            },
        };
    },
    computed: {
        isActive() {
            return this.activeId === this.state.parentId;
        },
        activeId() {
            return this.$parent.activeId;
        },
        activeMode() {
            return this.$parent.activeMode;
        },
        answerDialogue() {
            return this.$parent.answerDialogue;
        },
    },
    watch: {
        parentId(value) {
            this.state.parentId = value;
        },
        canDelete(value) {
            this.state.canDelete = value;
        },
    },
    methods: {
        removeMarker(ev) {
            if (ev) {
                ev.preventDefault();
                ev.stopPropagation();
            }

            this.$parent.$emit('remove-marker', {
                id: this.id,
                parentId: this.state.parentId,
                type: this.type,
            });

            this.$destroy();
        },
        preventActions(ev) {
            ev.preventDefault();
            ev.stopPropagation();
        },
        setCurrentAnswerInstance(ev) {
            this.preventActions(ev);

            if (this.isActive) {
                return;
            }

            this.$parent.$emit('change-answer-instance', {
                id: this.state.parentId,
                type: this.type,
                isNotSaved: this.state.canDelete,
            });
        },
    },
};
</script>
