<template>
    <div>
        <div class="py-4">
            <h1 class="h2 text-regular">
                {{ $t($options.MENU_ITEMS.PROFILE) }}
            </h1>

            <p
                v-if="response.error"
                class="text-left error--text pt-4 text-smedium"
            >
                {{ response.error }}
            </p>

            <p
                v-if="response.data"
                class="pt-4 mb-0 text-left text-smedium"
            >
                {{ $t('profile.successfullyActivated') }}
            </p>
        </div>

        <div class="py-4">
            <v-list>
                <template
                    v-for="(item, i) in $options.ITEMS"
                >
                    <v-list-item
                        :key="`i${i}`"
                        :class="item.class"
                        :to="{ name: item.route }"
                    >
                        <v-list-item-avatar
                            min-width="70"
                            class="caption grey--text profile__list-label"
                        >
                            {{ $t(item.label) }}
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <template
                                v-if="item.value"
                            >
                                {{ $t(item.value) }}
                            </template>
                            <template
                                v-else
                            >
                                {{ user[item.datakey] }}
                            </template>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon color="primary">
                                mdi-chevron-right
                            </v-icon>
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider
                        :key="`d${i}`"
                    ></v-divider>
                </template>
            </v-list>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ROUTE } from '@/js/constants/route';
import { MENU_ITEMS } from '@/js/constants/menu';

const ITEMS = [
    {
        label: 'profile.nameLabel',
        datakey: 'name',
        route: ROUTE.PROFILE_NAME,
    },
    {
        label: 'profile.emailLabel',
        datakey: 'email',
        route: ROUTE.PROFILE_MAIL,
    },
    {
        label: 'profile.passwordLabel',
        value: '************',
        route: ROUTE.PROFILE_PASSWORD,
    },
    {
        label: 'profile.phoneLabel',
        datakey: 'phone',
        route: ROUTE.PROFILE_PHONE,
    },
    {
        label: 'profile.addressLabel',
        datakey: 'permanentAddressCity',
        route: ROUTE.PROFILE_CITY,
    },
    {
        label: 'profile.accountLabel',
        value: 'profile.removeAccount',
        class: 'profile__list-item-action',
        route: ROUTE.PROFILE_REMOVE_ACCOUNT,
    },
];

export default {
    ITEMS,
    MENU_ITEMS,

    data() {
        const { params } = this.$route;

        return {
            params,
            response: {
                error: null,
                data: null,
            },
        };
    },
    computed: {
        ...mapState('user', [
            'user',
        ]),
    },
    methods: {
        onError(response) {
            this.response.error = response;
        },
        onSuccess(response) {
            this.response.data = response;
        },
    },
};
</script>

<style lang="less">
.v-avatar.profile__list-label {
    justify-content: flex-start;
}
</style>
