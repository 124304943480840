export function scrollToHash(hash = null) {
    let el;
    try {
        el = document.querySelector(hash);
    } catch (e) {
        return;
    }
    if (!el) return;

    const bodyTop = document.body.getBoundingClientRect().top;
    const elementPosition = el.getBoundingClientRect().top;
    const top = elementPosition - bodyTop;

    window.scrollTo({
        top,
        behavior: 'smooth',
    });
}
