import { LAYER_VIEW_TYPES } from '@/js/constants/map';

export const LAYERS = {
    zelen: {
        code: 'zelen',
        name: 'map.layerControl.objective.zelenTitle',
        icon: 'greenery-icon',
        views: [
            {
                ...LAYER_VIEW_TYPES.DEFAULT,
            },
        ],
    },
    pocetnost: {
        code: 'pocetnost',
        name: 'map.layerControl.objective.pocetnostTitle',
        icon: 'people-icon',
        views: [
            {
                ...LAYER_VIEW_TYPES.HEATMAP,
            },
        ],
    },
    investicie: {
        code: 'investicie',
        name: 'map.layerControl.objective.investicieTitle',
        icon: 'city-icon',
        views: [
            {
                ...LAYER_VIEW_TYPES.DEFAULT,
            },
            {
                ...LAYER_VIEW_TYPES.HEXGRID,
            },
            {
                ...LAYER_VIEW_TYPES.HEATMAP,
            },
        ],
    },
    priestupky: {
        code: 'priestupky',
        name: 'map.layerControl.objective.priestupkyTitle',
        icon: 'cars-icon',
        views: [
            {
                ...LAYER_VIEW_TYPES.DEFAULT,
            },
            {
                ...LAYER_VIEW_TYPES.HEXGRID,
            },
            {
                ...LAYER_VIEW_TYPES.HEATMAP,
            },
        ],
    },
};

export const TILESETS = {};

export const INVESTICIE_COLORS = {
    23: {
        color: '#3da300',
        name: 'Realizované',
    },
    24: {
        color: '#0052da',
        name: 'Realizuje sa',
    },
    25: {
        color: '#ed8000',
        name: 'Pripravuje sa ',
    },
    29: {
        color: '#781435',
        name: 'Investície mesta, rok 2015 ',
    },
    28: {
        color: '#7b3a00',
        name: 'Investície mesta, rok 2016',
    },
    34: {
        color: '#4a3333',
        name: 'Investície mesta, rok 2017',
    },
};

export const EMOTION_QUESTIONS = [
    {
        id: 2,
        name: 'map.mocks.goodSpaces',
        color: '#827db8',
        icon: 'room-icon-2',
        iconColor: 'primary',
    },
    {
        id: 3,
        name: 'map.mocks.badSpaces',
        color: '#9bc732',
        icon: 'room-icon',
        iconColor: '#eb5757',
    },
    {
        id: 4,
        name: 'map.mocks.goodVegetation',
        color: '#0f0047',
        icon: 'trees-icon-2',
        iconColor: 'primary',
    },
    {
        id: 0,
        name: 'map.mocks.badVegetation',
        color: '#77d6cf',
        icon: 'trees-icon-2',
        iconColor: '#eb5757',
    },
    {
        id: 5,
        name: 'map.mocks.goodMobility',
        color: '#bf3e15',
        icon: 'runner-icon',
        iconColor: 'primary',
    },
    {
        id: 1,
        name: 'map.mocks.badMobility',
        color: '#154e56',
        icon: 'stop-icon',
        iconColor: '#eb5757',
    },
    {
        id: 6,
        name: 'map.mocks.goodSecurity',
        color: '#b31f59',
        icon: 'shield-icon',
        iconColor: 'primary',
    },
    {
        id: 7,
        name: 'map.mocks.badSecurity',
        color: '#5f8d7a',
        icon: 'danger-icon',
        iconColor: '#eb5757',
    },
];
