var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{key:_vm.formKey,ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',[_c('v-text-field',{attrs:{"prepend-icon":_vm.$options.ITEMS.name.icon,"type":_vm.$options.ITEMS.name.type,"rules":_vm.trans(_vm.$options.ITEMS.name.validators),"required":"","autofocus":"","autocomplete":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.$options.ITEMS.name.label))+" "),(_vm.$options.ITEMS.name.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true},{key:"message",fn:function(ref){
var message = ref.message;
return [_c('v-icon',{staticClass:"error-message__icon",attrs:{"color":"red","size":"16"}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(message)+" ")]}}]),model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"prepend-icon":_vm.$options.ITEMS.email.icon,"type":_vm.$options.ITEMS.email.type,"rules":_vm.trans(_vm.$options.ITEMS.email.validators),"error-count":"3","required":"","autocomplete":"email username"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.$options.ITEMS.email.label))+" "),(_vm.$options.ITEMS.email.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true},{key:"message",fn:function(ref){
var message = ref.message;
return [_c('v-icon',{staticClass:"error-message__icon",attrs:{"color":"red","size":"16"}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(message)+" ")]}}]),model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1),_c('v-flex',[_c('v-text-field',{ref:"password",staticClass:"mb-2",attrs:{"prepend-icon":_vm.$options.ITEMS.password.icon,"append-icon":_vm.isVisible ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.isVisible ? 'text' : _vm.$options.ITEMS.password.type,"rules":_vm.trans(_vm.$options.ITEMS.password.validators).concat( [_vm.validatorConfirmPassword]),"required":_vm.$options.ITEMS.password.required,"hint":_vm.$t(_vm.$options.ITEMS.password.hint),"autocomplete":"new-password","error-count":"3","persistent-hint":""},on:{"click:append":_vm.togglePasswordVisibility},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.$options.ITEMS.password.label))+" "),(_vm.$options.ITEMS.password.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true},{key:"message",fn:function(ref){
                         var message = ref.message;
return [_c('v-icon',{staticClass:"error-message__icon",attrs:{"color":"red","size":"16"}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(message)+" ")]}}]),model:{value:(_vm.formData.password1),callback:function ($$v) {_vm.$set(_vm.formData, "password1", $$v)},expression:"formData.password1"}})],1),_c('v-flex',[_c('v-text-field',{ref:"confirmPassword",attrs:{"prepend-icon":_vm.$options.ITEMS.confirmPassword.icon,"type":_vm.$options.ITEMS.confirmPassword.type,"rules":_vm.trans(_vm.$options.ITEMS.confirmPassword.validators),"required":"","autocomplete":"new-password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.$options.ITEMS.confirmPassword.label))+" "),(_vm.$options.ITEMS.confirmPassword.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true},{key:"message",fn:function(ref){
                         var message = ref.message;
return [_c('v-icon',{staticClass:"error-message__icon",attrs:{"color":"red","size":"16"}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(message)+" ")]}}]),model:{value:(_vm.formData.password2),callback:function ($$v) {_vm.$set(_vm.formData, "password2", $$v)},expression:"formData.password2"}})],1),_c('v-flex',{staticClass:"mb-3"},[_c('v-text-field',{attrs:{"prepend-icon":_vm.$options.ITEMS.permanentAddressCity.icon,"type":_vm.$options.ITEMS.permanentAddressCity.type,"rules":_vm.trans(_vm.$options.ITEMS.permanentAddressCity.validators),"required":"","autocomplete":"address-level2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.$options.ITEMS.permanentAddressCity.label))+" "),(_vm.$options.ITEMS.permanentAddressCity.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true},{key:"message",fn:function(ref){
                         var message = ref.message;
return [_c('v-icon',{staticClass:"error-message__icon",attrs:{"color":"red","size":"16"}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(message)+" ")]}}]),model:{value:(_vm.formData.permanentAddressCity),callback:function ($$v) {_vm.$set(_vm.formData, "permanentAddressCity", $$v)},expression:"formData.permanentAddressCity"}})],1),_c('v-flex',[_c('v-checkbox',{attrs:{"rules":_vm.trans(_vm.$options.ITEMS.gdprAgreed.validators),"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('a',{attrs:{"target":"_blank","href":_vm.$options.ITEMS.gdprAgreed.file},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$options.ITEMS.gdprAgreed.label))+" ")]),_c('span',{staticClass:"error--text form-checkbox__required-icon"},[_vm._v(" * ")])]},proxy:true},{key:"message",fn:function(ref){
                         var message = ref.message;
return [_c('v-icon',{staticClass:"error-message__icon",attrs:{"color":"red","size":"16"}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(message)+" ")]}}]),model:{value:(_vm.formData.gdprAgreed),callback:function ($$v) {_vm.$set(_vm.formData, "gdprAgreed", $$v)},expression:"formData.gdprAgreed"}})],1),_c('v-flex',{staticClass:"text-left text-smedium grey--text text--darken-1",attrs:{"mb-4":""}},[_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('form.mandatoryFields'))+" ")]),_c('input',{attrs:{"type":_vm.$options.ITEMS.utm.type,"name":_vm.$options.ITEMS.utm.name},domProps:{"value":_vm.formData.utm}}),_c('v-flex',{attrs:{"py-4":""}},[_c('v-layout',{attrs:{"justify-space-between":"","row":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-btn',{attrs:{"color":"primary","rounded":"","text":"","to":{name: _vm.$options.ROUTE.LOGIN}}},[_vm._v(" "+_vm._s(_vm.$t('registration.back'))+" ")])],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-btn',{attrs:{"rounded":"","color":"secondary","type":"submit","loading":_vm.isLoading,"disabled":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.$t('registration.submit'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }