<template>
    <div>
        <div class="py-4">
            <h1 class="h2 text-regular">
                {{ $t('activation.title') }}
            </h1>

            <div class="pt-6">
                <v-progress-circular
                    v-if="loadingCount"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>

            <p
                v-if="response.error"
                class="text-left error--text pt-4 text-smedium"
            >
                {{ response.error }}
            </p>

            <p
                v-if="response.data"
                class="pt-4 mb-0 text-left text-smedium"
            >
                {{ $t('activation.success') }}
            </p>
        </div>

        <div class="py-4">
            <v-layout
                justify-space-between
                row
            >
                <v-flex
                    shrink
                >
                    <v-btn
                        color="primary mx-0"
                        text
                        :to="{name: $options.ROUTE.MAP}"
                    >
                        {{ $t('activation.back') }}
                    </v-btn>
                </v-flex>
            </v-layout>
        </div>

        <form-activate
            @success="onSuccess"
            @error="onError"
        ></form-activate>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            response: {
                error: null,
                data: null,
            },
        };
    },
    computed: {
        ...mapState([
            'loadingCount',
        ]),
    },
    methods: {
        onError(response) {
            this.response.error = response;
        },
        onSuccess(response) {
            this.response.data = response;
        },
    },
};
</script>
