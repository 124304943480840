<template>
    <div
        class="btn current-position__button"
        @click.prevent="setCurrentPosition"
    >
        <v-progress-circular
            v-if="isLoading"
            size="16"
            indeterminate
            color="grey"
            width="2"
        ></v-progress-circular>

        <v-icon
            v-else
            size="18"
        >
            mdi-crosshairs-gps
        </v-icon>
    </div>
</template>

<script>
import { ALERT_TYPES } from '@/js/constants/alert';
import createMarkerComponent from '@/js/helpers/createMarkerComponent';

import MarkerPath from '@/js/components/markers/MarkerPath';

export default {
    props: {
        markerLayer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            currentPosition: {
                marker: null,
                coords: null,
            },
        };
    },
    methods: {
        setCurrentPosition() {
            if (this.currentPosition.marker) {
                this.markerLayer.removeMarker(this.currentPosition.marker);

                this.currentPosition.marker = null;
                this.currentPosition.coords = null;

                this.removeInstance();

                return;
            }

            if ('geolocation' in navigator) {
                this.isLoading = true;

                navigator.geolocation.getCurrentPosition((position) => {
                    const coords = SMap.Coords.fromWGS84(
                        position.coords.longitude,
                        position.coords.latitude,
                    );
                    const marker = createMarkerComponent(coords, null, MarkerPath, {
                        type: 'current',
                    }, this);

                    this.currentPosition.marker = marker;
                    this.currentPosition.coords = coords;

                    this.markerLayer.addMarker(marker);
                    this.map.setCenterZoom(this.currentPosition.coords, 18, true);

                    this.$store.dispatch('doAlert', {
                        text: this.$t('userLocation.successful'),
                        type: ALERT_TYPES.SUCCESS,
                    }, { root: true });

                    this.isLoading = false;
                }, (err) => {
                    // eslint-disable-next-line no-console
                    console.error(err);

                    this.$store.dispatch('doAlert', {
                        text: this.$t('userLocation.failed'),
                        type: ALERT_TYPES.ERROR,
                    });
                });
            } else {
                this.$store.dispatch('doAlert', {
                    text: this.$t('userLocation.notSupported'),
                    type: 'error',
                });
            }
        },
        createMarkerLayer() {
            this.markerLayer = new SMap.Layer.Marker();

            this.addAdditionalLayer(this.markerLayer);
            this.markerLayer.enable();
        },
    },
};
</script>
