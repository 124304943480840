<script>
import axios from 'axios';
import { API, getUrlWithParams } from '../../constants/api';
import { ROUTE } from '../../constants/route';

export default {
    ROUTE,

    data() {
        return {
            activated: false,
            errors: null,
        };
    },
    created() {
        this.activate();
    },
    methods: {
        activate() {
            this.$store.commit('addLocalLoadingCount');

            axios.get(getUrlWithParams(API.ACTIVATE, this.$route.query))
                .then((response) => {
                    this.emitSuccessfulState(response);
                })
                .catch((error) => {
                    this.emitErrorState(error);
                })
                .then(() => {
                    this.$store.commit('decreaseLocalLoadingCount');
                });
        },
    },

    render(h) {
        return h;
    },
};
</script>
