<template>
    <v-form
        ref="form"
        data-test="form-forgot"
        @submit.prevent="validate"
    >
        <v-layout column>
            <v-flex>
                <v-text-field
                    ref="input"
                    v-model="formData.email"
                    :prepend-icon="$options.ITEMS.email.icon"
                    :type="$options.ITEMS.email.type"
                    :rules="trans($options.ITEMS.email.validators)"
                    required
                    autofocus
                    autocomplete="email username"
                >
                    <template #label>
                        {{ $t($options.ITEMS.email.label) }}
                    </template>

                    <template #message="{message}">
                        <v-icon color="red">
                            mdi-alert-circle
                        </v-icon>
                        {{ message }}
                    </template>
                </v-text-field>
            </v-flex>

            <v-flex py-4>
                <v-layout
                    justify-space-between
                    row
                >
                    <v-flex
                        shrink
                    >
                        <v-btn
                            rounded
                            color="primary"
                            text
                            :to="{name: $options.ROUTE.LOGIN}"
                        >
                            {{ $t('forgot.back') }}
                        </v-btn>
                    </v-flex>

                    <v-flex
                        shrink
                    >
                        <v-btn
                            rounded
                            color="secondary"
                            type="submit"
                            :loading="isLoading"
                            :disabled="isLoading"
                        >
                            {{ $t('forgot.submit') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import axios from 'axios';
import { ROUTE } from '../../constants/route';
import { USER_FORGOT } from '../../constants/form';
import { API } from '../../constants/api';

export default {
    ROUTE,
    ITEMS: USER_FORGOT.ITEMS,

    data() {
        return {
            isLoading: false,
            formData: {
                email: '',
            },
        };
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.send();
            }
        },
        send() {
            if (this.isLoading) return;
            this.isLoading = true;
            this.nullResponseMessages();

            axios({
                url: API.FORGOT,
                method: 'post',
                data: this.formData,
            })
                .then((response) => {
                    this.emitSuccessfulState(response);
                })
                .catch((error) => {
                    this.emitErrorState(error);
                })
                .then(() => {
                    this.isLoading = false;
                });
        },
        nullResponseMessages() {
            this.emitSuccessfulState(null);
            this.emitErrorState(null);
        },
    },
};
</script>
