<template>
    <header class="header">
        <v-toolbar
            dark
            color="primary"
            class="px-3"
        >
            <v-fade-transition hide-on-leave>
                <v-app-bar-nav-icon
                    v-if="!isSearchToggled"
                    class="header__back mr-0 header__link"
                    @click.prevent="onBackClick"
                >
                    <v-icon>
                        mdi-arrow-left
                    </v-icon>
                </v-app-bar-nav-icon>
            </v-fade-transition>

            <v-fade-transition hide-on-leave>
                <template v-if="isSearchToggled">
                    <v-autocomplete
                        v-model="mapAutocompleteValue"
                        :placeholder="this.$t('controls.searchHint')"
                        color="white"
                        class="header__autocomplete"
                        autofocus
                        :items="mapAutocompleteItems"
                        item-text="title"
                        item-value="id"
                        :search-input.sync="mapAutocompleteSearch"
                        :loading="mapAutocompleteIsLoading"
                        no-filter
                        hide-no-data
                        hide-details
                        return-object
                    >
                        <template #item="{ item }">
                            <v-list-item-avatar>
                                <v-icon
                                    v-if="item.resultType"
                                    color="primary"
                                >
                                    {{ item.resultType === 'locality'
                                        ? 'icon-city-icon'
                                        : 'icon-direction' }}
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="header__autocomplete-content">
                                <v-list-item-title
                                    v-text="item.title"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                    v-text="item.secondRow"
                                ></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </template>
            </v-fade-transition>

            <v-fade-transition hide-on-leave>
                <v-toolbar-title
                    v-if="!isSearchToggled"
                    class="white--text"
                >
                    {{ title }}
                </v-toolbar-title>
            </v-fade-transition>

            <v-spacer></v-spacer>

            <v-btn
                v-if="hasSearch"
                icon
                @click.prevent="toggleSearch"
            >
                <v-icon>
                    {{ isSearchToggled ? 'mdi-close' : 'mdi-magnify' }}
                </v-icon>
            </v-btn>

            <slot name="append"></slot>
        </v-toolbar>
    </header>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import mapAutocomplete from '@/js/mixins/mapAutocomplete';
import { ROUTE } from '@/js/constants/route';
import { API } from '@/js/constants/api';
import { ALERT_TYPES } from '@/js/constants/alert';

export default {
    mixins: [
        mapAutocomplete,
    ],
    props: {
        title: {
            type: String,
            default: '',
        },
        backRoute: {
            type: [String, Object],
            default: null,
        },
        campaign: {
            type: Object,
            default: null,
        },
        hasSearch: {
            type: Boolean,
            default: false,
        },
        backCb: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            isSearchToggled: false,
            bbox: null,
            center: null,
        };
    },
    computed: {
        ...mapState(['route']),
    },
    watch: {
        campaign(campaign) {
            if (!campaign) {
                return;
            }

            this.center = null;
            this.setBbox(campaign.city);
        },
    },
    methods: {
        toggleSearch() {
            this.isSearchToggled = !this.isSearchToggled;
            this.mapAutocompleteValue = null;
        },
        onBackClick() {
            if (this.route.name === 'demography') {
                this.$router.push({
                    name: ROUTE.QUESTIONS,
                    params: { cid: this.$route.params.cid },
                });

                return;
            }

            if (this.route.name === ROUTE.CAMPAIGN
                && this.isLoggedSynthetic) {
                this.$popup.show('popup-back-confirmation');

                return;
            }

            if (this.backCb) this.backCb();

            if (this.backRoute) this.$router.push(this.backRoute);
        },
        setBbox(city) {
            if (!city) {
                return;
            }

            this.mapAutocompleteIsLoading = true;

            const customAxios = axios.create({
                withCredentials: false,
            });
            delete customAxios.defaults.headers.common.Authorization;
            const { CancelToken } = axios;
            const source = CancelToken.source();

            const axiosData = API.SEARCH;
            axiosData.cancelToken = source.token;

            const params = {
                qq: `city=${city.name}`,
            };

            axiosData.params = {
                ...axiosData.params,
                ...params,
            };

            // TODO: Add condition to handle autocomplete if this api is not ready
            customAxios(axiosData)
                .then(({ data }) => {
                    if (data.items[0]) {
                        this.bbox = `bbox:${(Object.values(data.items[0].mapView)).toString()}`;
                    }
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        this.$store.dispatch('doAlert', {
                            text: error.message,
                            type: ALERT_TYPES.ERROR,
                        });
                    }
                })
                .then(this.mapAutocompleteIsLoading = false);
        },
    },
};
</script>
