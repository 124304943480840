<template>
    <v-menu
        :key="`layer-menu-${layer.id}`"
        class="layer-control__layer"
        offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <div
                class="layer-control__card"
                :class="activeLayerClass"
                v-bind="attrs"
                v-on="on"
            >
                <!--TODO: Add loading state-->
                <div class="layer-control__icon">
                    <v-progress-circular
                        v-if="isLoading"
                        size="27"
                        :color="layer.iconColor"
                        class="layer-control__progress"
                        indeterminate
                    ></v-progress-circular>
                    <v-icon
                        v-else
                        :color="layer.iconColor"
                    >
                        {{ `icon-${layer.icon}` }}
                    </v-icon>
                </div>

                <div
                    class="layer-control__text text-center"
                    v-text="$t(layer.name)"
                ></div>
            </div>
        </template>

        <div class="layer-control__sub-wrapper">
            <div
                class="layer-control__container
                                           layer-control__container--sub"
            >
                <div
                    v-for="sublayer in layer.views"
                    :key="`sublayer-system-${sublayer.code}`"
                    class="layer-control__item"
                >
                    <div
                        class="layer-control__sub-layer"
                        @click.prevent="showLayer(sublayer.code)"
                    >
                        <div
                            class="layer-control__card"
                            :class="{ 'is-active': activeSubLayerClass(sublayer.code) }"
                        >
                            <div class="layer-control__icon">
                                <v-icon
                                    v-if="activeSubLayerClass(sublayer.code)"
                                    color="#fff"
                                >
                                    mdi-close
                                </v-icon>
                                <v-icon
                                    v-else
                                    color="#c5e9ff"
                                >
                                    {{ `icon-${sublayer.icon}` }}
                                </v-icon>
                            </div>

                            <div
                                class="layer-control__text text-center"
                            >
                                {{ $t(`map.layerControl.subLayers.${sublayer.code}`) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-menu>
</template>

<script>
export default {
    name: 'LayerControlLayer',
    props: {
        layer: {
            type: Object,
            required: true,
        },
        activeView: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        activeLayerClass() {
            return this.activeView
                && `layer-control__card--${this.activeView}`;
        },
        activeSubLayerClass() {
            return (view) => this.activeView === view;
        },
    },
    created() {
        this.$root.$on(`${this.layer.id}--loaded`, this.stopLoading);
    },
    beforeDestroy() {
        this.$root.$off(`${this.layer.id}--loaded`, this.stopLoading);
    },
    methods: {
        stopLoading() {
            this.isLoading = false;
        },
        showLayer(code) {
            if (this.activeView !== code) {
                this.isLoading = true;
            }

            this.$root.$emit('show-layer', this.layer, code);
        },
    },
};
</script>

<style lang="less">
.layer-control__progress {
    margin-top: 7px;
}
</style>
