export const BASE_API_URL = (process.env.VUE_APP_PROD_BUILD === '1')
    ? 'https://server.aipowered.city'
    : 'https://data-city.azurewebsites.net';
export const API_PREFIX = '/api/v1';

export const BASE_LAYER_API_URL = (process.env.VUE_APP_PROD_BUILD === '1')
    ? 'https://app-data-city-edm-prod-01.azurewebsites.net'
    : 'https://app-data-city-edm-test-01.azurewebsites.net';
export const SEARCH_AUTOSUGGEST = 'https://autosuggest.search.hereapi.com/v1/autosuggest';
export const SEARCH = 'https://geocode.search.hereapi.com/v1/geocode';

export const METHODS = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
};

export const API = {
    // USER
    REGISTER: `${BASE_API_URL}${API_PREFIX}/user/register`,
    LOGIN: `${BASE_API_URL}${API_PREFIX}/user/login`,
    LOGOUT: `${BASE_API_URL}${API_PREFIX}/user/logout`,
    USER: `${BASE_API_URL}${API_PREFIX}/user`,
    PASSWORD: `${BASE_API_URL}${API_PREFIX}/user/change_password`,
    FORGOT: `${BASE_API_URL}${API_PREFIX}/user/reset_password`,
    RESET: `${BASE_API_URL}${API_PREFIX}/user/reset_password_confirm`,
    ACTIVATE: `${BASE_API_URL}${API_PREFIX}/verify/email/:id/:email`,

    // SYNTHETIC USER
    REGISTER_SU: `${BASE_API_URL}${API_PREFIX}/user/synthetic/create`,
    CONVERT_SU: `${BASE_API_URL}${API_PREFIX}/user/synthetic/register`,

    // CAMPAIGNS
    CAMPAIGNS: `${BASE_API_URL}${API_PREFIX}/campaign`,
    CAMPAIGN: `${BASE_API_URL}${API_PREFIX}/campaign/:cid`,
    CITIES: `${BASE_API_URL}${API_PREFIX}/city`,
    CATEGORIES: `${BASE_API_URL}${API_PREFIX}/category`,
    QUESTIONS: `${BASE_API_URL}${API_PREFIX}/campaign/:cid/question`,
    QUESTION: `${BASE_API_URL}${API_PREFIX}/campaign_question/:qid`,
    ANSWERS: `${BASE_API_URL}${API_PREFIX}/campaign_question/:id/answer`,
    SAVE_ANSWER: `${BASE_API_URL}${API_PREFIX}/campaign_answer`,
    UPDATE_ANSWER: `${BASE_API_URL}${API_PREFIX}/campaign_answer/:id`,
    DEMOGRAPHY: `${BASE_API_URL}${API_PREFIX}/campaign_demography/option`,
    SAVE_DEMOGRAPHY: `${BASE_API_URL}${API_PREFIX}/campaign_demography`,
    ANSWER: `${BASE_API_URL}${API_PREFIX}/campaign_answer/:id`,
    ANSWER_ATTACHMENT: `${BASE_API_URL}${API_PREFIX}/campaign_answer/:id/attachment`,
    ADD_ANSWER_ATTACHMENT: `${BASE_API_URL}${API_PREFIX}/campaign_attachment`,
    REMOVE_ANSWER_ATTACHMENT: `${BASE_API_URL}${API_PREFIX}/campaign_attachment/:id`,
    ANSWER_CUSTOM_COMMENT: `${BASE_API_URL}${API_PREFIX}/campaign_answer/:id/custom_comment`,
    ANSWER_SELECTED_COMMENT: `${BASE_API_URL}${API_PREFIX}/campaign_answer/:id/selected_comment`,
    ANSWER_SAVE_CUSTOM_COMMENT: `${BASE_API_URL}${API_PREFIX}/campaign_custom_comment`,
    ANSWER_UPDATE_CUSTOM_COMMENT: `${BASE_API_URL}${API_PREFIX}/campaign_custom_comment/:id`,
    ANSWER_UPDATE_SELECTED_COMMENT: `${BASE_API_URL}${API_PREFIX}/campaign_selected_comment/:id`,

    // MAP
    ENTITIES: `${BASE_API_URL}${API_PREFIX}/entity`,
    EMOTIONS_BY_QUESTION: `${BASE_API_URL}${API_PREFIX}/entity/emotion`,
    EMOTION_QUESTIONS: `${BASE_API_URL}${API_PREFIX}/entity/emotion_question`,
    /**
     * Layers API
     *
     * params(optional):
     * - dateFrom (yyyy-mm-dd)
     * - dateTo (yyyy-mm-dd)
     * - layerIds[]
     */
    LAYERS: {
        url: `${BASE_LAYER_API_URL}${API_PREFIX}/layer`,
        method: METHODS.GET,
    },
    LAYER_DATA: {
        url: `${BASE_LAYER_API_URL}${API_PREFIX}/layer/data`,
        method: METHODS.GET,
    },
    SEARCH: {
        url: SEARCH,
        method: METHODS.GET,
        params: {
            apiKey: process.env.VUE_APP_HERE_API_KEY,
        },
    },
    SEARCH_AUTOSUGGEST: {
        url: SEARCH_AUTOSUGGEST,
        method: METHODS.GET,
        params: {
            apiKey: process.env.VUE_APP_HERE_API_KEY,
        },
    },
};

export const getUrlWithParams = (url, args) => url.replace(/:(\w+)/g, (_, arg) => args[arg]);

export function getApi(apiObject, params = {}) {
    return {
        url: apiObject.url.replace(/:(\w+)/g, (_, key) => params[key] || ''),
        method: apiObject.method,
    };
}
