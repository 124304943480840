export function doSetPosition({ commit }, data) {
    commit('setPosition', data);
}

export function doSetZoom({ commit }, data) {
    commit('setZoom', data);
}

export function doReset({ dispatch }, name) {
    dispatch('doSetZoom', { [name]: null });
    dispatch('doSetPosition', { [name]: null });
}
