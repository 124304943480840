import axios from 'axios';
import { API, getApi } from '@/js/constants/api';
import {
    LAYER_VIEW_TYPES,
} from '@/js/constants/map';
import { ALERT_TYPES } from '@/js/constants/alert';

function queryLayerDataToObject(data) {
    if (!data || data === '') {
        return {};
    }

    return (data.split(',')).reduce((acc, cur) => {
        const array = cur.split(':');

        return {
            ...acc,
            [array[0]]: array[1] || LAYER_VIEW_TYPES.DEFAULT,
        };
    }, {});
}

function queryLayerDataToString(data) {
    return (Object.keys(data)
        .map((item) => `${item}:${data[item]}`))
        .join(',');
}

export default {
    cancelToken: null,
    data() {
        return {
        };
    },
    methods: {
        removeActiveLayer(id) {
            let { layerDataIds } = this.query;
            const layerData = queryLayerDataToObject(layerDataIds);

            delete layerData[id];
            this.$delete(this.activeLayers, id);

            if (layerDataIds) {
                layerDataIds = queryLayerDataToString(layerData);
                this.query.layerDataIds = layerDataIds;
                this.updateQuery(this.query);
            }
        },
        updateQuery(query) {
            this.$router.push({
                query,
            });
        },
        changeActiveLayer(id, view, initial) {
            this.$set(this.activeLayers, id, view);
            let { layerDataIds } = this.query;

            if (!layerDataIds) {
                layerDataIds = '';
            }

            const layerData = {
                ...queryLayerDataToObject(layerDataIds),
                [id]: view,
            };
            this.query.layerDataIds = queryLayerDataToString(layerData);

            if (!initial) {
                this.updateQuery(this.query);
            }
        },
        showLayer(layer, view, initial) {
            if (!initial
                && this.activeLayers
                && this.activeLayers[layer.id] === view) {
                this.removeActiveLayer(layer.id);

                return;
            }

            this.changeActiveLayer(layer.id, view, initial);

            if (!initial) {
                this.loadLayer(layer.id, view);
            }
        },
        layerService(ids, query, cancel) {
            if (this.$options.cancelToken) {
                this.$options.cancelToken();
            }

            const axiosData = getApi(API.LAYER_DATA);
            if (cancel) {
                axiosData.cancelToken = new axios.CancelToken((token) => {
                    this.$options.cancelToken = token;
                });
            }
            axiosData.params = {
                ...query,
                layerIds: ids,
            };

            return axios(axiosData);
        },
        getLayers(ids) {
            if (!ids) {
                return null;
            }

            return ids.split(',')
                .map((item) => {
                    const arr = item.split(':');

                    return {
                        id: arr[0],
                        view: arr[1],
                    };
                });
        },
        loadLayers(layerData, query, resetLayers) {
            if (!layerData) {
                return;
            }

            const ids = layerData.map((item) => item.id).join(',');

            this.layerService(ids, query, true)
                .then(({ data: { layers } }) => {
                    if (resetLayers) {
                        this.$root.$emit('map-reset-layers');
                    }

                    this.$nextTick(() => {
                        layers.forEach((item) => {
                            const layerPair = layerData
                                .find((layer) => item.id === layer.id);

                            item.view = (layerPair && layerPair.view)
                                ? layerPair.view
                                : LAYER_VIEW_TYPES.DEFAULT;
                            const { view } = item;

                            this.showLayer(item, view, true);
                            this.$nextTick(() => {
                                this.$root.$emit('map-render-layer', {
                                    layer: item,
                                    view,
                                });
                            });
                        });
                    });

                    this.isLoading = false;
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        return;
                    }

                    this.isLoading = false;
                    this.$store.dispatch('doAlert', {
                        text: error.message,
                        type: ALERT_TYPES.ERROR,
                    });
                });
        },
        loadLayer(id, view) {
            if (this.loading) {
                return;
            }

            this.isLoading = true;

            this.layerService(id)
                .then(({ data: { layers } }) => {
                    const layer = layers[0];
                    layer.view = view;

                    if (layer) {
                        this.$nextTick(() => {
                            this.$root.$emit(`${id}--loaded`);
                            this.$root.$emit('map-render-layer', {
                                layer,
                            });
                        });
                    }
                })
                .catch((error) => {
                    this.$store.dispatch('doAlert', {
                        text: error.message,
                        type: ALERT_TYPES.ERROR,
                    });
                })
                .then(() => {
                    this.isLoading = false;
                });
        },
    },
};
