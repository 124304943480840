<template>
    <div class="list">
        <slot name="prepend"></slot>

        <div
            v-if="data.length === 0"
            class="list__tile list__tile--no-results"
        >
            <slot name="no-results">
                {{ $t('common.emptyList') }}
            </slot>
        </div>

        <template
            v-else
        >
            <router-link
                v-for="(item, index) in data"
                :key="`$listItemId${item.id}`"
                :to="{
                    name: isCampaign ? $options.ROUTE.CAMPAIGN : $options.ROUTE.QUESTION,
                    params: {
                        cid: item.id,
                        qid: item.id,
                        ...params,
                    },
                }"
                tag="div"
                class="list__tile"
            >
                <div
                    class="list__wrapper list__link list__wrapper--top"
                >
                    <div class="list__action">
                        <v-tooltip
                            v-if="isCampaign"
                            top
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="primary"
                                    depressed
                                    fab
                                    dark
                                    small
                                    class="ma-0 list__button list__button--main"
                                    v-on="on"
                                >
                                    {{ item.city && item.city.key }}
                                </v-btn>
                            </template>

                            <span>
                                {{ item.city && item.city.name }}
                            </span>
                        </v-tooltip>

                        <v-btn
                            v-else
                            color="primary"
                            depressed
                            fab
                            dark
                            small
                            class="ma-0 list__button list__button--main"
                        >
                            {{ index + 1 }}.
                        </v-btn>
                    </div>

                    <div class="list__content">
                        <div class="list__title">
                            {{ item[titleProperty] }}
                        </div>
                    </div>

                    <div
                        v-if="isCampaign"
                        class="list__action list__action--right"
                    >
                        <v-container
                            grid-list-md
                            pa-0
                        >
                            <v-layout
                                wrap
                                justify-end
                            >
                                <v-flex
                                    v-for="tag in item.categories"
                                    :key="`$tagId${tag.id}`"
                                    shrink
                                >
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                class="list__button ma-0"
                                                color="grey"
                                                depressed
                                                fab
                                                dark
                                                small
                                                icon
                                                v-on="on"
                                            >
                                                <v-icon>
                                                    icon-{{ tag.icon }}
                                                </v-icon>
                                            </v-btn>
                                        </template>

                                        <span>
                                            {{ tag.name }}
                                        </span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </div>
                </div>

                <div class="list__wrapper list__wrapper--bottom">
                    <div class="list__action">
                        <template
                            v-if="isCampaign"
                        >
                            <v-icon>
                                mdi-calendar
                            </v-icon>
                        </template>
                    </div>

                    <div class="list__content">
                        <div
                            v-if="isCampaign"
                            class="list__subtitle"
                        >
                            {{ campaignDuration(item.daysLeft) }}
                        </div>

                        <v-container
                            v-else
                            pa-0
                        >
                            <v-layout ma-0>
                                <v-flex>
                                    <div
                                        class="list__entity"
                                        :class="{
                                            'list__entity--active':
                                                !isNull(item.numAnswers)
                                        }"
                                    >
                                        <v-icon
                                            class="list__content-icon"
                                            :color="isNull(item.numAnswers) ? null : 'primary'"
                                        >
                                            mdi-map-marker
                                        </v-icon>

                                        <span class="list__entities-count">
                                            {{ item.numAnswers || 0 }}
                                        </span>
                                    </div>
                                </v-flex>

                                <v-flex>
                                    <div
                                        class="list__entity"
                                        :class="{
                                            'list__entity--active':
                                                !isNull(item.numComments)
                                        }"
                                    >
                                        <v-icon
                                            class="list__content-icon"
                                            :color="isNull(item.numComments)
                                                ? null
                                                : 'primary'"
                                        >
                                            mdi-message
                                        </v-icon>

                                        <span class="list__entities-count">
                                            {{ item.numComments || 0 }}
                                        </span>
                                    </div>
                                </v-flex>

                                <v-flex>
                                    <div
                                        class="list__entity"
                                        :class="{
                                            'list__entity--active':
                                                !isNull(item.numAttachments)
                                        }"
                                    >
                                        <v-icon
                                            class="list__entity-icon"
                                            :class="{
                                                'list__content-icon--active':
                                                    !isNull(item.numAttachments)
                                            }"
                                            :color="isNull(item.numAttachments) ? null : 'primary'"
                                        >
                                            mdi-image
                                        </v-icon>

                                        <span class="list__entities-count">
                                            {{ item.numAttachments || 0 }}
                                        </span>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </div>

                    <div
                        v-if="isCampaign"
                        class="list__action list__action--right list__action--fluid"
                    >
                        <progress-bar
                            :current="item.questionsAnswered"
                            :max="item.questionsTotal"
                        ></progress-bar>
                    </div>
                </div>
            </router-link>
        </template>

        <slot name="append"></slot>
    </div>
</template>

<script>
import { ROUTE } from '../constants/route';

export default {
    ROUTE,
    props: {
        data: {
            type: [Array, Object],
            default: () => ({}),
        },
        type: {
            type: String,
            default: 'campaign',
        },
        params: {
            type: Object,
            default: () => ({}),
        },
        titleProperty: {
            type: String,
            default: 'name',
        },
    },
    computed: {
        isCampaign() {
            return this.type === 'campaign';
        },
        isNull() {
            return (count) => +count === 0;
        },
    },
    methods: {
        campaignDuration(value) {
            return `${this.$t('feelings.campaign.endsIn')} ${value} ${this.$t(this.daysWording(value))}`;
        },
        daysWording(value) {
            if (!value) {
                return '';
            }

            switch (+value) {
            case 1:
                return 'wording.day.day';

            case 2 || 3 || 4:
                return 'wording.day.days234';

            default:
                return 'wording.day.days';
            }
        },
    },
};
</script>
