import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';
import { storage } from '../../../utils/storage';
import { STORAGE } from '../../../constants/storage';

const state = {
    user: JSON.parse(storage.getItem(STORAGE.USER)) || {},
    token: storage.getItem(STORAGE.TOKEN) || null,
};

export default {
    state,
    getters,
    actions,
    mutations,
};
