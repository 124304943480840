// import Vue from 'vue';
// import InfoBar from '../components/InfoBar';
// const ComponentCtor = Vue.extend(InfoBar);

export const doAlert = ({ commit }, data) => {
    if (data.text === null
        || data.text === '') {
        return;
    }

    const props = {
        text: data.text,
        type: data.type,
        multi: data.multi,
        html: data.html,
        timeout: data.timeout,
        storeCallback: commit,
    };

    commit('addAlert', props);
    // componentInstance.$mount();
    // document.querySelector('#app').appendChild(componentInstance.$el);
};

export const doSetLanguage = ({ commit }, lang) => {
    commit('setLanguage', lang);
};
