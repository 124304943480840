import Vue from 'vue';

export default (position, id, component, options, parentComponent) => {
    const markerWrapper = document.createElement('div');
    const marker = new SMap.Marker(position, id, { url: markerWrapper });

    // Create component instance
    const container = marker.getContainer();
    const Component = Vue.extend(component);

    const instance = new Component({

        propsData: {
            ...options,
            marker,
            activeId: parentComponent.activeId,
        },

        parent: parentComponent,
    });

    instance.$mount();
    container['3'].appendChild(instance.$el);

    return marker;
};
