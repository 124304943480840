import debounce from 'lodash.debounce';
import axios from 'axios';
import { MAP_SEARCH } from '@/js/constants/map';
import { API } from '@/js/constants/api';
import { ALERT_TYPES } from '@/js/constants/alert';

export default {
    mapAutocompleteDebounce: null,

    data() {
        return {
            mapAutocompleteSearch: null,
            mapAutocompleteValue: null,
            mapAutocompleteItems: [],
            mapAutocompleteIsLoading: false,
        };
    },

    watch: {
        mapAutocompleteSearch(val) {
            if (val) {
                if (!(this.mapAutocompleteValue && this.mapAutocompleteValue.title === val)) {
                    this.mapAutocompleteItems = [];
                    this.$options.mapAutocompleteDebounce();
                }
            } else {
                this.mapAutocompleteItems = [];
                this.$options.mapAutocompleteDebounce.cancel();
            }
        },
        mapAutocompleteValue(val) {
            this.addSearchMarker(val);
        },
    },

    created() {
        this.$options.mapAutocompleteDebounce = debounce(this.mapAutocompleteDoSearch, 300);
    },

    methods: {
        mapAutocompleteDoSearch() {
            this.mapAutocompleteIsLoading = true;

            const customAxios = axios.create({
                withCredentials: false,
            });
            delete customAxios.defaults.headers.common.Authorization;
            const { CancelToken } = axios;
            const source = CancelToken.source();

            const axiosData = API.SEARCH_AUTOSUGGEST;
            axiosData.cancelToken = source.token;

            if (this.center === undefined) {
                this.center = MAP_SEARCH.at;
            }

            const params = {
                q: this.mapAutocompleteSearch,
                ...MAP_SEARCH,
                at: this.center,
                in: this.bbox || MAP_SEARCH.in,
            };

            axiosData.params = {
                ...axiosData.params,
                ...params,
            };

            customAxios(axiosData)
                .then(({ data }) => {
                    this.mapAutocompleteItems = data.items;
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        this.$store.dispatch('doAlert', {
                            text: error.message,
                            type: ALERT_TYPES.ERROR,
                        });
                    }
                })
                .then(() => {
                    this.mapAutocompleteIsLoading = false;
                });
        },
        addSearchMarker(items) {
            this.$emit('on-search-confirmation', items);
        },
    },
};
