<template>
    <div class="filter">
        <div class="filter__title">
            <h3 class="h4">
                {{ $t('feelings.campaign.categoryFilter') }}
            </h3>
        </div>

        <div
            ref="container"
            class="filter__wrapper js-scroll"
        >
            <div
                v-for="(item, index) in items"
                :key="`#$filter-category-${index}`"
                class="filter__item"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="btn-icon--xs"
                            :color="colors(item)"
                            dark
                            fab
                            small
                            depressed
                            icon
                            @click.prevent="toggleIsActive(item)"
                            v-on="on"
                        >
                            <v-icon
                                dark
                            >
                                icon-{{ item.icon }}
                            </v-icon>
                        </v-btn>
                    </template>

                    <span>
                        {{ item.name }}
                    </span>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

export default {
    props: {
        items: {
            type: [Array, Object],
            default: () => ([]),
        },
    },

    data() {
        return {
            ps: null,
        };
    },

    computed: {
        colors() {
            return (item) => {
                const colors = [
                    'grey',
                    item.isActive ? 'isActive' : 'lighten-2',
                ];

                return colors.join(' ');
            };
        },
    },

    mounted() {
        this.ps = new PerfectScrollbar(this.$refs.container);
    },

    beforeDestroy() {
        this.ps.destroy();
    },

    methods: {
        toggleIsActive(item) {
            item.isActive = !item.isActive;
        },
    },
};
</script>
