import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        light: true,
        themes: {
            light: {
                primary: {
                    base: '#096ea9',
                    lighten3: '#93BCD3',
                },
                secondary: '#2eb3a4',
                accent: '#8c9eff',
                error: '#eb5757',
                secondary_darker: '#009688',
            },
        },
    },
    breakpoint: {
        thresholds: {
            xs: 414,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1240,
        },
    },
});
