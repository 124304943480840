<template>
    <v-form
        ref="form"
        @submit.prevent="validate"
    >
        <v-layout column>
            <v-flex>
                <v-text-field
                    ref="password"
                    v-model="formData.password"
                    :prepend-icon="$options.ITEMS.password.icon"
                    :type="$options.ITEMS.password.type"
                    :rules="[...trans($options.ITEMS.password.validators),
                             validatorConfirmPassword]"
                    :required="$options.ITEMS.password.required"
                    autofocus
                    autocomplete="new-password"
                >
                    <template #label>
                        {{ $t($options.ITEMS.password.label) }}
                    </template>

                    <template #message="{message}">
                        <v-icon
                            color="red"
                            size="16"
                            class="error-message__icon"
                        >
                            error
                        </v-icon>
                        {{ message }}
                    </template>
                </v-text-field>

                <v-text-field
                    v-model="formData.confirmPassword"
                    :prepend-icon="$options.ITEMS.confirmPassword.icon"
                    :type="$options.ITEMS.confirmPassword.type"
                    :rules="trans($options.ITEMS.confirmPassword.validators)"
                    :required="$options.ITEMS.confirmPassword.required"
                    autocomplete="new-password"
                >
                    <template #label>
                        {{ $t($options.ITEMS.confirmPassword.label) }}
                    </template>

                    <template #message="{message}">
                        <v-icon
                            color="red"
                            size="16"
                            class="error-message__icon"
                        >
                            error
                        </v-icon>
                        {{ message }}
                    </template>
                </v-text-field>
            </v-flex>

            <v-flex py-4>
                <v-layout
                    justify-space-between
                    row
                >
                    <v-flex
                        shrink
                    >
                        <v-btn
                            rounded
                            color="primary"
                            text
                            :to="{name: $options.ROUTE.LOGIN}"
                        >
                            {{ $t('reset.back') }}
                        </v-btn>
                    </v-flex>

                    <v-flex
                        shrink
                    >
                        <v-btn
                            rounded
                            color="secondary"
                            type="submit"
                            :loading="isLoading"
                            :disabled="isLoading"
                        >
                            {{ $t('reset.submit') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import axios from 'axios';
import { ROUTE } from '../../constants/route';
import { ERROR_ICON, USER_RESET } from '../../constants/form';
import { API } from '../../constants/api';

export default {
    ROUTE,
    ITEMS: USER_RESET.ITEMS,

    data() {
        return {
            isLoading: false,
            formData: {
                password: '',
                confirmPassword: '',
            },
        };
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.send();
            }
        },
        send() {
            if (this.isLoading) return;
            this.isLoading = true;

            const { query } = this.$route;
            const data = {
                ...this.formData,
                ...query,
            };

            axios({
                url: API.RESET,
                method: 'post',
                data,
            })
                .then((response) => {
                    this.emitSuccessfulState(response);
                    this.$router.push({ name: ROUTE.LOGIN });
                })
                .catch((error) => {
                    this.$emit('error', this.errorMessage(error));
                })
                .then(() => {
                    this.isLoading = false;
                });
        },
        validatorConfirmPassword(value) {
            return (value === this.formData.confirmPassword) || ['form.password.matchValidation', { icon: ERROR_ICON }];
        },
    },
};
</script>
