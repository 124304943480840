<template>
    <div>
        <div class="py-4">
            <h1 class="h2 text-regular">
                {{ $t('login.title') }}
            </h1>
        </div>

        <div class="py-4">
            <form-login
                @logged="onLogged"
            ></form-login>
        </div>
    </div>
</template>

<script>
import { ROUTE } from '../../constants/route';

export default {
    methods: {
        onLogged() {
            this.$router.push({ name: ROUTE.MAP });
        },
    },
};
</script>
