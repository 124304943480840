<template>
    <v-form
        ref="form"
        @submit.prevent="validate"
    >
        <div class="py-4">
            <h1 class="h2 text-regular">
                {{ $t(item.title) }}
            </h1>
        </div>

        <div class="py-4">
            <v-text-field
                v-model="value"
                :prepend-icon="item.icon"
                :type="item.type"
                :rules="trans(item.validators)"
                :autocomplete="item.autocomplete"
                :hint="$t(item.hint)"
                required
                autofocus
            >
                <template #label>
                    {{ $t(item.label) }}
                </template>

                <template #message="{message}">
                    <v-icon
                        color="red"
                        size="16"
                        class="error-message__icon"
                    >
                        mdi-alert-circle
                    </v-icon>
                    {{ message }}
                </template>
            </v-text-field>
        </div>

        <v-layout
            justify-space-between
            py-4
        >
            <v-flex
                shrink
            >
                <v-btn
                    text
                    color="primary"
                    :to="{name: $options.ROUTE.PROFILE}"
                    exact
                >
                    {{ $t('profile.back') }}
                </v-btn>
            </v-flex>

            <v-flex
                shrink
            >
                <v-btn
                    rounded
                    color="secondary"
                    type="submit"
                    :loading="isLoading"
                    :disabled="isLoading"
                >
                    {{ $t(item.submit) }}
                </v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { USER_PROFILE } from '@/js/constants/form';
import { ROUTE } from '@/js/constants/route';
import { API } from '@/js/constants/api';
import loadUser from '@/js/mixins/loadUser';

export default {
    ROUTE,

    mixins: [
        loadUser,
    ],
    data() {
        return {
            value: '',
            isLoading: false,
        };
    },
    computed: {
        ...mapState('user', [
            'user',
        ]),
        item() {
            return USER_PROFILE.ITEMS[this.$route.name];
        },
    },
    created() {
        const value = this.user[this.item.name];
        if (value) {
            this.value = value;
        }
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.send();
            }
        },
        onUserLoad() {
            this.$store.dispatch('doAlert', {
                type: 'success',
                text: this.$t('profile.successfullyChanged'),
            });
            this.$router.push({ name: ROUTE.PROFILE });
        },
        send() {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;

            axios({
                url: API.USER,
                method: 'put',
                data: {
                    [this.item.name]: this.value,
                },
            })
                .then(() => {
                    if (this.$route.name === ROUTE.PROFILE_MAIL) {
                        this.$store.dispatch('doAlert', {
                            type: 'success',
                            text: `${this.$t('profile.emailChange.requestProcessed')}<br><br>${
                                this.$t('profile.emailChange.emailSent')}<br><br>${
                                this.$t('profile.emailChange.loginAfterChange')}`,
                            multi: true,
                            html: true,
                            timeout: 0,
                        });
                        this.$store.dispatch('user/doLogout', this.$t('user.successfulLogout'));
                        this.$router.push({ name: ROUTE.LOGIN });
                        return;
                    }

                    this.loadUser(this.onUserLoad);
                })
                .catch((error) => {
                    this.$store.dispatch('doAlert', {
                        text: this.errorMessage(error),
                        type: 'error',
                    });
                    this.isLoading = false;
                });
        },
    },
};
</script>
