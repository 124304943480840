<template>
    <v-layout
        column
    >
        <!--Map-->
        <v-flex
            class="d-flex relative"
        >
            <s-map
                v-if="!layoutLoadingCount && answers"
                #default="{
                    mapMethods,
                    map,
                    geometryLayer,
                }"
                class="d-flex"
                :options="mapOptions"
                :persistent-pos="$options.MAP_INSTANCE.QUESTION"
                @mapLoaded="onMapLoaded"
            >
                <map-controls
                    v-if="map"
                    :map-methods="mapMethods"
                    :map="map"
                    :geometry-layer="geometryLayer"
                    :options-pos="$options.MAP_OPTIONS.CURRENT_POS_CONTROLLER_QUESTION"
                    :options-default="localizeControls($options.MAP_CONTROL_ITEMS_DEFAULT_QUESTION)"
                >
                </map-controls>

                <marker-controls
                    v-if="map"
                    ref="marker-controls"
                    :map-methods="mapMethods"
                    :map="map"
                    :answers="answers || []"
                    :question="question"
                    :marker-type.sync="markerType"
                    :geometry-layer="geometryLayer"
                    :answer-dialogue="answerDialogue"
                    search-layer-enable
                    @change-answer-instance-up="onChangeAnswerInstance"
                    @submit-answer-markers="onSubmitAnswerMarkers"
                    @on-active-id-change="onActiveIdChange"
                    @on-speed-dial-disabled-change="onSpeedDialDisabledChange"
                ></marker-controls>
            </s-map>
        </v-flex>
        <!--./Map-->

        <!--Answer-submit-dialogue-->
        <answer-submit-dialogue
            v-show="answerDialogue"
            ref="answer-submit-dialogue"
            :answer.sync="answer"
            :marker-type.sync="markerType"
            @set-new-answer-instance="onSetNewAnswerInstance"
        ></answer-submit-dialogue>
        <!--./Answer-submit-dialogue-->

        <!--Question-detail-->
        <question-detail
            v-show="question"
            ref="question-detail"
            :is-visible.sync="isQuestionDetailOpened"
            :question="question || {}"
            :map-loaded="mapLoaded"
            :marker-type="markerType"
            @ready-marker-type="setMarkerType"
        ></question-detail>
        <!--./Question-detail-->

        <!--Answer-delete-modal-->
        <answer-delete-modal
            name="popupOnAnswerDelete"
            @delete-answer="deleteAnswer"
        ></answer-delete-modal>
        <!--./Answer-delete-modal-->
    </v-layout>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { API, getUrlWithParams } from '@/js/constants/api';
import {
    MAP_OPTIONS,
    MAP_CONTROL_ITEMS_DEFAULT_QUESTION,
    MAP_INSTANCE,
} from '@/js/constants/map';

export default {
    MAP_OPTIONS,
    MAP_CONTROL_ITEMS_DEFAULT_QUESTION,
    MAP_INSTANCE,

    props: {
        campaign: {
            type: Object,
            required: true,
        },
        selectedPlace: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            answerDialogue: false,
            isQuestionDetailOpened: false,
            showOnlyUserCities: null,
            answer: null,
            answers: null,
            question: null,
            markerType: null,
            actionMode: null,
            activeId: null,
            mapLoaded: false,
        };
    },
    computed: {
        ...mapState([
            'layoutLoadingCount',
        ]),
        getQuestionId() {
            return this.$route.params.qid;
        },
        mapOptions() {
            return this.campaign && {
                position: {
                    x: this.campaign.city.longitude,
                    y: this.campaign.city.latitude,
                },
            };
        },
    },
    watch: {
        isQuestionDetailOpened(n) {
            this.$emit('on-question-detail-visibility-change', !n);
        },
        selectedPlace(item) {
            if (item) {
                const options = {
                    title: item.title,
                    latitude: item.position.lat,
                    longitude: item.position.lng,
                };

                this.$refs['marker-controls'].addSearchMarker(options);
            }
        },
    },
    mounted() {
        this.isQuestionDetailOpened = true;

        this.getQuestionDetails();
        this.getAllAnswers();
    },
    methods: {
        onSpeedDialDisabledChange(state) {
            this.$refs['question-detail'].setAreControlsDisable(state);
        },
        onSetQuestionDetilOpened(state) {
            this.isQuestionDetailOpened = state;
        },
        onActiveIdChange(id) {
            this.activeId = id;
        },
        localizeControls(options) {
            options.filter(item => item.name === 'Zoom').forEach((item) => {
                item.options.forEach((o) => {
                    // TODO: Toto
                    //  riesenie
                    //  nie
                    //  je
                    //  dostatocne
                    //  robustne
                    //  a
                    //  pri
                    //  prepinani
                    //  jazykov
                    //  vypisuje
                    //  chybu:
                    //  [vue-i18n] Value of key 'Zoom out' is not a string!
                    if (o && o.titles && o.titles.length > 0) {
                        o.titles = o.titles.map(txt => this.$t(txt));
                    }
                });
            });

            return options;
        },
        async getQuestionDetails() {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;
            this.$store.commit('addLayoutLoadingCount');

            try {
                const { data } = await axios.get(
                    getUrlWithParams(API.QUESTION, this.$route.params),
                );

                if (data) {
                    this.question = data;
                }

                this.$emit('on-set-title', this.question.name);
            } catch (error) {
                this.$store.dispatch('doAlert', {
                    text: this.errorMessage(error),
                    type: 'error',
                });
            }

            this.isLoading = false;
            this.$store.commit('decreaseLayoutLoadingCount');
        },
        async deleteAnswer() {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;
            this.$store.commit('addLocalLoadingCount');

            try {
                await axios.delete(
                    getUrlWithParams(API.ANSWER, { id: String(this.activeId) }),
                );

                this.answerDialogue = false;
                this.$refs['answer-submit-dialogue'].removeOutsideClickListener();
                this.$popup.hide('popupOnAnswerDelete');
                this.$refs['marker-controls'].deletePoints();
                this.onSetNewAnswerInstance();

                this.$store.dispatch('doAlert', {
                    text: this.$t('feelings.answerDelete.success', { id: this.activeId }),
                    type: 'success',
                });
            } catch (error) {
                this.$store.dispatch('doAlert', {
                    text: this.errorMessage(error),
                    type: 'error',
                });
            }

            this.isLoading = false;
            this.$store.commit('decreaseLocalLoadingCount');
        },
        async getAllAnswers() {
            this.$store.commit('addLocalLoadingCount');

            try {
                const { data } = await axios.get(
                    getUrlWithParams(API.ANSWERS, { id: this.getQuestionId }),
                );

                if (data) {
                    this.answers = data;
                }
            } catch (error) {
                this.$store.dispatch('doAlert', {
                    text: this.errorMessage(error),
                    type: 'error',
                });
            }

            this.$store.commit('decreaseLocalLoadingCount');
        },
        async getAnswer(id) {
            this.$store.commit('addLocalLoadingCount');

            try {
                const { data } = await axios.get(
                    getUrlWithParams(API.ANSWER, { id }),
                );

                if (data) {
                    this.answer = data;
                }
            } catch (error) {
                this.$store.dispatch('doAlert', {
                    text: this.errorMessage(error),
                    type: 'error',
                });
            }

            this.$store.commit('decreaseLocalLoadingCount');
        },
        async setCurrentInstance(ev) {
            if (ev.isNotSaved) {
                this.setInstanceWithUnsavedPoints(ev);
                return;
            }

            if (this.isLoading) {
                return;
            }

            this.isLoading = true;
            this.$store.commit('addLocalLoadingCount');

            try {
                await Promise.all([
                    this.getAnswer(ev.id),
                ]);

                const options = {
                    openDialogue: true,
                    saveAnswer: false,
                    type: this.markerType,
                };

                this.setAnswerMarkers(options);
                this.$refs['answer-submit-dialogue'].resetDraggable();
                // this.$refs['answer-submit-dialogue'].setOutsideClickListener();
                // this.$refs['marker-controls'].onSubmitAnswer(options);
            } catch (error) {
                this.$store.dispatch('doAlert', {
                    text: this.errorMessage(error),
                    type: 'error',
                });
            }

            this.isLoading = false;
            this.$store.commit('decreaseLocalLoadingCount');
        },
        async saveAnswer(markers) {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;
            this.$store.commit('addLocalLoadingCount');

            const coords = markers.map(marker => ({
                x: marker.getCoords().x,
                y: marker.getCoords().y,
            }));

            const processedData = {
                questionId: (this.answer && this.answer.id) || this.getQuestionId,
                entity: {
                    coordinates: coords,
                    locationType: this.markerType.toUpperCase(),
                },
            };

            let data = null;

            if (this.answer) {
                data = await axios.put(
                    getUrlWithParams(API.UPDATE_ANSWER, { id: processedData.questionId }),
                    processedData,
                );
            } else {
                data = await axios.post(
                    API.SAVE_ANSWER,
                    processedData,
                );

                if (data.data) {
                    this.$refs['marker-controls'].setMarkersFromBe(data.data);
                }
            }

            if (data.data) {
                this.answer = data.data;
                this.$store.dispatch('doAlert', {
                    text: this.$t('feelings.answerSaved'),
                    type: 'success',
                });
            }
        },
        onSetNewAnswerInstance() {
            setTimeout(() => {
                this.answerDialogue = false;
            }, 350);

            this.$refs['marker-controls'].onSetNewInstance();
        },
        onSubmitAnswerMarkers(options) {
            this.setAnswerMarkers(options);
        },
        onChangeAnswerInstance(ev) {
            this.setCurrentInstance(ev);
            this.$refs['answer-submit-dialogue'].resetDraggable(ev);
            // this.$refs['answer-submit-dialogue'].setOutsideClickListener();
        },
        setInstanceWithUnsavedPoints(ev) {
            this.answer = null;
            this.answerDialogue = false;
            this.markerType = ev.type;

            this.$refs['marker-controls'].onEnableMarkerControls();
        },
        setAnswerMarkers({ openDialogue, saveAnswer = false, markers }) {
            if (saveAnswer) {
                this.answer = null;
                this.saveAnswer(markers)
                    .then(() => {
                        this.$refs['answer-submit-dialogue'].resetDraggable();

                        this.answerDialogue = true;
                        // this.$refs['answer-submit-dialogue'].setOutsideClickListener();
                    })
                    .catch((error) => {
                        this.$store.dispatch('doAlert', {
                            text: this.errorMessage(error),
                            type: 'error',
                        });
                    })
                    .then(() => {
                        this.isLoading = false;
                        this.$store.commit('decreaseLocalLoadingCount');
                    });
            } else {
                this.answerDialogue = openDialogue;
            }
        },
        setMarkerType(type) {
            this.$refs['marker-controls'].readyMarkerType(type);
        },
        onMapLoaded() {
            this.mapLoaded = true;
        },
    },
};
</script>
