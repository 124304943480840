<template>
    <v-form
        ref="form"
        data-test="form-login"
        @submit.prevent="validate"
    >
        <v-layout column>
            <v-flex>
                <v-text-field
                    v-model="formData.email"
                    :prepend-icon="$options.ITEMS.email.icon"
                    :type="$options.ITEMS.email.type"
                    :label="$t($options.ITEMS.email.label)"
                    :rules="trans($options.ITEMS.email.validators)"
                    :required="$options.ITEMS.email.required"
                    autofocus
                    autocomplete="email username"
                    class="mb-4"
                    data-test="form-login-email"
                >
                    <template #message="{message}">
                        <v-icon
                            color="red"
                            size="16"
                            class="error-message__icon"
                        >
                            mdi-alert-circle
                        </v-icon>
                        {{ message }}
                    </template>
                </v-text-field>

                <v-text-field
                    v-model="formData.password"
                    :prepend-icon="$options.ITEMS.password.icon"
                    :append-icon="isVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="isVisible ? 'text' : $options.ITEMS.password.type"
                    :label="$t($options.ITEMS.password.label)"
                    :rules="trans($options.ITEMS.password.validators)"
                    :required="$options.ITEMS.password.required"
                    autocomplete="current-password"
                    class="mb-2"
                    data-test="form-login-password"
                    @click:append="togglePasswordVisibility"
                >
                    <template #message="{message}">
                        <v-icon
                            color="red"
                            size="16"
                            class="error-message__icon"
                        >
                            mdi-alert-circle
                        </v-icon>
                        {{ message }}
                    </template>
                </v-text-field>
            </v-flex>

            <v-flex
                mb-4
                class="text-left text-smedium"
            >
                <router-link
                    :to="{name: $options.ROUTE.FORGOT}"
                    :target="external ? '_blank' : null"
                    data-test="form-login-link-forgot"
                >
                    {{ $t('login.forgotPassword') }}
                </router-link>
            </v-flex>

            <v-flex py-4>
                <v-layout
                    justify-space-between
                    row
                >
                    <v-flex
                        shrink
                    >
                        <v-btn
                            rounded
                            color="primary"
                            text
                            :to="{name: $options.ROUTE.REGISTER}"
                            :target="external ? '_blank' : null"
                        >
                            {{ $t('login.register') }}
                        </v-btn>
                    </v-flex>

                    <v-flex
                        shrink
                    >
                        <v-btn
                            rounded
                            color="secondary"
                            type="submit"
                            :loading="isLoading"
                            :disabled="isLoading"
                            data-test="form-login-submit"
                        >
                            {{ $t('login.submit') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import axios from 'axios';
import { ROUTE } from '../../constants/route';
import { API } from '../../constants/api';
import { USER_LOGIN } from '../../constants/form';

export default {
    ROUTE,
    ITEMS: USER_LOGIN.ITEMS,

    props: {
        external: Boolean,
    },
    data() {
        return {
            isVisible: false,
            isLoading: false,
            formData: {
                email: '',
                password: '',
            },
        };
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.send();
            }
        },
        togglePasswordVisibility() {
            this.isVisible = !this.isVisible;
        },
        send() {
            if (this.isLoading) return;
            this.isLoading = true;

            axios({
                url: API.LOGIN,
                method: 'post',
                data: this.formData,
            })
                .then((response) => {
                    if (response) {
                        this.$store.dispatch('user/doLogin', response.data);
                        this.getUser(response.data && response.data.token);

                        this.$store.dispatch('doAlert', {
                            text: this.$t('login.success'),
                            type: 'success',
                        });
                    } else {
                        this.$store.dispatch('doAlert', {
                            text: this.$t('network.unknownError'),
                            type: 'error',
                        });
                    }
                })
                .catch((error) => {
                    const errorMessage = this.errorMessage(error);

                    this.$store.dispatch('doAlert', {
                        text: errorMessage,
                        type: 'error',
                    }, { root: true });
                })
                .then(() => {
                    this.isLoading = false;
                });
        },
        getUser(token) {
            axios.get(API.USER)
                .then((user) => {
                    this.$store.dispatch('user/doSetUser', user.data);
                    this.$emit('logged', token);
                })
                .catch((error) => {
                    this.$store.dispatch('doAlert', {
                        type: 'error',
                        text: this.errorMessage(error),
                    });
                });
        },
    },
};
</script>
