<template>
    <div>
        <div class="py-4">
            <h1 class="h2 text-regular">
                {{ $t('reset.title') }}
            </h1>

            <p
                v-if="response.error"
                class="text-left error--text pt-4 text-smedium"
            >
                {{ response.error }}
            </p>

            <p
                class="pt-4 mb-0 text-left text-smedium"
            >
                <template v-if="response.data">
                    {{ $t('reset.success') }}
                </template>

                <template v-else>
                    {{ $t('reset.instructions') }}
                </template>
            </p>
        </div>

        <div class="py-4">
            <router-link
                v-if="response.data"
                :to="{name: $options.ROUTE.LOGIN}"
                class="text-left"
            >
                {{ $t('reset.login') }}
            </router-link>

            <form-reset
                v-else
                @success="onSuccess"
                @error="onError"
            ></form-reset>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            response: {
                error: null,
                data: null,
            },
        };
    },

    methods: {
        onError(response) {
            this.response.error = response;
        },
        onSuccess(response) {
            this.response.data = response;
        },
    },
};
</script>
