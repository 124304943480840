import { render, staticRenderFns } from "./EcarewaveAbout.vue?vue&type=template&id=a3d14482&scoped=true&"
import script from "./EcarewaveAbout.vue?vue&type=script&lang=js&"
export * from "./EcarewaveAbout.vue?vue&type=script&lang=js&"
import style0 from "./EcarewaveAbout.vue?vue&type=style&index=0&id=a3d14482&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3d14482",
  null
  
)

export default component.exports