/* eslint-disable */
import $ from 'jquery';
import SlideMenu from './components/SlideMenu';
import WOW from 'wow.js';

export function initEcarewave() {
    const wow = new WOW();
    wow.init();

    $(document).ready(() => {
        $('.form-control').on('input change', function () {
            const $el = $(this);
            $el.parents('.input-group').first().toggleClass('filled', $el.val().trim().length > 0);
        });

        SlideMenu.init();

        // $('a[href^="#"]').click(function (e) {
        //     e.preventDefault();
        //     const $elm = $($(this).attr('href'));
        //     if ($elm.length === 0) return;
        //
        //     $('.js-slide-menu-close').trigger('click');
        //
        //     $('html, body').animate({
        //         scrollTop: $elm.offset().top,
        //     }, 600);
        // });

        let sendingConctact = false;
        $('.js-contact').submit(function (ev) {
            ev.preventDefault();
            if (sendingConctact) return;
            sendingConctact = true;

            const $this = $(this);
            const $subtit = $this.find('.js-contact-submit');
            const $success = $this.find('.form__success');
            const $error = $this.find('.form__error');
            const submitContent = $subtit.html();
            $subtit.html('<span class="loader"></span>');
            $this.find('.form__input--error').removeClass('form__input--error');
            $success.empty();
            $error.empty();
            $
                .ajax({
                    method: this.method,
                    url: this.action,
                    data: $this.serialize(),
                })
                .done(() => {
                    $success.text('Thanks for contacting us! We will get in touch with you');
                })
                .fail((jqXHR) => {
                    if (jqXHR.status === 422) {
                        jqXHR.responseJSON.forEach((error) => {
                            $this.find(`[name="${error}"]`).addClass('form__input--error');
                        });
                    } else {
                        $error.text('Something went wrong. Try again later.');
                    }
                })
                .always(() => {
                    sendingConctact = false;
                    $subtit.html(submitContent);
                });
        });
    });
}
