<template>
    <div class="attachments">
        <a
            v-for="attachment in attachments"
            :key="attachment.id"
            :href="attachment.url"
            class="attachments__item"
            :class="{'attachments__item--image': !attachment.isLoading}"
            :style="{ backgroundImage: `url(${attachment.urlThumb})` }"
            @click.capture="preventIfLoading(attachment, $event)"
        >
            <img
                :src="attachment.urlThumb"
                class="attachments__image"
            >
            <v-progress-circular
                v-if="attachment.isLoading"
                :value="attachment.progress"
                :indeterminate="attachment.progress === 0 || attachment.progress === 100"
                :width="15"
                :size="100"
                color="primary"
            ></v-progress-circular>
            <v-icon
                v-else
                medium
                class="attachments__delete pa-1"
                @click.prevent.stop="deleteFile(attachment)"
            >
                mdi-delete-forever
            </v-icon>
        </a>

        <div
            v-show="isLoading"
            class="attachments__item attachments__item--loading"
        >
            <v-progress-circular
                color="grey lighten-5"
                indeterminate
            ></v-progress-circular>
        </div>

        <form
            v-show="!isLoading"
            class="attachments__item"
            :class="{ 'attachments__item--dragging': isDragging }"
        >
            <v-icon
                color="white"
                x-large
            >
                mdi-plus
            </v-icon>

            <input
                type="file"
                accept="image/*"
                multiple
                class="attachments__input"
                @dragenter="isDragging = true"
                @drop="isDragging = false"
                @dragleave="isDragging = false"
                @change="addFile"
            >
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import loadImage from 'blueimp-load-image';
import { API, getUrlWithParams } from '@/js/constants/api';
import { IMAGE_JPEG_QUALITY, MAX_HEIGHT, MAX_WIDTH } from '@/js/constants/image';

let newId = 0;

export default {
    cancelToken: null,

    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            attachments: [],
            isLoading: false,
            isDragging: false,
        };
    },
    watch: {
        id() {
            this.loadAttachments();
        },
        attachments() {
            this.destroyGallery();
            this.$nextTick(() => {
                window.lightGallery(this.$el, {
                    download: false,
                    getCaptionFromTitleOrAlt: false,
                    selector: '.attachments__item--image',
                });
            });
        },
    },
    created() {
        this.loadAttachments();
    },
    beforeDestroy() {
        this.destroyGallery();
    },
    methods: {
        loadAttachments() {
            if (this.isLoading) {
                this.$options.cancelToken();
            }

            this.attachments = [];
            this.isLoading = true;
            this.$store.commit('addLocalLoadingCount');

            axios({
                url: getUrlWithParams(API.ANSWER_ATTACHMENT, { id: this.id }),
                cancelToken: new axios.CancelToken((cancelToken) => {
                    this.$options.cancelToken = cancelToken;
                }),
            })
                .then(({ data }) => {
                    this.attachments = data;
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        return;
                    }

                    this.$store.dispatch('doAlert', {
                        type: 'error',
                        text: this.errorMessage(error),
                    });
                })
                .then(() => {
                    this.isLoading = false;
                    this.$store.commit('decreaseLocalLoadingCount');
                });
        },
        async addFile(event) {
            const { target } = event;
            const { files } = target;

            if (!files.length) {
                return;
            }

            const data = new FormData();
            const newAttachments = [];
            const promises = [];

            for (let i = 0; i < files.length; i += 1) {
                const file = files[i];
                if (!file.type.startsWith('image/')) {
                    this.$store.dispatch('doAlert', {
                        type: 'info',
                        message: this.$t('feelings.attachments.typeCheckFailed'),
                    });

                    target.value = '';
                    return;
                }

                const id = `new-${newId}`;
                const url = URL.createObjectURL(file);

                newId += 1;

                const attachment = {
                    id,
                    url,
                    urlThumb: url,
                    isLoading: true,
                    progress: 0,
                };

                this.attachments.push(attachment);
                newAttachments.push(attachment);

                promises.push(new Promise((resolve) => {
                    loadImage(
                        file,
                        (image) => {
                            if (image instanceof Event) {
                                this.$store.dispatch('doAlert', {
                                    type: 'error',
                                    text: this.$t('network.error', { reason: image.message }),
                                });
                                return;
                            }

                            image.toBlob((blob) => {
                                data.append('files', blob);
                                resolve();
                            }, 'image/jpeg', IMAGE_JPEG_QUALITY);
                        },
                        {
                            canvas: true,
                            maxWidth: MAX_WIDTH,
                            maxHeight: MAX_HEIGHT,
                            orientation: true,
                        },
                    );
                }));
            }
            target.value = '';

            await Promise.all(promises);

            axios({
                url: API.ADD_ANSWER_ATTACHMENT,
                method: 'post',
                params: {
                    answerId: this.id,
                },
                data,
                onUploadProgress(progress) {
                    newAttachments.forEach((attachment) => {
                        attachment.progress = (progress.loaded / progress.total) * 100;
                    });
                },
            })
                .then(({ data: { attachments = [], failedUploads = [] } }) => {
                    newAttachments.forEach((attachment) => {
                        this.attachments.splice(
                            this.attachments.indexOf(attachment),
                            1,
                        );
                    });
                    this.attachments.push(...attachments);

                    // TODO show all.. now alert can show only one alert at a time!
                    // maybe update alert module, to show more in a time, or in sequence
                    failedUploads.forEach((fail) => {
                        this.$store.dispatch('doAlert', {
                            type: 'error',
                            text: this.$t('network.error', { reason: fail.detail }),
                        });
                    });
                })
                .catch((error) => {
                    newAttachments.forEach((attachment) => {
                        this.attachments.splice(
                            this.attachments.indexOf(attachment),
                            1,
                        );
                    });

                    this.$store.dispatch('doAlert', {
                        type: 'error',
                        text: this.errorMessage(error),
                    });
                });
        },
        deleteFile(attachment) {
            if (attachment.isLoading) {
                return;
            }

            this.$set(attachment, 'isLoading', true);
            this.$set(attachment, 'progress', 0);

            axios.delete(getUrlWithParams(API.REMOVE_ANSWER_ATTACHMENT, attachment))
                .then(() => {
                    this.attachments.splice(
                        this.attachments.indexOf(attachment),
                        1,
                    );
                })
                .catch((error) => {
                    this.$store.dispatch('doAlert', {
                        type: 'error',
                        text: this.errorMessage(error),
                    });
                });
        },
        destroyGallery() {
            const lgUid = this.$el.getAttribute('lg-uid');
            if (lgUid) {
                window.lgData[lgUid].destroy(true);
            }
        },
        preventIfLoading(attachment, event) {
            if (attachment.isLoading) {
                event.preventDefault();
            }
        },
    },
};
</script>
