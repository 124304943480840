import axios from 'axios';
import { API } from '@/js/constants/api';
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState(['utmParams']),
    },
    methods: {
        loadUser(cb) {
            axios.get(API.USER)
                .then((user) => {
                    this.$store.dispatch('user/doSetUser', user.data);

                    if (cb) {
                        cb();
                    }
                })
                .catch(({ message, response }) => {
                    if (response && response.status === 401) {
                        this.$store.dispatch('user/doLogout');
                        this.registerSyntheticUser(cb);
                    } else {
                        this.$store.dispatch('doAlert', {
                            type: 'error',
                            text: this.errorMessage(message),
                        });
                    }
                });
        },
        async registerSyntheticUser(cb) {
            try {
                const utm = this.utmParams;
                const { data } = await axios({
                    url: API.REGISTER_SU,
                    method: 'post',
                    data: {
                        utm,
                    },
                });

                if (data) {
                    this.$store.dispatch('user/doLogin',
                        { token: data.token });
                }

                this.loadUser(cb);
            } catch ({ message, response }) {
                if (response && response.status === 401) {
                    this.$store.dispatch('user/doLogout');
                } else {
                    this.$store.dispatch('doAlert', {
                        type: 'error',
                        text: this.errorMessage(message),
                    });
                }
            }
        },
    },
};
