<script>
import Vue from 'vue';
import CurrentPosition from '@/js/components/CurrentPosition';

export default {
    props: {
        map: {
            type: Object,
            default: null,
        },
        mapMethods: {
            type: Object,
            default: null,
        },
        optionsPos: {
            type: Object,
            default: () => ({}),
        },
        optionsDefault: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            markerLayer: null,
            isInitialized: false,
        };
    },
    mounted() {
        if (!true.isInitialized) {
            this.isInitialized = true;
            this.initControls();
        }
    },
    methods: {
        initControls() {
            if (this.mapMethods) {
                this.createMarkerLayer();
                this.addControlItems(this.optionsDefault);
                this.doAddControlItemCurrentPos({
                    ...this.optionsPos,
                    map: this.map,
                    markerLayer: this.markerLayer,
                });
                this.addMouseSupport();
            }
        },
        createMarkerLayer() {
            this.markerLayer = new SMap.Layer.Marker();

            this.mapMethods.addAdditionalLayer(this.markerLayer);
            this.markerLayer.enable();
        },
        addControlItems(options) {
            options.forEach((option) => {
                this.map.addControl(
                    new SMap.Control[option.name](...option.options),
                    option.placement,
                );
            });
        },
        doAddControlItemCurrentPos(options) {
            const container = document.createElement('div');
            // eslint-disable-next-line no-underscore-dangle
            const getHudContainer = this.map._dom.layers
                .find((item) => item.classList.contains('hud'))
                .querySelector('.noprint');

            const Component = Vue.extend(CurrentPosition);
            const instance = new Component({

                propsData: {
                    ...options,
                },
                parent: this,
            });

            instance.$mount();
            container.appendChild(instance.$el);
            container.setAttribute('class', 'current-position');

            Object.keys(options).forEach((option) => {
                container.style[option] = options[option];
            });

            getHudContainer.appendChild(container);

            this.map.currentPosition = {
                _container: container,
            };
        },
        addMouseSupport() {
            /* eslint-disable no-bitwise */
            const mouse = new SMap.Control.Mouse(
                SMap.MOUSE_PAN
                    | SMap.MOUSE_WHEEL
                    | SMap.MOUSE_ZOOM,
            );
            /* eslint-enable no-bitwise */

            this.map.addControl(mouse);
        },
    },
    render() {
        return {};
    },
};
</script>
