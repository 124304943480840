<template>
    <div class="menu-lang-switcher">
        <v-menu
            offset-y
            auto
        >
            <template #activator="{on, attrs}">
                <v-btn
                    color="white"
                    dark
                    fab
                    small
                    depressed
                    v-bind="attrs"
                    v-on="on"
                >
                    <country-flag
                        :country="$t('languages.icon')"
                        size="small"
                    ></country-flag>
                </v-btn>
            </template>

            <v-list>
                <v-list-item
                    v-for="lang in languages"
                    :key="lang.CODE"
                    color="#222"
                    @click.prevent="setLanguage(lang.CODE)"
                >
                    <v-list-item-content>
                        <country-flag
                            :country="lang.ICON"
                            size="small"
                        ></country-flag>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'MenuLangSwitcher',
    props: {
        languages: {
            type: Array,
            required: true,
        },
    },
    methods: {
        setLanguage(lang) {
            this.$i18n.locale = lang;
            this.$store.dispatch('doSetLanguage', lang);
        },
    },
};
</script>

<style lang="less">
.menu-lang-switcher {
    position: relative;
    height: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .small-flag {
        margin: 0 !important;
    }
}
</style>
