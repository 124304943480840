<template>
    <v-app
        class="d-flex transparent"
        :class="{'is-ecarewave': isEcarewave}"
    >
        <v-main>
            <v-container
                fill-height
                fluid
                ma-0
                pa-0
            >
                <router-view></router-view>

                <alerts></alerts>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import axios from 'axios';
import loadUser from '@/js/mixins/loadUser';
import Alerts from '@/js/components/alerts/Alerts';
import { mapGetters } from 'vuex';
import { ROUTE } from './constants/route';
import { MAP_INSTANCE } from './constants/map';

export default {
    components: {
        Alerts,
    },
    mixins: [loadUser],
    computed: {
        ...mapGetters(['isEcarewave']),
    },
    created() {
        this.loadUser(this.onUserLoad);
        this.setInterceptorForApiCalls();

        // Reset question map position and zoom to defaults
        this.$store.dispatch('map/doReset', MAP_INSTANCE.QUESTION);
    },
    methods: {
        onUserLoad() {
            this.$root.$emit('userLoaded');
        },
        setInterceptorForApiCalls() {
            axios.interceptors.response.use(undefined, (err) => {
                if (
                    this.isLogged
                    && err.response
                    && err.response.status === 401
                    && err.config
                    // eslint-disable-next-line no-underscore-dangle
                    && !err.config.__isRetryRequest
                ) {
                    if (err.response.data.errorCode
                        && err.response.data.errorCode === 'INVALID_CREDENTIALS') {
                        return Promise.reject(err);
                    }

                    this.$store.dispatch('user/doLogout');

                    if (!this.$route.meta.noRedirect) {
                        this.$router.replace({ name: ROUTE.MAP });
                    }

                    window.location.reload();
                }

                return Promise.reject(err);
            });
        },
    },
};
</script>

<style lang="less">
@import '../less/app.less';

.transparent {
    background: transparent !important;
}

.v-application--wrap {
    height: 100%;
    min-height: auto;
}

.is-ecarewave {
    height: auto;

    .v-application--wrap {
        height: auto;
    }
}
</style>
