export const CATEGORY_ICONS = {
    1: 'tree',
    2: 'car',
    3: 'home',
};

export const MARKER_ICONS = {
    POINT: 'icon-marker',
    PATH: 'icon-path',
    LINESTRING: 'icon-path',
    POLYGON: 'icon-poly',
};

export const MISC_ICONS = {
    MARKER_CONTROLS_DEFAULT: 'mdi-plus',
    MARKER_CONTROLS_CONFIRM: 'mdi-check',
};
