<template>
    <div>
        <div class="header-home elevation-3">
            <div
                v-if="!embed"
                class="header-home__left"
            >
                <div class="menu-primary__trigger">
                    <v-btn
                        text
                        icon
                        color="default"
                        @click.prevent.stop="toggleMenu"
                    >
                        <v-icon class="menu-primary__trigger-icon">
                            mdi-menu
                        </v-icon>
                    </v-btn>
                </div>
            </div>

            <div class="header-home__center">
                <v-autocomplete
                    v-model="mapAutocompleteValue"
                    :placeholder="this.$t('controls.searchHint')"
                    class="header-home__autocomplete"
                    append-icon="null"
                    :items="mapAutocompleteItems"
                    item-text="title"
                    item-value="id"
                    :search-input.sync="mapAutocompleteSearch"
                    :loading="mapAutocompleteIsLoading"
                    clearable
                    no-filter
                    hide-no-data
                    hide-details
                    return-object
                >
                    <template #item="{ item }">
                        <v-list-item-avatar>
                            <v-icon
                                v-if="item.resultType"
                                color="primary"
                            >
                                {{ item.resultType === 'locality'
                                    ? 'icon-city-icon'
                                    : 'icon-direction' }}
                            </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="item.title"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                                v-text="item.secondRow"
                            ></v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </div>

            <div
                v-if="!embed"
                class="header-home__right"
            >
                <v-menu
                    v-if="isLogged && !isLoggedSynthetic"
                    offset-y
                    min-width="120"
                >
                    <template #activator="{ on }">
                        <v-btn
                            color="primary"
                            fab
                            small
                            depressed
                            class="header-home__user-btn"
                            v-on="on"
                        >
                            <v-icon
                                size="30"
                                color="primary"
                            >
                                mdi-account-circle
                            </v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item
                            :to="{name: $options.ROUTE.PROFILE}"
                        >
                            <v-list-item-action>
                                <v-icon>
                                    mdi-account
                                </v-icon>
                            </v-list-item-action>

                            <v-list-item-title>
                                {{ $t($options.MENU_ITEMS.PROFILE) }}
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            @click.prevent="logout"
                        >
                            <v-list-item-action>
                                <v-icon>
                                    mdi-power
                                </v-icon>
                            </v-list-item-action>

                            <v-list-item-title>
                                {{ $t($options.MENU_ITEMS.LOGOUT) }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <template v-else>
                    <v-btn
                        color="primary"
                        fab
                        small
                        depressed
                        class="header-home__user-btn header-home__user-btn--not-logged"
                        :to="{name: $options.ROUTE.LOGIN}"
                        data-test="menu-header-login-button"
                    >
                        <v-icon
                            size="26"
                            color="primary lighten-3"
                        >
                            mdi-account-outline
                        </v-icon>
                    </v-btn>
                </template>
            </div>
        </div>

        <menu-primary
            v-if="!embed"
            :items="items"
            :toggle.sync="toggle"
        ></menu-primary>
    </div>
</template>

<script>
import { ROUTE } from '@/js/constants/route';
import logoutMixin from '@/js/mixins/logoutMixin';
import mapAutocomplete from '@/js/mixins/mapAutocomplete';
import { MENU_ITEMS } from '@/js/constants/menu';

export default {
    ROUTE,
    MENU_ITEMS,

    mixins: [
        logoutMixin,
        mapAutocomplete,
    ],

    props: {
        embed: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            toggle: false,
            items: [
                {
                    title: MENU_ITEMS.CAMPAIGNS,
                    icon: 'mdi-view-dashboard',
                    url: ROUTE.CAMPAIGNS,
                },
            ],
        };
    },

    methods: {
        toggleMenu() {
            this.toggle = !this.toggle;
        },
    },
};
</script>
