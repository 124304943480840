<template>
    <div class="map-timeline">
        <template
            v-if="hasLayers"
        >
            <v-btn
                class="map-timeline__button"
                color="secondary"
                fab
                small
                @click="addYear(-1)"
            >
                <v-icon>
                    mdi-chevron-double-left
                </v-icon>
            </v-btn>
            <v-btn
                class="map-timeline__button"
                color="primary"
                fab
                small
                @click="addMonth(-1)"
            >
                <v-icon>
                    mdi-chevron-left
                </v-icon>
            </v-btn>
        </template>

        <div
            :class="{ 'is-disabled': !hasLayers }"
            class="map-timeline__button v-btn
                v-btn--is-elevated v-btn--has-bg
                v-btn--rounded theme--light v-size--large"
            @click.prevent
        >
            <span
                class="text-color-primary mr-1"
                v-text="currentMonth"
            ></span>
            <span
                class="text-color-secondary"
                v-text="currentYear"
            ></span>
        </div>
        <template
            v-if="hasLayers"
        >
            <v-btn
                class="map-timeline__button"
                color="primary"
                fab
                small
                @click="addMonth(1)"
            >
                <v-icon>
                    mdi-chevron-right
                </v-icon>
            </v-btn>
            <v-btn
                class="map-timeline__button"
                color="secondary"
                fab
                small
                @click="addYear(1)"
            >
                <v-icon>
                    mdi-chevron-double-right
                </v-icon>
            </v-btn>
        </template>
    </div>
</template>

<script>
import {
    addMonths, addYears, format, lastDayOfMonth,
} from 'date-fns';
import { FORMAT_DEFAULT } from '@/js/constants/date';

export default {
    name: 'MapTimeline',
    props: {
        dateFrom: {
            type: String,
            default: '1990-01-01',
        },
        dateTo: {
            type: String,
            default: null,
        },
        hasLayers: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const dateToLocal = this.dateTo
            ? new Date(this.dateTo)
            : new Date(new Date());

        return {
            dates: null,
            menu: false,
            localDates: null,
            currentDate: 'December 2020',
            dateFromLocal: new Date(this.dateFrom),
            dateToLocal,
        };
    },
    computed: {
        currentYear() {
            return format(this.dateToLocal, 'yyyy');
        },
        currentMonth() {
            return this.$d(this.dateToLocal, 'month_long');
        },
    },
    created() {
        this.initDates();
    },
    methods: {
        initDates() {
            this.currentDate = format(this.dateToLocal, 'LLLL yyyy');
        },
        emitDates() {
            const dates = {
                dateFrom: this.dateFromLocal,
                dateTo: this.dateToLocal,
            };
            Object.keys(dates).forEach((key) => {
                dates[key] = format(dates[key], FORMAT_DEFAULT);
            });

            this.$emit('set-timeline', dates);
        },
        setLastDayMonth(date) {
            return lastDayOfMonth(date);
        },
        addMonth(val) {
            const date = this.setLastDayMonth(addMonths(this.dateToLocal, val));

            if (date < this.dateFromLocal) {
                return;
            }

            this.dateToLocal = date;
            this.emitDates();
        },
        addYear(val) {
            const date = this.setLastDayMonth(addYears(this.dateToLocal, val));

            if (date < this.dateFromLocal) {
                return;
            }

            this.dateToLocal = date;
            this.emitDates();
        },
    },
};
</script>

<style lang="less">
@import './src/less/variables';

.map-timeline {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 3;

    max-width: 420px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    transform: translateX(-50%);
}

.map-timeline__button {
    &.v-btn:not(.v-btn--round) {
        height: 30px;
        max-width: 180px;
        width: 100%;

        display: flex;
        flex-wrap: wrap;
        flex: 0 1 auto;

        font-weight: 700;
        line-height: 1;

        @media (min-width: @break-t-min) {
            height: 40px;
        }

        &:before {
            content: none;
        }

        &.is-disabled {
            opacity: .7;
        }
    }

    &.v-btn--fab.v-size--small {
        @media (max-width: @break-t-max) {
            height: 30px;
            width: 30px;

            margin: 6px 6px;
        }
    }
}
</style>
