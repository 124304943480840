var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list"},[_vm._t("prepend"),(_vm.data.length === 0)?_c('div',{staticClass:"list__tile list__tile--no-results"},[_vm._t("no-results",[_vm._v(" "+_vm._s(_vm.$t('common.emptyList'))+" ")])],2):_vm._l((_vm.data),function(item,index){return _c('router-link',{key:("$listItemId" + (item.id)),staticClass:"list__tile",attrs:{"to":{
                name: _vm.isCampaign ? _vm.$options.ROUTE.CAMPAIGN : _vm.$options.ROUTE.QUESTION,
                params: Object.assign({}, {cid: item.id,
                    qid: item.id},
                    _vm.params),
            },"tag":"div"}},[_c('div',{staticClass:"list__wrapper list__link list__wrapper--top"},[_c('div',{staticClass:"list__action"},[(_vm.isCampaign)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 list__button list__button--main",attrs:{"color":"primary","depressed":"","fab":"","dark":"","small":""}},on),[_vm._v(" "+_vm._s(item.city && item.city.key)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.city && item.city.name)+" ")])]):_c('v-btn',{staticClass:"ma-0 list__button list__button--main",attrs:{"color":"primary","depressed":"","fab":"","dark":"","small":""}},[_vm._v(" "+_vm._s(index + 1)+". ")])],1),_c('div',{staticClass:"list__content"},[_c('div',{staticClass:"list__title"},[_vm._v(" "+_vm._s(item[_vm.titleProperty])+" ")])]),(_vm.isCampaign)?_c('div',{staticClass:"list__action list__action--right"},[_c('v-container',{attrs:{"grid-list-md":"","pa-0":""}},[_c('v-layout',{attrs:{"wrap":"","justify-end":""}},_vm._l((item.categories),function(tag){return _c('v-flex',{key:("$tagId" + (tag.id)),attrs:{"shrink":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"list__button ma-0",attrs:{"color":"grey","depressed":"","fab":"","dark":"","small":"","icon":""}},on),[_c('v-icon',[_vm._v(" icon-"+_vm._s(tag.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(tag.name)+" ")])])],1)}),1)],1)],1):_vm._e()]),_c('div',{staticClass:"list__wrapper list__wrapper--bottom"},[_c('div',{staticClass:"list__action"},[(_vm.isCampaign)?[_c('v-icon',[_vm._v(" mdi-calendar ")])]:_vm._e()],2),_c('div',{staticClass:"list__content"},[(_vm.isCampaign)?_c('div',{staticClass:"list__subtitle"},[_vm._v(" "+_vm._s(_vm.campaignDuration(item.daysLeft))+" ")]):_c('v-container',{attrs:{"pa-0":""}},[_c('v-layout',{attrs:{"ma-0":""}},[_c('v-flex',[_c('div',{staticClass:"list__entity",class:{
                                        'list__entity--active':
                                            !_vm.isNull(item.numAnswers)
                                    }},[_c('v-icon',{staticClass:"list__content-icon",attrs:{"color":_vm.isNull(item.numAnswers) ? null : 'primary'}},[_vm._v(" mdi-map-marker ")]),_c('span',{staticClass:"list__entities-count"},[_vm._v(" "+_vm._s(item.numAnswers || 0)+" ")])],1)]),_c('v-flex',[_c('div',{staticClass:"list__entity",class:{
                                        'list__entity--active':
                                            !_vm.isNull(item.numComments)
                                    }},[_c('v-icon',{staticClass:"list__content-icon",attrs:{"color":_vm.isNull(item.numComments)
                                            ? null
                                            : 'primary'}},[_vm._v(" mdi-message ")]),_c('span',{staticClass:"list__entities-count"},[_vm._v(" "+_vm._s(item.numComments || 0)+" ")])],1)]),_c('v-flex',[_c('div',{staticClass:"list__entity",class:{
                                        'list__entity--active':
                                            !_vm.isNull(item.numAttachments)
                                    }},[_c('v-icon',{staticClass:"list__entity-icon",class:{
                                            'list__content-icon--active':
                                                !_vm.isNull(item.numAttachments)
                                        },attrs:{"color":_vm.isNull(item.numAttachments) ? null : 'primary'}},[_vm._v(" mdi-image ")]),_c('span',{staticClass:"list__entities-count"},[_vm._v(" "+_vm._s(item.numAttachments || 0)+" ")])],1)])],1)],1)],1),(_vm.isCampaign)?_c('div',{staticClass:"list__action list__action--right list__action--fluid"},[_c('progress-bar',{attrs:{"current":item.questionsAnswered,"max":item.questionsTotal}})],1):_vm._e()])])}),_vm._t("append")],2)}
var staticRenderFns = []

export { render, staticRenderFns }