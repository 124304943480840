let usingStorage;

try {
    usingStorage = window.localStorage;
} catch (e) {
    let memoryStorage = {};
    usingStorage = {
        getItem(keyName) {
            if (Object.prototype.hasOwnProperty.call(memoryStorage, keyName)) {
                return memoryStorage[keyName];
            }
            return null;
        },
        setItem(keyName, keyValue) {
            memoryStorage[keyName] = keyValue;
        },
        removeItem(keyName) {
            delete memoryStorage[keyName];
        },
        clear() {
            memoryStorage = {};
        },
    };
}

export const storage = usingStorage;
