/* eslint-disable */

import MobileMenu from './MobileMenu'

const SlideMenu = {
    type: 'slide-left',
    wrapperId: '.js-slide-menu__wrapper',
    maskId: '.js-slide-menu__mask',
    menuOpenerClass: '.js-slide-menu',
    menuCloserClass: '.js-slide-menu-close',

    wrapper: null,
    menu: null,


    init() {
        this.body = document.body
        this.wrapper = document.querySelector(this.wrapperId)
        this.mask = document.querySelector(this.maskId)
        this.menu = document.querySelector(`.slide-menu--${this.type}`)
        this.menuOpeners = document.querySelectorAll(this.menuOpenerClass)
        this.menuClosers = document.querySelectorAll(this.menuCloserClass)

        if (!this.mask) {
            console.error('Missing mask element for SlideMenu, maybe need to add HTML.')
            return
        }

        this.initEvents()
    },

    initEvents() {
        // Event for clicks on the open buttons.
        each(this.menuOpeners, item => {
            item.addEventListener('click', e => {
                e.preventDefault()
                this.open()
            })
        })

        // Event for clicks on the mask.
        this.mask.addEventListener('click', e => {
            e.preventDefault()
            this.close()
        })

        // Event for clicks on the open buttons.
        each(this.menuClosers, item => {
            item.addEventListener('click', e => {
                e.preventDefault()
                this.close()
            })
        })
    },

    open() {
        this.body.classList.add('has-active-slide-menu')
        this.wrapper.classList.add(`has-${this.type}`)
        this.menu.classList.add('is-active')
        this.mask.classList.add('is-active')
        this.disableMenuOpeners()
    },

    close() {
        this.body.classList.remove('has-active-slide-menu')
        this.wrapper.classList.remove(`has-${this.type}`)
        this.menu.classList.remove('is-active')
        this.mask.classList.remove('is-active')
        this.enableMenuOpeners()
    },

    enableMenuOpeners() {
        each(this.menuOpeners, item => {
            item.disabled = false
        })
    },

    disableMenuOpeners() {
        each(this.menuOpeners, item => {
            item.disabled = true
        })
    },
}

function each(collection, callback) {
    // eslint-disable-next-line
    for (let i = 0; i < collection.length; i++) {
        const item = collection[i]
        callback(item)
    }
}

export default SlideMenu
