/**
 * Translation helper global mixin to be used for input validator messages;
 */
export default {
    computed: {
        trans() {
            return (v) => {
                const $t = this.$t.bind(this);

                return v.map((rule) => rule.bind(null, $t));
            };
        },
    },
};
