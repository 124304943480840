<template>
    <v-main>
        <v-container>
            <v-layout column>
                <h1 class="display-3 font-weight-black">
                    Guidelines
                </h1>

                <v-divider class="my-6"></v-divider>

                <!--Colors-->
                <v-flex>
                    <h2 class="display-2">
                        Colors
                    </h2>

                    <v-container>
                        <v-layout column>
                            <v-flex row>
                                <pre>.bg-color--primary</pre>

                                <div class="guideline-color bg-color--primary"></div>
                            </v-flex>

                            <v-flex row>
                                <pre>.bg-color--secondary</pre>

                                <div class="guideline-color bg-color--secondary"></div>
                            </v-flex>

                            <v-flex row>
                                <pre>.bg-color--error</pre>

                                <div class="guideline-color bg-color--error"></div>
                            </v-flex>

                            <v-flex row>
                                <pre>.bg-color--grey</pre>

                                <div class="guideline-color bg-color--grey"></div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
                <!--./Colors-->

                <v-divider class="my-6"></v-divider>

                <!--Typography-->
                <v-flex>
                    <h2 class="display-2">
                        Typography
                    </h2>

                    <!--Fonts-->
                    <v-container>
                        <v-layout column>
                            <v-flex row>
                                <pre>.default, .primary</pre>
                                <span class="text-primary"> Primary </span> <br>
                                <span class="text-default"> Default </span>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <!--./Fonts-->

                    <h3 class="display-1">
                        Headlines
                    </h3>

                    <!--Headlines-->
                    <v-container>
                        <v-layout column>
                            <v-flex>
                                <h1 class="h1">
                                    Heading 1
                                </h1>

                                <h2 class="h2">
                                    Heading 2
                                </h2>

                                <h3 class="h3">
                                    Heading 3
                                </h3>

                                <h4 class="h4">
                                    Heading 4
                                </h4>

                                <h5 class="h5">
                                    Heading 5
                                </h5>

                                <h6 class="h6">
                                    Heading 6
                                </h6>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <!--./Headlines-->

                    <!--Icons-->
                    <h3 class="display-1">
                        Icons
                    </h3>

                    <v-container
                        fluid
                        grid-list-md
                    >
                        <v-layout
                            row
                            wrap
                        >
                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>Marker</pre>

                                <v-icon>
                                    icon-marker
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.chat_bubble</pre>

                                <v-icon>
                                    mdi-message
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.image</pre>

                                <v-icon>
                                    image
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.delete</pre>

                                <v-icon>
                                    delete
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.person</pre>

                                <v-icon>
                                    person
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.menu</pre>

                                <v-icon>
                                    menu
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.close</pre>

                                <v-icon>
                                    close
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.check</pre>

                                <v-icon>
                                    check
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.event</pre>

                                <v-icon>
                                    event
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.search</pre>

                                <v-icon>
                                    search
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.cloud_upload</pre>

                                <v-icon>
                                    cloud_upload
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.edit</pre>

                                <v-icon>
                                    edit
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.place</pre>

                                <v-icon>
                                    place
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.directions_car</pre>

                                <v-icon>
                                    directions_car
                                </v-icon>
                            </v-flex>

                            <v-flex
                                xs4
                                sm3
                                column
                            >
                                <pre>.home</pre>

                                <v-icon class="material-icons-outlined">
                                    mdi-home
                                </v-icon>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <!--./Icons-->
                </v-flex>
                <!--./Typography-->

                <!--Buttons-->
                <v-flex>
                    <h2 class="display-2">
                        Buttons
                    </h2>

                    <v-container>
                        <v-layout
                            row
                            wrap
                        >
                            <v-flex
                                xs2
                                row
                            >
                                <v-btn
                                    color="primary"
                                    dark
                                >
                                    Primary
                                </v-btn>
                            </v-flex>

                            <v-flex
                                xs2
                                row
                            >
                                <v-btn
                                    color="secondary"
                                    dark
                                >
                                    secondary
                                </v-btn>
                            </v-flex>

                            <v-flex
                                xs2
                                row
                            >
                                <v-btn
                                    color="secondary"
                                    dark
                                    class="mx-0 btn--wide"
                                >
                                    wide
                                </v-btn>
                            </v-flex>

                            <v-flex
                                row
                                xs2
                            >
                                <v-btn
                                    color="grey darken-1"
                                    fab
                                    small
                                    depressed
                                    disabled
                                    class="is-disabled"
                                >
                                    <v-icon
                                        class="is-disabled"
                                    >
                                        mdi-home
                                    </v-icon>
                                </v-btn>
                            </v-flex>

                            <v-flex
                                row
                                xs2
                            >
                                <v-btn
                                    color="grey darken-1"
                                    fab
                                    dark
                                    small
                                    depressed
                                >
                                    <v-icon>
                                        mdi-home
                                    </v-icon>
                                </v-btn>
                            </v-flex>

                            <v-flex
                                row
                                xs2
                            >
                                <v-btn
                                    color="error"
                                    class="elevation-2"
                                    fab
                                    dark
                                    small
                                >
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </v-flex>

                            <v-flex
                                row
                                xs2
                            >
                                <v-btn
                                    color="secondary"
                                    class="elevation-2"
                                    fab
                                    dark
                                >
                                    <v-icon
                                        class="text-strong text-sxxlarge"
                                    >
                                        mdi-check
                                    </v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
                <!--./Buttons-->

                <!--Speed dial button group-->
                <v-flex>
                    <h2 class="display-2">
                        Speed dial
                    </h2>

                    <v-container>
                        <v-layout
                            row
                            wrap
                        >
                            <v-flex
                                row
                                class="relative"
                            >
                                <speed-dial></speed-dial>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
                <!--./Speed dial button group-->

                <!--Logo-->
                <v-flex>
                    <h2 class="display-2">
                        Logo
                    </h2>

                    <v-container>
                        <v-layout column>
                            <v-flex row>
                                <pre>.logo</pre>

                                <logo></logo>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
                <!--./Logo-->

                <!--Menu-->
                <v-flex>
                    <h2 class="display-2">
                        Menu Primary
                    </h2>

                    <v-container>
                        <v-layout column>
                            <v-flex row>
                                <pre>.menu-primary</pre>

                                <menu-primary
                                    :items="items"
                                ></menu-primary>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
                <!--./Menu-->

                <!--Header bar-->
                <v-flex>
                    <h2 class="display-2">
                        Header bar
                    </h2>

                    <v-container>
                        <v-layout column>
                            <v-flex row>
                                <pre>.header</pre>

                                <header-component has-search></header-component>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
                <!--./Header bar-->

                <!--Inputs-->
                <v-flex>
                    <h2 class="display-2">
                        Inputs
                    </h2>

                    <v-container>
                        <v-layout column>
                            <v-flex row>
                                <pre>.text</pre>

                                <v-text-field
                                    label="Text"
                                    prepend-icon="edit"
                                ></v-text-field>
                            </v-flex>

                            <v-flex row>
                                <pre>.text</pre>

                                <v-checkbox
                                    name="codename1"
                                    value="codename1"
                                    color="primary"
                                >
                                    <template #label>
                                        <span class="text-color-default ">
                                            Checkbox
                                        </span>
                                    </template>
                                </v-checkbox>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
                <!--./Inputs-->

                <!--Image Card-->
                <v-flex>
                    <h2 class="display-2">
                        Image card
                    </h2>

                    <v-container grid-list-md>
                        <v-layout
                            row
                            justify-start
                        >
                            <v-flex
                                row
                                :grow="false"
                                shrink
                            >
                                <v-img
                                    src="https://via.placeholder.com/150"
                                    :lazy-src="`https://picsum.photos/10/6?image=${2 * 5 + 10}`"
                                    aspect-ratio="1"
                                    class="grey lighten-2"
                                    height="84"
                                    width="84"
                                    cover
                                >
                                    <template #placeholder>
                                        <v-layout
                                            fill-height
                                            align-center
                                            justify-center
                                            ma-0
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-layout>
                                    </template>
                                </v-img>
                            </v-flex>

                            <v-flex row>
                                <v-img
                                    src="https://via.placeholder.com/150"
                                    :lazy-src="`https://picsum.photos/10/6?image=${2 * 5 + 10}`"
                                    aspect-ratio="1"
                                    class="grey lighten-2"
                                    height="84"
                                    width="84"
                                    cover
                                >
                                    <template #placeholder>
                                        <v-layout
                                            fill-height
                                            align-center
                                            justify-center
                                            ma-0
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-layout>
                                    </template>
                                </v-img>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
                <!--./Image Card-->

                <!--Listings-->
                <v-flex>
                    <h2 class="display-2">
                        Listings
                    </h2>

                    <v-container>
                        <v-layout column>
                            <v-flex row>
                                <pre>.list .list--campaign</pre>
                                <list
                                    :data="campaigns"
                                    class="list--campaign"
                                ></list>

                                <v-spacer class="my-12"></v-spacer>

                                <pre>.list .list--questions</pre>
                                <list
                                    :data="questions"
                                    type="questions"
                                    class="list--questions"
                                ></list>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
                <!--./Listings-->

                <!--Header home bar-->
                <v-flex>
                    <h2 class="display-2">
                        Header home bar
                    </h2>

                    <v-container>
                        <v-layout column>
                            <v-flex row>
                                <pre>.header-home</pre>

                                <header-home></header-home>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
                <!--./Header home bar-->
            </v-layout>
        </v-container>
    </v-main>
</template>

<script>
export default {
    data() {
        return {
            items: [
                { title: 'Home', icon: 'dashboard' },
                { title: 'About', icon: 'question_answer' },
            ],

            campaigns: [
                {
                    name: 'Kampaň #1',
                    city: 'po',
                    questions: {
                        answered: 3,
                        total: 5,
                    },
                    tags: ['home', 'edit', 'close'],
                },
                {
                    name: 'Kampaň s dlhším názvom ako sú dva riadky a viacerými kategóriami.',
                    city: 'ba',
                    questions: {
                        answered: 2,
                        total: 5,
                    },
                    tags: ['home', 'close'],
                },
            ],

            questions: [
                {
                    name: 'Kadiaľ rád chodíš?',
                    entities: {
                        numComments: 3,
                        numMarkers: 5,
                        numImages: 2,
                    },
                },
                {
                    name: 'Kde je zlé parkovanie? A ďalší riadok pre ukážku.',
                    entities: {
                        numComments: null,
                        numMarkers: null,
                        numImages: null,
                    },
                },
                {
                    name: 'Lorem ipsum?',
                    entities: {
                        numComments: null,
                        numMarkers: null,
                        numImages: null,
                    },
                },
                {
                    name: 'Dolor sit amet?',
                    entities: {
                        numComments: null,
                        numMarkers: null,
                        numImages: null,
                    },
                },
            ],
        };
    },
};
</script>
