<template>
    <v-flex
        ref="draggableElement"
        v-draggable="{
            element: '.js-dragger-trigger',
            options: {
                height: [setHeight],
                min_height: 71,
                min_clipping_height: 71,
                clipping_bottom_distance: 250,
            },
        }"
        :class="{
            'transition-draggable': !dragging,
            'question-dialogue--clipped-top': clippedTop
        }"
        class="question-dialogue elevation-6"
        @on-clipping-top="onClippingTop"
        @on-dragging="onDragging"
        @touchmove.prevent
    >
        <v-layout
            column
        >
            <div
                class="question-dialogue__header"
            >
                <div
                    v-show="!clippedTop"
                    ref="draggerTrigger"
                    class="question-dialogue__dragger mb-2 js-dragger-trigger"
                >
                    <v-icon color="grey lighten-1">
                        mdi-drag-horizontal-variant
                    </v-icon>
                </div>

                <v-toolbar
                    :class="{'question-dialogue__toolbar--clipped': clippedTop}"
                    class="question-dialogue__toolbar px-4 js-dragger-trigger elevation-0"
                    text
                >
                    <v-btn
                        :disabled="!clippedTop"
                        icon
                        class="mr-0"
                        @click.prevent.stop="onDownClick"
                        @touchstart.stop
                        @mousedown.stop
                    >
                        <v-icon>{{ clippedTop ? 'mdi-arrow-down' : '' }}</v-icon>
                    </v-btn>

                    <v-toolbar-title class="grey--text text--darken-1">
                        {{ $t('feelings.answerDetail.title') }}
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn
                        icon
                        @click.prevent="submit"
                        @touchstart.stop
                        @mousedown.stop
                    >
                        <v-icon
                            color="success darken-1"
                        >
                            mdi-check
                        </v-icon>
                    </v-btn>

                    <v-btn
                        icon
                        @click.prevent="onDelete"
                        @touchstart.stop
                        @mousedown.stop
                    >
                        <v-icon
                            color="red darken-1"
                        >
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </v-toolbar>
            </div>

            <div
                v-show="localLoadingCount === 0"
                class="question-dialogue__body"
            >
                <v-container
                    pt-0
                >
                    <div
                        v-for="comment in selectedComments"
                        :key="`selected-comment-${comment.id}`"
                    >
                        <v-checkbox
                            v-model="comment.selected"
                            color="primary"
                            :label="comment.body"
                        ></v-checkbox>
                    </div>

                    <div>
                        <v-textarea
                            v-if="localLoadingCount === 0"
                            v-model="customComment.body"
                            prepend-icon="mdi-pencil"
                            type="text"
                            rows="2"
                            auto-grow
                        >
                            <template #label>
                                {{ $t('form.comment.label') }}
                            </template>
                        </v-textarea>
                    </div>

                    <div>
                        <h4 class="subtitle-1">
                            {{ $t('feelings.answerDetail.gallery') }}
                        </h4>

                        <attachments
                            v-if="answer"
                            :id="answer.id"
                        ></attachments>
                    </div>
                </v-container>
            </div>
        </v-layout>
    </v-flex>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import Attachments from '@/js/components/Attachments';
import { API, getUrlWithParams } from '@/js/constants/api';

export default {
    components: {
        Attachments,
    },

    props: {
        answer: {
            type: Object,
            default: null,
        },
        markerType: {
            type: String,
            default: null,
        },
        deleteMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            clippedBottom: false,
            clippedTop: false,
            dragging: false,
            setHeight: 400,
            customComment: {
                id: null,
                body: '',
            },
            selectedComments: null,
            isNewComment: false,
            isLoading: false,
        };
    },
    computed: {
        ...mapState([
            'localLoadingCount',
        ]),
    },
    watch: {
        answer() {
            this.setDataComments();
        },
    },
    created() {
        this.setDataComments();
    },
    beforeDestroy() {
        document.removeEventListener('click', this.onClickOutside);
    },
    methods: {
        onClickOutside() {
            // Disabled because of design choice

            // if (this.$refs.draggableElement.contains(event.target)
            //     || this.clippedBottom
            //     || this.$popup.currentPopup === 'popupOnAnswerDelete') {
            //     return;
            // }
            //
            // this.setHeightMethod(0);
            // this.onClippingBottom();
        },
        async saveCustomComments() {
            try {
                // eslint-disable-next-line no-unused-vars
                let response;
                const isNewComment = this.answer;

                if (isNewComment && !isNewComment.customComment) {
                    response = await axios.post(
                        API.ANSWER_SAVE_CUSTOM_COMMENT,
                        {
                            answerId: this.answer.id,
                            body: this.customComment.body || '',
                        },
                    );
                } else {
                    response = await axios.put(
                        getUrlWithParams(API.ANSWER_UPDATE_CUSTOM_COMMENT, {
                            id: this.customComment.id,
                        }), {
                            body: this.customComment.body || '',
                        },
                    );
                }

                return response;
            } catch (error) {
                this.$store.dispatch('doAlert', {
                    text: this.errorMessage(error),
                    type: 'error',
                });

                throw new Error(this.errorMessage(error));
            }
        },
        async saveSelectedComments() {
            if (this.selectedComments && this.selectedComments.length !== 0) {
                try {
                    await this.asyncForEach(this.selectedComments, async (item) => {
                        // eslint-disable-next-line no-unused-vars
                        const data = await axios.put(
                            getUrlWithParams(API.ANSWER_UPDATE_SELECTED_COMMENT, { id: item.id }),
                            { selected: item.selected },
                        );
                    });
                } catch (error) {
                    throw new Error(this.errorMessage(error));
                }
            }
        },
        async asyncForEach(array, callback) {
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < array.length; index++) {
                // eslint-disable-next-line no-await-in-loop
                await callback(array[index], index, array);
            }
        },
        setDataComments() {
            const isAnswer = !!this.answer;
            const hasComment = isAnswer && this.answer.customComment;

            this.customComment = {
                id: hasComment && this.answer.customComment.id,
                body: (hasComment && (this.answer.customComment.body || '')) || '',
            };
            this.selectedComments = isAnswer && this.answer.selectedComments;
        },
        onDeleteConfirmation() {
            if (window.history.length > 1) {
                this.$router.go(-1);
            } else {
                this.$router.push('..');
            }

            this.$emit('update:markerType', null);
            this.$emit('update:deleteMode', false);
        },
        onDelete() {
            this.$popup.show('popupOnAnswerDelete');
        },
        onClippingTop() {
            this.clippedTop = true;
        },
        async submit() {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;
            this.$store.commit('addLocalLoadingCount');

            try {
                await Promise.all([
                    this.saveCustomComments(),
                    this.saveSelectedComments(),
                ]);

                this.clippedBottom = true;
                this.removeOutsideClickListener();

                // Prepare new instance
                this.$emit('update.answer', null);
                this.$emit('set-new-answer-instance');

                this.$store.dispatch('doAlert', {
                    text: this.$t('feelings.answerSaved'),
                    type: 'success',
                });
            } catch (e) {
                this.$store.dispatch('doAlert', {
                    text: e.message,
                    type: 'error',
                });
            }

            this.setHeightMethod(0);
            this.isLoading = false;
            this.$store.commit('decreaseLocalLoadingCount');
        },
        onDragging(event) {
            const { state } = event.detail;

            if (state) {
                this.clippedTop = false;
                this.clippedBottom = false;
            }

            this.dragging = state;
        },
        onDownClick() {
            this.clippedTop = false;
            this.setHeightMethod(400);
        },
        resetDraggable() {
            this.clippedTop = false;
            this.clippedBottom = false;
            this.setHeightMethod(400);
        },
        setOutsideClickListener() {
            document.addEventListener('click', this.onClickOutside);
        },
        removeOutsideClickListener() {
            document.removeEventListener('click', this.onClickOutside);
        },
        setHeightMethod(height) {
            this.setHeight = height;

            if (this.$refs.draggableElement) {
                this.$refs.draggableElement.style.height = `${height}px`;
            }
        },
    },
};
</script>
