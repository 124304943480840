import Vue from 'vue';
import { storage } from '@/js/utils/storage';
import { STORAGE } from '@/js/constants/storage';

export function saveUtmParams(state, params) {
    let utmParams = storage.getItem(STORAGE.UTM_PARAMS);

    if (utmParams
        && utmParams !== '') {
        state.utmParams = utmParams;
    }

    utmParams = '';
    Object.keys(params)
        .forEach((key) => {
            if (key.indexOf('utm_') !== -1
                && params[key] !== '') {
                utmParams += `&${key}=${params[key]}`;
            }
        });

    if (utmParams
        && utmParams !== '') {
        state.utmParams = utmParams.slice(1);
        storage.setItem(STORAGE.UTM_PARAMS, utmParams);
    }
}
export function addLayoutLoadingCount(state) {
    state.layoutLoadingCount += 1;
}

export function decreaseLayoutLoadingCount(state) {
    state.layoutLoadingCount -= 1;
}

export function addLocalLoadingCount(state) {
    state.localLoadingCount += 1;
}

export function decreaseLocalLoadingCount(state) {
    state.localLoadingCount -= 1;
}

export function addAlert(state, props) {
    state.alertId += 1;
    const alert = { ...props, id: state.alertId };

    state.alerts = { ...state.alerts, [state.alertId]: alert };
    state.alertCount += 1;
}

export function removeAlert(state, id) {
    Vue.delete(state.alerts, id);
    state.alertCount -= 1;
}

export function setLanguage(state, lang) {
    state.language = lang;
    storage.setItem(STORAGE.LANGUAGE, lang);
}
