<template>
    <popup
        class="popup--modal"
        disable-esc
        disable-off-click
        :name="name"
        inline
        transition="fade"
    >
        <template #close>
            <span></span>
        </template>

        <template #default="{ close }">
            <slot :close="close">
                <v-layout class="pa-4 elevation-4">
                    <v-flex>
                        <v-card
                            elevation="0"
                        >
                            <v-card-title
                                class="pb-2 pt-4"
                                primary-title
                            >
                                <h2 class="card__title">
                                    {{ $t('feelings.answerDelete.title') }}
                                </h2>
                            </v-card-title>

                            <v-card-text>
                                <p class="card__text">
                                    {{ $t('feelings.answerDelete.description') }}
                                </p>
                            </v-card-text>

                            <v-card-text>
                                <div class="card__actions-wrapper">
                                    <div class="card__action">
                                        <v-btn
                                            rounded
                                            block
                                            class="white--text my-0 font-weight-bold"
                                            color="primary"
                                            @click.prevent="close"
                                        >
                                            <v-icon
                                                class="card__icon"
                                                left
                                            >
                                                mdi-keyboard-backspace
                                            </v-icon>
                                            {{ $t('common.no') }}
                                        </v-btn>
                                    </div>

                                    <div class="card__action">
                                        <v-btn
                                            rounded
                                            block
                                            class="white--text my-0 font-weight-bold"
                                            color="error"
                                            @click.prevent="onDeleteConfirmation"
                                        >
                                            <v-icon
                                                class="card__icon"
                                                left
                                            >
                                                mdi-delete
                                            </v-icon>
                                            {{ $t('common.yes') }}
                                        </v-btn>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </slot>
        </template>
    </popup>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
    },
    methods: {
        onDeleteConfirmation() {
            this.$emit('delete-answer', this.id);
        },
    },
};
</script>
