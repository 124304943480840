<template>
    <v-container>
        <v-layout column>
            <v-flex>
                <div class="content">
                    <h1 class="content__title">
                        {{ $t('feelings.campaign.detailTitle') }}
                    </h1>

                    <div class="content__text">
                        <!--eslint-disable vue/no-v-html -->
                        <p
                            class="text-pre-format"
                            v-html="campaign.description"
                        >
                        </p>
                        <!--eslint-enable vue/no-v-html -->
                    </div>
                </div>
            </v-flex>

            <v-flex
                align-self-end
                d-flex
                shrink
            >
                <v-btn
                    color="secondary"
                    rounded
                    :to="{
                        name: $options.ROUTE.QUESTIONS,
                        params: {
                            cid: campaign.id,
                        },
                    }"
                >
                    {{ $t('feelings.campaign.start') }}
                </v-btn>
            </v-flex>
        </v-layout>

        <answer-delete-modal
            name="popup-back-confirmation"
            class="popup--small"
        >
            <template>
                <v-layout class="pa-4 elevation-4">
                    <v-flex>
                        <v-card
                            elevation="0"
                        >
                            <v-card-title
                                class="pb-2 pt-4"
                                primary-title
                            >
                                <h2 class="card__title">
                                    {{ $t('feelings.campaign.leaveTitle') }}
                                </h2>
                            </v-card-title>

                            <v-card-text>
                                <p class="card__text">
                                    {{ $t('feelings.campaign.leaveDescription') }}
                                </p>
                            </v-card-text>

                            <v-card-text>
                                <div class="card__actions-wrapper card__actions-wrapper--column">
                                    <div class="card__action">
                                        <v-btn
                                            rounded
                                            class="white--text mt-0 mb-4 mr-0 font-weight-bold"
                                            color="primary"
                                            block
                                            light
                                            @click.prevent="onContinue"
                                        >
                                            <v-icon
                                                class="card__icon"
                                                left
                                            >
                                                mdi-keyboard-backspace
                                            </v-icon>
                                            {{ $t('common.continue') }}
                                        </v-btn>
                                    </div>

                                    <div class="card__action">
                                        <v-btn
                                            rounded
                                            class="white--text my-0 font-weight-bold"
                                            color="secondary"
                                            block
                                            @click.prevent="onConfirm"
                                        >
                                            <v-icon
                                                class="card__icon"
                                                left
                                            >
                                                mdi-account
                                            </v-icon>
                                            {{ $t('common.register') }}
                                        </v-btn>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </template>
        </answer-delete-modal>
    </v-container>
</template>

<script>
import { ROUTE } from '@/js/constants/route';
import { MAP_INSTANCE } from '@/js/constants/map';

export default {
    ROUTE,

    props: {
        campaign: {
            type: Object,
            required: true,
        },
    },

    mounted() {
        this.$emit('on-set-title', this.campaign.name);

        this.$store.dispatch('map/doReset', MAP_INSTANCE.QUESTION);
    },
    methods: {
        onConfirm() {
            this.$router.push({ name: ROUTE.REGISTER });
        },
        onContinue() {
            this.$router.push({ name: ROUTE.CAMPAIGNS });
        },
    },
};
</script>

<style lang="less">
    .card__icon--fixed {
        width: 20px;
        position: static;
        transform: none;
    }
</style>
