<template>
    <v-layout column>
        <v-flex>
            <div class="layout__header relative">
                <header-component
                    :back-route="{name: $options.ROUTE.MAP}"
                    :title="this.$t($options.MENU_ITEMS.CAMPAIGNS)"
                ></header-component>

                <v-progress-linear
                    v-if="localLoadingCount"
                    class="my-0 progress--absolute"
                    indeterminate
                ></v-progress-linear>
            </div>

            <div
                v-show="!localLoadingCount"
                class="layout__list"
            >
                <list
                    :data="filteredCampaigns"
                    class="list--campaign list--even"
                >
                    <template #no-results>
                        {{ $t('feelings.campaign.noCampaignsFound') }}
                    </template>
                </list>
            </div>
        </v-flex>

        <v-flex
            class="elevation-4"
            shrink
        >
            <div class="layout__filter mt-auto">
                <v-layout column>
                    <v-flex>
                        <filter-category
                            :items="categories"
                        ></filter-category>
                    </v-flex>
                </v-layout>
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { API } from '@/js/constants/api';
import { CATEGORY_ICONS } from '@/js/constants/icons';
import daysLeft from '@/js/helpers/daysLeft';
import { MENU_ITEMS } from '@/js/constants/menu';
import { FORMAT_DEFAULT } from '@/js/constants/date';
import { format } from 'date-fns';
import { ROUTE } from '../constants/route';

export default {
    ROUTE,
    MENU_ITEMS,

    data() {
        return {
            campaigns: [],
            cities: [],
            categories: [],
            loadings: {
                campaigns: false,
                cities: false,
                categories: false,
            },
            showOnlyUserCities: null,
        };
    },
    computed: {
        ...mapState([
            'localLoadingCount',
        ]),
        isLoading() {
            return Object.values(this.loadings).some(loading => loading);
        },
        filteredCategories() {
            return this.categories.filter(category => category.isActive);
        },
        filteredCampaigns() {
            if (this.filteredCategories.length) {
                const activeCategoryIds = this.filteredCategories;

                return this.campaigns.filter((campaign) => {
                    const ids = new Set(
                        campaign.categories.map(category => category.id),
                    );

                    return activeCategoryIds.every(
                        activeCategory => ids.has(activeCategory.id),
                    );
                });
            }

            return this.campaigns;
        },
    },
    mounted() {
        this.getCampaigns();
        this.getCities();
        this.getCategories();
    },
    methods: {
        getCampaigns() {
            if (this.loadings.campaigns) {
                return;
            }

            this.loadings.campaigns = true;
            this.$store.commit('addLocalLoadingCount');
            const params = {
                endDateFrom: format(new Date(), FORMAT_DEFAULT),
            };

            axios.get(API.CAMPAIGNS, { params })
                .then(({ data }) => {
                    if (data) {
                        data.forEach((campaign) => {
                            campaign.daysLeft = daysLeft(campaign.endDate);
                            campaign.categories.forEach((category) => {
                                category.icon = CATEGORY_ICONS[category.id];
                            });
                        });
                        this.campaigns = data;
                    }
                })
                .catch((error) => {
                    this.$store.dispatch('doAlert', {
                        text: this.errorMessage(error),
                        type: 'error',
                    });
                })
                .then(() => {
                    this.loadings.campaigns = false;
                    this.$store.commit('decreaseLocalLoadingCount');
                });
        },
        getCities() {
            if (this.loadings.cities) {
                return;
            }

            this.loadings.cities = true;
            this.$store.commit('addLocalLoadingCount');

            axios.get(API.CITIES)
                .then(({ data }) => {
                    if (data) {
                        this.cities = data;
                    }
                })
                .catch((error) => {
                    this.$store.dispatch('doAlert', {
                        text: this.errorMessage(error),
                        type: 'error',
                    });
                })
                .then(() => {
                    this.loadings.cities = false;
                    this.$store.commit('decreaseLocalLoadingCount');
                });
        },
        getCategories() {
            if (this.loadings.categories) {
                return;
            }

            this.loadings.categories = true;
            this.$store.commit('addLocalLoadingCount');

            axios.get(API.CATEGORIES)
                .then(({ data }) => {
                    if (data) {
                        data.forEach((category) => {
                            category.icon = CATEGORY_ICONS[category.id];
                            category.isActive = false;
                        });
                        this.categories = data;
                    }
                })
                .catch((error) => {
                    this.$store.dispatch('doAlert', {
                        text: this.errorMessage(error),
                        type: 'error',
                    });
                })
                .then(() => {
                    this.loadings.categories = false;
                    this.$store.commit('decreaseLocalLoadingCount');
                });
        },
    },
};
</script>
