export default {
    methods: {
        renderDataHeatmap(data, {
            layer,
            weightCode,
            maxWeight = 10,
            heatmapRadius,
            heatmapOpacity = 0.2,
        }) {
            if (!layer) {
                return;
            }
            if (data.features) {
                const processed = data.features.filter(
                    (feature) => feature.geometry.type === 'Point',
                )
                    .map((feature) => this.dataHeatmap(feature, weightCode));

                const weightArray = data.features.map((feature) => {
                    // eslint-disable-next-line no-restricted-globals
                    if (Number.isNaN(+feature.properties[weightCode])) {
                        return 0;
                    }

                    return +feature.properties[weightCode];
                });

                // eslint-disable-next-line no-undef
                const heatmap = L.heatLayer(processed, {
                    radius: heatmapRadius || 30,
                    minOpacity: heatmapOpacity || 0,
                    max: maxWeight || Math.max(...weightArray) / 2,
                });

                layer.addLayer(heatmap);
            }
        },
        dataHeatmap(feature, weightCode) {
            let output = null;

            if (feature.geometry.coordinates.length > 0) {
                const coords = feature.geometry.coordinates;

                output = [
                    coords[1],
                    coords[0],
                    weightCode
                        ? feature.properties[weightCode]
                        : 1,
                ];
            }

            return output;
        },
    },
};
