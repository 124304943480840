<template>
    <v-form
        ref="form"
        @submit.prevent="validate"
    >
        <div class="py-4">
            <h1 class="h2 text-regular">
                {{ $t('form.password.change.title') }}
            </h1>
        </div>

        <div class="py-4">
            <v-text-field
                v-model="formData.oldPassword"
                :prepend-icon="$options.ITEMS.oldPassword.icon"
                :type="$options.ITEMS.oldPassword.type"
                :rules="trans($options.ITEMS.oldPassword.validators)"
                :autocomplete="$options.ITEMS.oldPassword.autocomplete"
                required
                autofocus
            >
                <template #label>
                    {{ $t($options.ITEMS.oldPassword.label) }}
                </template>

                <template #message="{message}">
                    <v-icon
                        color="red"
                        size="16"
                        class="error-message__icon"
                    >
                        mdi-alert-circle
                    </v-icon>
                    {{ message }}
                </template>
            </v-text-field>

            <v-text-field
                ref="password1"
                v-model="formData.password1"
                :prepend-icon="$options.ITEMS.password1.icon"
                :type="$options.ITEMS.password1.type"
                :rules="[...trans($options.ITEMS.password1.validators), validatorConfirmPassword]"
                :autocomplete="$options.ITEMS.password1.autocomplete"
                :hint="this.$t($options.ITEMS.password1.hint)"
                persistent-hint
                required
            >
                <template #label>
                    {{ $t($options.ITEMS.password1.label) }}
                </template>

                <template #message="{message}">
                    <v-icon
                        v-if="!$refs.password1.hasHint"
                        color="red"
                        size="16"
                        class="error-message__icon"
                    >
                        mdi-alert-circle
                    </v-icon>
                    {{ message }}
                </template>
            </v-text-field>

            <v-text-field
                v-model="formData.password2"
                :prepend-icon="$options.ITEMS.password2.icon"
                :type="$options.ITEMS.password2.type"
                :rules="trans($options.ITEMS.password2.validators)"
                :autocomplete="$options.ITEMS.password2.autocomplete"
                required
            >
                <template #label>
                    {{ $t($options.ITEMS.password2.label) }}
                </template>

                <template #message="{message}">
                    <v-icon
                        color="red"
                        size="16"
                        class="error-message__icon"
                    >
                        mdi-alert-circle
                    </v-icon>
                    {{ message }}
                </template>
            </v-text-field>
        </div>

        <v-layout
            justify-space-between
            py-4
        >
            <v-flex
                shrink
            >
                <v-btn
                    rounded
                    text
                    color="primary"
                    :to="{name: $options.ROUTE.PROFILE}"
                    exact
                >
                    {{ $t('profile.back') }}
                </v-btn>
            </v-flex>

            <v-flex
                shrink
            >
                <v-btn
                    rounded
                    color="secondary"
                    type="submit"
                    :loading="isLoading"
                    :disabled="isLoading"
                >
                    {{ $t('form.password.change.submit') }}
                </v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import axios from 'axios';
import { ERROR_ICON, USER_PROFILE } from '@/js/constants/form';
import { ROUTE } from '@/js/constants/route';
import { API } from '@/js/constants/api';

export default {
    ROUTE,
    ITEMS: USER_PROFILE.ITEMS,

    data() {
        return {
            isLoading: false,
            formData: {
                oldPassword: '',
                password1: '',
                password2: '',
            },
        };
    },
    watch: {
        'formData.password2': function formDataPassword2() {
            this.$refs.password1.validate();
        },
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.send();
            }
        },
        send() {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;

            axios({
                url: API.PASSWORD,
                method: 'post',
                data: this.formData,
            })
                .then(() => {
                    this.$store.dispatch('doAlert', {
                        type: 'success',
                        text: this.$t('form.password.change.success'),
                    });
                    this.$router.push({ name: ROUTE.PROFILE });
                })
                .catch((error) => {
                    this.$store.dispatch('doAlert', {
                        text: this.errorMessage(error),
                        type: 'error',
                    });
                    this.isLoading = false;
                });
        },
        validatorConfirmPassword(value) {
            return (value === this.formData.password2) || ['form.password.matchValidation', { icon: ERROR_ICON }];
        },
    },
};
</script>
