import 'nodelist-foreach-polyfill';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import CountryFlag from 'vue-country-flag';
import Popup from '@odyzeo/popup';
import FormItem from '@odyzeo/form-item';
import VTooltip from 'v-tooltip';

import { Icon } from 'leaflet';

import { mapGetters, mapState } from 'vuex';
import { sync } from 'vuex-router-sync';

import { TRANSLATIONS, FALLBACK_LANGUAGE, DATE } from '@/js/constants/locale';
import store from './store';
import router from './router';
import './plugins/lightgallery';
import './plugins/extendControlPositions';
import vuetify from './plugins/vuetify';

/* Component imports */
import App from './App';
import AnswerSubmitDialogue from './components/AnswerSubmitDialogue';
import AnswerDeleteModal from './components/AnswerDeleteModal';
import FilterCategory from './components/FilterCategory';

import FormRegister from './components/user/FormRegister';
import FormForgot from './components/user/FormForgot';
import FormReset from './components/user/FormReset';
import FormLogin from './components/user/FormLogin';
import FormActivate from './components/user/FormActivate';

import Header from './components/Header';
import HeaderHome from './components/HeaderHome';
import List from './components/List';
import Logo from './components/Logo';
import LeafletMap from './components/map/LeafletMap';
import MapControls from './components/map/MapControls';
import MarkerControls from './components/MarkerControls';
import MenuPrimary from './components/menu/MenuPrimary';
import Marker from './components/markers/Marker';
import Progress from './components/Progress';
import QuestionDetail from './components/QuestionDetail';
import Register from './views/user/Register';
import SpeedDial from './components/SpeedDial';

import SMap from './components/map/SMap';
import { ROUTE } from './constants/route';

import draggable from './directives/draggable';
import trans from './helpers/trans';

/* Mixins */
import networkMixin from './mixins/networkMixin';

/* Directives */
Vue.directive('draggable', draggable);

/* Components declared globally */
Vue.component('answer-delete-modal', AnswerDeleteModal);
Vue.component('answer-submit-dialogue', AnswerSubmitDialogue);
Vue.component('country-flag', CountryFlag);
Vue.component('list', List);
Vue.component('logo', Logo);
Vue.component('leaflet-map', LeafletMap);

Vue.component('form-register', FormRegister);
Vue.component('form-forgot', FormForgot);
Vue.component('form-reset', FormReset);
Vue.component('form-login', FormLogin);
Vue.component('form-activate', FormActivate);

Vue.component('filter-category', FilterCategory);
Vue.component('header-home', HeaderHome);
Vue.component('header-component', Header);
Vue.component('map-controls', MapControls);
Vue.component('marker-controls', MarkerControls);
Vue.component('marker-element', Marker);
Vue.component('menu-primary', MenuPrimary);
Vue.component('progress-bar', Progress);
Vue.component('question-detail', QuestionDetail);
Vue.component('register', Register);
Vue.component('speed-dial', SpeedDial);
Vue.component('s-map', SMap);

Vue.use(Popup);
Vue.use(FormItem);
Vue.use(VueI18n);
Vue.use(VTooltip);

Vue.config.productionTip = false;
Vue.mixin(trans);
Vue.mixin(networkMixin);
Vue.mixin({
    ROUTE,

    computed: {
        ...mapGetters('user', [
            'isLogged',
            'isLoggedSynthetic',
        ]),
        ...mapState([
            'language',
        ]),
    },
});

const unsync = sync(store, router);

// this part resolve an issue where the markers would not appear
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    /* eslint-disable global-require */
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    /* eslint-enable global-require */
});

const i18n = new VueI18n({
    locale: FALLBACK_LANGUAGE,
    messages: TRANSLATIONS,
    dateTimeFormats: {
        en: DATE.dateTimeFormats,
        sk: DATE.dateTimeFormats,
    },
});

new Vue({
    i18n,
    router,
    store,
    vuetify,

    created() {
        this.$i18n.locale = this.language;
        this.$store.commit('saveUtmParams', this.$route.query);
    },

    beforeDestroy() {
        unsync();
    },

    render: h => h(App),
}).$mount('#app');
