<template>
    <div>
        <div class="py-4">
            <h1 class="h2 text-regular">
                {{ $t('registration.title') }}
            </h1>

            <p
                v-if="response.error"
                class="error--text pt-4 mb-0 text-left text-smedium"
            >
                <v-icon color="error">
                    mdi-alert-circle
                </v-icon> {{ response.error }}
            </p>
        </div>

        <div class="py-4">
            <form-register @error="onError"></form-register>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            response: {
                error: null,
            },
        };
    },

    methods: {
        onError(error) {
            this.response.error = error;
        },
    },
};
</script>
