<template>
    <div
        v-if="data"
        class="layer-legend"
    >
        <div class="layer-legend__item">
            <div
                class="layer-legend__label"
                v-text="data.minValue"
            ></div>
        </div>
        <div class="layer-legend__item">
            <div
                class="layer-legend__gradient"
                :style="gradient"
            ></div>
        </div>
        <div
            class="layer-legend__item"
        >
            <div
                class="layer-legend__label"
                v-text="data.maxValue"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LayerLegend',
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        gradient() {
            if (!this.data) {
                return null;
            }

            return {
                backgroundImage: `linear-gradient(${this.data.minColor}, ${this.data.maxColor})`,
            };
        },
    },
};
</script>
<style lang="less">
@import '../../../less/variables';

.layer-legend {
    position: absolute;
    right: 20px;
    bottom: 50px;

    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 0 0 auto;
    z-index: 10000;

    @media(min-width: @break-t-min) {
        bottom: 10px;
    }
}

.layer-legend__gradient {
    height: 100px;
    width: 25px;
    border: 1px solid gray;
}

.layer-legend__label {
    color: #fff;
    font-weight: 700;
    font-size: @font-size--smedium;
    margin-bottom: 10px;
    text-shadow:
            -1px -1px 0 #000,
            1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
}

.layer-legend__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    padding: 5px 0;

    &:last-child {
        .layer-legend__label {
            order: 2;
            margin: 10px 0 0;
        }
    }
}
.layer-legend__color {
    width: 35px;
    height: 20px;
    border: 1px solid #fff;
}
</style>
