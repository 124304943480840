<template>
    <div class="layer-control elevation-6">
        <slot name="close"></slot>
        <div
            class="layer-control__container layer-control__container--tileset"
        >
            <h2
                class="layer-control__title"
            >
                {{ $t('map.layerControl.tilesets.title') }}
            </h2>

            <div
                v-for="layer in $options.TILESETS"
                :key="`layer-control-tileset-${layer.code}`"
                class="layer-control__item"
            >
                <v-flex
                    class="layer-control__layer"
                    offset-y
                >
                    <div
                        class="layer-control__card"
                        :class="{'layer-control__card--active':
                            activeTileset === layer.code}"
                        @click.prevent="switchTileset(layer.code)"
                    >
                        <div class="layer-control__icon">
                            <v-icon
                                :color="layer.iconColor"
                            >
                                {{ layer.icon }}
                            </v-icon>
                        </div>

                        <div class="layer-control__text text-center">
                            {{ $t(layer.name) }}
                        </div>
                    </div>
                </v-flex>
            </div>
        </div>

        <div
            v-if="subjectiveData && subjectiveData.length > 0"
            class="layer-control__container
                           layer-control__container--feelings"
        >
            <h2
                class="layer-control__title"
            >
                {{ $t('map.layerControl.subjective.title') }}
            </h2>

            <div
                v-for="layer in subjectiveData"
                :key="`layer-emotion-${layer.id}`"
                class="layer-control__item"
            >
                <layer-control-layer
                    :layer="layer"
                    :active-view="activeView(layer)"
                ></layer-control-layer>
            </div>
        </div>
        <div
            v-if="objectiveData && objectiveData.length > 0"
            class="layer-control__container
                           layer-control__container--feelings"
        >
            <h2
                class="layer-control__title"
            >
                {{ $t('map.layerControl.objective.title') }}
            </h2>

            <div
                v-for="layer in objectiveData"
                :key="`layer-emotion-${layer.id}`"
                class="layer-control__item"
            >
                <layer-control-layer
                    :layer="layer"
                    :active-view="activeView(layer)"
                ></layer-control-layer>
            </div>
        </div>
    </div>
</template>

<script>
import { LAYERS, EMOTION_QUESTIONS } from '@/js/constants/mocks';
import {
    LEAFLET_MAP,
    TILESET_CODE,
    TILESETS,
} from '@/js/constants/map';
import LayerControlLayer from '@/js/components/map/LayerControlLayer';

export default {
    LAYERS,
    LEAFLET_MAP,
    EMOTION_QUESTIONS,
    TILESETS,
    name: 'LayerControl',
    components: {
        LayerControlLayer,
    },
    props: {
        activeLayers: {
            type: Object,
            default: () => ({}),
        },
        subjectiveData: {
            type: Array,
            default: null,
        },
        objectiveData: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            activeTileset: TILESET_CODE.DEFAULT,
        };
    },
    computed: {
        activeView() {
            return (layer) => this.activeLayers[layer.id];
        },
    },
    methods: {
        switchTileset(code) {
            this.activeTileset = code;

            this.$root.$emit('switch-active-tile-set', this.activeTileset);
        },
    },
};
</script>
