<template>
    <div
        class="map-layer-control"
        :class="{'is-open': layerControlVisible}"
    >
        <v-btn
            v-tooltip="{
                content: $t('mapLayerControl.tooltip'),
                show: tooltipToggle,
                trigger: 'manual',
                placement: 'bottom',
                classes: ['map-layer-control__tooltip'],
            }"
            class="map-layer-control__btn my-0"
            color="white"
            fab
            small
            @click.prevent="toggleNavigation"
        >
            <slot></slot>
        </v-btn>

        <v-container
            v-show="layerControlVisible"
            class="map-layer-control__container pa-0"
        >
            <v-layout column>
                <v-flex>
                    <layer-control
                        :active-layers="activeLayers"
                        :subjective-data="subjectiveData"
                        :objective-data="objectiveData"
                    >
                        <template #close>
                            <v-btn
                                icon
                                x-small
                                class="map-layer-control__close"
                                @click.prevent="toggleNavigation"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                    </layer-control>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import LayerControl from '@/js/components/map/LayerControl';

export default {
    name: 'MapLayerControl',
    components: {
        LayerControl,
    },
    props: {
        subjectiveData: {
            type: Array,
            default: null,
        },
        objectiveData: {
            type: Array,
            default: null,
        },
        activeLayers: {
            type: Object,
            default: null,
        },
        hasLayers: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            layerControlVisible: false,
            tooltipToggle: !this.hasLayers,
        };
    },
    watch: {
        hasLayers(v) {
            this.tooltipToggle = !v;
        },
    },
    methods: {
        toggleNavigation() {
            this.layerControlVisible = !this.layerControlVisible;
            if (this.layerControlVisible && this.hasLayers) {
                this.tooltipToggle = false;
            }

            this.$emit('open-menu', this.layerControlVisible);
        },
    },
};
</script>

<style lang="less">
@import './src/less/variables';

@top: 73px;

.map-layer-control {
    position: absolute;
    top: @top;
    right: 15px;
    z-index: 6;

    &.is-open {
        height: calc(100% - @top);
    }

    @media(max-width: @break-m-max) {
        right: 0;
    }
}

.map-layer-control__tooltip.tooltip {
    z-index: 5;
    margin: 0;
}

.map-layer-control__container {
    position: relative;
    height: 100%;
    overflow: auto;
}

.v-btn.map-layer-control__btn {
    position: absolute;
    right: 0;
    z-index: 0;
    top: 0;
    margin-right: 0;

    @media(max-width: @break-m-max) {
        margin-right: 15px;
    }
}

.map-layer-control__close.v-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
}
</style>
