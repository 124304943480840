/**
 * Custom dragging directive with options
 * and callbacks on clipping either top / bottom part of
 * screen / options;
 *
 * Custom events:
 * - on-clipping-top
 * - on-clipping-bottom
 * - on-dragging
 */
export default {
    inserted(el, binding, vnode) {
        el.dragger_element = el;
        el.dragger_options = {
            offset: 100,
            parentHeight: null,
            document_height: null,
            client_mouse_y_pos: null,
            client_y_pos: null,
            height: null,
            min_height: 20,
            min_clipping_height: 0,
            max_height: null,
            clipping_top_distance: 150,
            clipping_bottom_distance: 150,
            custom_event: null,
            dragger: (
                binding.value
                && binding.value.element
                && el.querySelectorAll(binding.value.element)
                )
                || el.dragger_element,
        };

        function createCustomEventInstance(eventName, args = null) {
            if (vnode.componentInstance) {
                vnode.componentInstance.$emit(eventName, { detail: args });
            } else {
                el.dragger_options.custom_event = new CustomEvent(eventName, { detail: args });

                vnode.elm.dispatchEvent(el.dragger_options.custom_event);
            }
        }
        function clipTo(type) {
            if (type === 'top') {
                el.dragger_options.height = el.dragger_options.document_height;
                createCustomEventInstance('on-clipping-top');
            } else {
                el.dragger_options.height = el.dragger_options.min_clipping_height;
                createCustomEventInstance('on-clipping-bottom');
            }
        }
        function setupDragger(ev, isInitial = false) {
            const pos = el.dragger_element.getBoundingClientRect();
            const clientY = ev.clientY || (ev.touches && ev.touches[0].clientY);

            if (isInitial) {
                el.dragger_options.document_height = document.body.clientHeight;
                el.dragger_options.height = el.dragger_element.offsetHeight;
                el.dragger_options.client_mouse_y_pos = clientY - pos.top;
                el.dragger_options.max_height = el.dragger_options.document_height;
            } else {
                el.dragger_options.client_y_pos = pos.top;
            }
        }
        function processDragging(ev) {
            setupDragger(ev);

            const clientY = ev.clientY || ev.touches[0].clientY;

            el.dragger_options.height = el.dragger_options.document_height
                    - (clientY - el.dragger_options.client_mouse_y_pos);

            if (el.dragger_options.height > el.dragger_options.max_height) {
                el.dragger_options.height = el.dragger_options.max_height;
            } else if (el.dragger_options.height < el.dragger_options.min_height) {
                el.dragger_options.height = el.dragger_options.document_height;
                el.dragger_options.height = el.dragger_options.min_height;
            }

            createCustomEventInstance('on-dragging', { state: true });
            el.dragger_element.style.height = `${el.dragger_options.height}px`;
        }
        function endDragging(type) {
            if (type === 'touch') {
                window.removeEventListener('touchmove', processDragging);
                window.removeEventListener('touchend', el.dragger_EndFunction);
            } else {
                window.removeEventListener('mousemove', processDragging);
                window.removeEventListener('mouseup', el.dragger_EndFunction);
            }

            if (el.dragger_options.document_height
                - el.dragger_options.height < el.dragger_options.clipping_top_distance) {
                clipTo('top');
            } else if (el.dragger_options.height
                < el.dragger_options.clipping_bottom_distance) {
                clipTo('bottom');
            }

            createCustomEventInstance('on-dragging', { state: false });
            el.dragger_element.style.height = `${el.dragger_options.height}px`;
        }
        function initDragging(ev) {
            setupDragger(ev, true);

            if (ev.type === 'touchstart') {
                el.dragger_EndFunction = endDragging.bind(this, 'touch');

                window.addEventListener('touchmove', processDragging);
                window.addEventListener('touchend', el.dragger_EndFunction);
            } else {
                el.dragger_EndFunction = endDragging.bind(this, 'mouse');

                window.addEventListener('mousemove', processDragging);
                window.addEventListener('mouseup', el.dragger_EndFunction);
            }
            createCustomEventInstance('on-dragging', { state: true });
        }
        function init() {
            const setuperFunc = setupDragger.bind(this, true);
            el.dragger_InitFunction = initDragging;
            el.dragger_options = { ...el.dragger_options, ...binding.value.options };

            if (el.dragger_options.height) {
                el.dragger_element.style.height = `${el.dragger_options.height}px`;
            }

            el.dragger_options.dragger.forEach((element) => {
                element.addEventListener('touchstart', el.dragger_InitFunction);
                element.addEventListener('mousedown', el.dragger_InitFunction);
            });
            window.addEventListener('resize', setuperFunc);
        }

        init();
    },
    unbind(el) {
        // Unbind events; Cleanup
        el.dragger_options.dragger.forEach((element) => {
            element.removeEventListener('touchstart', el.dragger_InitFunction);
            element.removeEventListener('mousedown', el.dragger_InitFunction);
        });
    },
};
