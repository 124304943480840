<template>
    <div class="smap-wrap d-flex">
        <div
            ref="smap"
            class="smap"
            @touchmove.prevent
        >
            <slot
                :map-methods="{ addAdditionalLayer }"
                :map="map"
                :geometry-layer="geometryLayer"
            ></slot>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { MAP_OPTIONS } from '@/js/constants/map';

export default {
    MAP_OPTIONS,

    props: {
        persistentPos: {
            type: String,
            default: null,
        },
        options: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            map: null,
            layer: null,
            searchLayer: null,
            geometryLayer: null,
            markerLayer: null,
            isInitialized: false,
        };
    },
    computed: {
        ...mapState('map', [
            'zoom',
            'position',
        ]),
        localZoom() {
            return this.options && this.options.zoom;
        },
        localPosition() {
            return this.options && this.options.position;
        },
    },
    watch: {
        map(n) {
            if (n) {
                if (this.isInitialized) {
                    return;
                }

                this.isInitialized = true;
                this.$emit('mapLoaded', true);
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.initMap({ ...MAP_OPTIONS });
        });
    },
    beforeDestroy() {
        if (this.persistentPos) {
            const position = this.map.getCenter();
            const zoom = this.map.getZoom();
            const posData = {
                [this.persistentPos]: position,
            };
            const zoomData = {
                [this.persistentPos]: zoom,
            };

            this.$store.dispatch('map/doSetPosition', posData);
            this.$store.dispatch('map/doSetZoom', zoomData);
        }

        this.map.$destructor();
    },
    methods: {
        initMap(options) {
            this.createMap(options);
            this.createPoiMarkerLayer();
            this.createGeometryLayer();
            this.enableLayers();
            this.addResizeMapControll();
        },
        createMap(options) {
            const centerCoords = this.localPosition
                ? Object.values(this.localPosition)
                : options.CENTER;
            let center = new SMap.Coords(...centerCoords);
            let zoom = this.localZoom || options.ZOOM;

            if (this.persistentPos) {
                if (this.position && this.position[this.persistentPos]) {
                    const pos = this.position[this.persistentPos];
                    center = new SMap.Coords(pos.x, pos.y);
                }

                if (this.zoom && this.zoom[this.persistentPos]) {
                    zoom = this.zoom[this.persistentPos];
                }
            }

            this.map = new SMap(JAK.gel(this.$refs.smap), center, zoom);
        },
        enableLayers() {
            this.map.addDefaultLayer(SMap.DEF_BASE).enable();
        },
        createPoiMarkerLayer() {
            const layer = new SMap.Layer.Marker(undefined, {
                poiTooltip: true,
            });

            this.addAdditionalLayer(layer);
            layer.enable();
        },
        createGeometryLayer() {
            this.geometryLayer = new SMap.Layer.Geometry();

            this.addAdditionalLayer(this.geometryLayer);
            this.geometryLayer.enable();
        },
        addAdditionalLayer(layer) {
            this.map.addLayer(layer);
        },
        addResizeMapControll() {
            const sync = new SMap.Control.Sync();

            this.map.addControl(sync);
        },
    },
};
</script>
