<template>
    <div>
        <template
            v-if="ready && isLoggedSynthetic"
        >
            <div class="py-4">
                <h1 class="h2 text-regular">
                    {{ $t('login.title') }}
                </h1>
            </div>

            <div class="py-4">
                <form-login
                    external
                    @logged="onLogged"
                ></form-login>
            </div>
        </template>

        <v-progress-circular
            v-else
            indeterminate
            color="primary"
        ></v-progress-circular>
    </div>
</template>

<script>
import { EXTERNAL_ORIGINS } from '../../constants/external';

export default {
    data() {
        return {
            ready: false,
        };
    },
    created() {
        this.$root.$on('userLoaded', () => {
            if (this.isLogged && !this.isLoggedSynthetic) {
                this.onLogged(this.$store.state.user.token);
            }
            this.ready = true;
        });
    },
    methods: {
        onLogged(token) {
            // we are top window - we are not in frame
            if (window.parent === window) {
                return;
            }

            const origin = EXTERNAL_ORIGINS.find(
                (orig) => document.referrer.startsWith(orig),
            );

            if (origin) {
                window.parent.postMessage({
                    type: 'login',
                    token,
                }, origin);
            }
        },
    },
};
</script>
