<template>
    <v-container>
        <v-flex
            md6
            mx-auto
            text-center
        >
            <div class="my-4 relative">
                <logo></logo>
                <div class="logo logo--civita">
                    <a
                        target="_blank"
                        href="https://www.civitacenter.sk/"
                    >
                        <img
                            src="@/assets/cropped-Logo_lezate_male.jpg"
                            alt="civita-center-logo"
                            class="logo__image"
                        >
                    </a>
                </div>
                <v-btn
                    v-if="!$route.meta.noRedirect"
                    absolute
                    top
                    right
                    fab
                    small
                    text
                    exact
                    color="primary"
                    :to="{ name: $options.ROUTE.MAP }"
                    data-test="auth-button-close"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <router-view></router-view>
        </v-flex>
    </v-container>
</template>

<script>
import { ROUTE } from '@/js/constants/route';

export default {
    ROUTE,
};
</script>
