import * as d3 from 'd3';

export default {
    created() {
        this.fixOnRemovePrototype();
    },
    methods: {
        renderDataHexgrid(data, layer, origin) {
            if (!layer) {
                return;
            }

            const processed = data.features.filter(
                (feature) => feature.geometry.type === 'Point',
            )
                .map((feature) => this.dataHexgrid(feature));

            // eslint-disable-next-line no-undef
            const hexgrid = L.hexbinLayer({
                radius: 18,
                opacity: 0.5,
                duration: 500,
                colorRange: ['white', origin.color],
                radiusRange: [18, 18],
            });
            hexgrid.data(processed);
            layer.addLayer(hexgrid);
        },
        dataHexgrid(processed) {
            let output = null;

            if (processed?.geometry.coordinates.length > 0) {
                const coords = processed.geometry.coordinates;
                output = [
                    coords[0],
                    coords[1],
                ];
            }

            return output;
        },
        fixOnRemovePrototype() {
            /* eslint-disable func-names,no-undef,no-underscore-dangle */
            L.HexbinLayer.prototype.onRemove = function (map) {
                L.SVG.prototype.onRemove.call(this);
                this._destroyContainer();
                map.off({ moveend: this.redraw }, this);
                this._map = null;
                d3.select(this._container).remove();
            };
            /* eslint-enable func-names,no-undef,no-underscore-dangle */
        },
    },
};
