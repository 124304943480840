<template>
    <div class="menu-primary">
        <v-navigation-drawer
            :value="toggle"
            fixed
            temporary
            class="menu-primary__menu"
            width="300"
            @input="updateToggle"
        >
            <v-list class="menu-primary__header py-0">
                <v-list-item
                    :to="{ name: $options.ROUTE.ECAREWAVE_INDEX }"
                    class="menu-primary__logo py-0 no-hover-bg"
                >
                    <v-list-item-content>
                        <div class="logo">
                            <img
                                src="@/assets/logo.png"
                                srcset="@/assets/logo@2x.png 2x,
                                        @/assets/logo@3x.png 3x"
                                class="logo__image"
                            >
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-list
                class="py-0"
            >
                <v-divider></v-divider>

                <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    :to="item.url"
                >
                    <v-list-item-action
                        class="menu-primary__item-icon"
                    >
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title
                            class="menu-primary__item-title"
                        >
                            {{ $t(item.title) }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-list
                class="py-0 mt-auto"
            >
                <v-divider></v-divider>

                <v-list-group
                    class="menu-primary__group"
                >
                    <template v-slot:activator>
                        <v-list-item
                            class="menu-primary__item-title"
                        >
                            <v-list-item-action
                                class="menu-primary__item-icon"
                            >
                                <country-flag
                                    :country="$t('languages.icon')"
                                    size="small"
                                ></country-flag>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ $t('languages.current') }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-list-item
                        v-for="lang in $options.LANGUAGES"
                        :key="lang.CODE"
                        color="#222"
                        @click.prevent="setLanguage(lang.CODE)"
                    >
                        <v-list-item-action
                            class="menu-primary__item-icon"
                        >
                            <country-flag
                                :country="lang.ICON"
                                size="small"
                            ></country-flag>
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-list-item-title
                                class="menu-primary__item-title"
                            >
                                {{ $t(lang.NAME) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>

                <template v-if="isLogged && !isLoggedSynthetic">
                    <v-divider></v-divider>

                    <v-list-item

                        :to="{name: $options.ROUTE.PROFILE}"
                    >
                        <v-list-item-action
                            class="menu-primary__item-icon"
                        >
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-list-item-title
                                class="menu-primary__item-title"
                            >
                                {{ $t($options.MENU_ITEMS.PROFILE) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item

                        @click.prevent="logout"
                    >
                        <v-list-item-action
                            class="menu-primary__item-icon"
                        >
                            <v-icon>mdi-power</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-list-item-title
                                class="menu-primary__item-title"
                            >
                                {{ $t($options.MENU_ITEMS.LOGOUT) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>

                <template v-else>
                    <v-divider></v-divider>

                    <v-list-item
                        :to="{name: $options.ROUTE.LOGIN}"
                    >
                        <v-list-item-action
                            class="menu-primary__item-icon"
                        >
                            <v-icon>mdi-exit-to-app</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-list-item-title
                                class="menu-primary__item-title"
                            >
                                {{ $t($options.MENU_ITEMS.LOGIN) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { ROUTE } from '@/js/constants/route';
import { MENU_ITEMS } from '@/js/constants/menu';
import { LANGUAGES } from '@/js/constants/locale';
import logoutMixin from '@/js/mixins/logoutMixin';

export default {
    ROUTE,
    MENU_ITEMS,
    LANGUAGES,

    mixins: [logoutMixin],

    props: {
        items: {
            type: Array,
            default: () => ([]),
        },
        toggle: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        updateToggle(state) {
            this.$emit('update:toggle', state);
        },
        setLanguage(lang) {
            this.$i18n.locale = lang;
            this.$store.dispatch('doSetLanguage', lang);
        },
    },
};
</script>

<style lang="less">
    .menu-primary__menu {
        .v-list {
            .v-list-item--active:before,
            .v-list-item--active:after {
                background: none;
            }
        }

        .v-list-item {
            position: relative;
            height: 56px;

            line-height: 24px;
            text-align: left;
        }
    }

    .menu-primary__item-title {
        padding-left: 0;
    }

    .menu-primary__group {
        .v-list-group__items {
            background: #efefef;
        }
    }
</style>
