import { geoJSON } from 'leaflet';
import proj4 from 'proj4';

export function cleanGeoJson(data) {
    const obj = [];

    data.forEach((item) => {
        if (item.geometry.coordinates.length > 0) {
            obj.push(item);
        }
    });

    return obj;
}

export function prepareGeoJSONData(data = [], { options, projection, layerGroup }) {
    let geoJsonLayer;

    // TODO: remove projections when this
    //  is confirmed to be processed throught BE
    if (projection) {
        proj4.defs(
            projection.name,
            projection.def,
        );

        geoJsonLayer = L.Proj.geoJson();
    } else {
        geoJsonLayer = geoJSON();
    }

    if (layerGroup) {
        layerGroup.mapObject.addLayer(geoJsonLayer);
    }

    geoJsonLayer.options = options;
    geoJsonLayer.addData(data);

    return geoJsonLayer;
}
