<template>
    <v-dialog
        v-model="isVisibleModel"
        fullscreen
        hide-overlay
        persistent
        transition="fade-transition"
        class="question-detail"
    >
        <v-layout
            column
            fill-height
        >
            <v-flex shrink>
                <header-component
                    :back-cb="goBack"
                    :title="question.name"
                >
                    <template #append>
                        <v-btn
                            icon
                            @click.prevent="hideModal"
                        >
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </template>
                </header-component>
            </v-flex>

            <v-flex class="d-flex">
                <div class="question-detail__content">
                    <v-container fill-height>
                        <v-layout
                            column
                            fill-height
                        >
                            <v-flex>
                                <!--eslint-disable vue/no-v-html -->
                                <h1
                                    class="question-detail__title h2"
                                    v-html="question.longDescription"
                                >
                                </h1>
                                <!--eslint-enable vue/no-v-html -->
                            </v-flex>

                            <v-flex shrink>
                                <div class="question-detail__actions">
                                    <div class="question-detail__actions-title py-4">
                                        {{ $t('feelings.selectAnswerType') }}
                                    </div>

                                    <v-container
                                        pa-0
                                        ma-0
                                        grid-list-md
                                        fluid
                                    >
                                        <v-layout
                                            v-if="mapLoaded"
                                        >
                                            <v-flex
                                                v-for="(button, index) in question.markers"
                                                :key="index"
                                            >
                                                <div class="question-detail__action-item">
                                                    <v-btn
                                                        v-if="!isButtonEnabled(
                                                            button.type.toLowerCase(),
                                                        )"
                                                        :disabled="areControlsDisabled"
                                                        fab
                                                        small
                                                        large
                                                        class="mx-0 mb-4"
                                                        color="primary"
                                                        @click.prevent="prepareMarkerType(
                                                            $event,
                                                            // eslint-disable-next-line max-len
                                                            $options.MAP_MARKER_TYPE_API_PAIRINGS[button.type].TYPE
                                                        )"
                                                    >
                                                        <v-icon color="white">
                                                            <!-- eslint-disable-next-line max-len-->
                                                            {{ $options.MAP_MARKER_TYPE_API_PAIRINGS[button.type].ICON }}
                                                        </v-icon>
                                                    </v-btn>

                                                    <v-btn
                                                        v-else
                                                        fab
                                                        small
                                                        large
                                                        class="mx-0 mb-4"
                                                        color="secondary"
                                                    >
                                                        <v-icon color="white">
                                                            <!-- eslint-disable-next-line max-len-->
                                                            {{ $options.MAP_MARKER_TYPE_API_PAIRINGS[button.type].ICON }}
                                                        </v-icon>
                                                    </v-btn>

                                                    <span class="question-detail__actions-title">
                                                        <!-- eslint-disable-next-line max-len-->
                                                        {{ $t($options.MAP_MARKER_TYPE_API_PAIRINGS[button.type].TEXT) }}
                                                    </span>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </div>
            </v-flex>
        </v-layout>
    </v-dialog>
</template>

<script>
import {
    MAP_MARKER_TYPES,
    MAP_MARKER_TYPE_API_PAIRINGS,
} from '@/js/constants/map';
import { MARKER_ICONS } from '@/js/constants/icons';

export default {
    MAP_MARKER_TYPES,
    MAP_MARKER_TYPE_API_PAIRINGS,
    MARKER_ICONS,

    props: {
        question: {
            type: Object,
            required: true,
        },
        isVisible: {
            type: Boolean,
            default: false,
        },
        markerType: {
            type: String,
            default: null,
        },
        activeMode: {
            type: Boolean,
            default: false,
        },
        mapLoaded: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isVisibleModel: false,
            message: false,
            areControlsDisabled: false,
        };
    },
    computed: {
        isButtonEnabled() {
            return (type) => this.markerType === type;
        },
    },
    watch: {
        isVisible(n) {
            this.isVisibleModel = n;
        },
    },
    mounted() {
        this.showMessage(this.getActionType);
    },
    methods: {
        setAreControlsDisable(state) {
            this.areControlsDisabled = state;
        },
        hideModal() {
            this.$emit('update:isVisible', false);
        },
        goBack() {
            this.$router.go(-1);
        },
        prepareMarkerType(ev, type) {
            ev.preventDefault();

            this.$nextTick(() => {
                this.$emit('ready-marker-type', type);
            });

            this.hideModal();
        },
        showMessage(actionState) {
            if (!actionState) {
                this.$emit('update:isVisible', true);
                this.isVisibleModel = true;
            }
        },
    },
};
</script>
