<template>
    <div>
        <section class="wrapper intro wow fadeInBottom">
            <div
                class="container wow fadeInUp"
                data-wow-offset="50"
                data-wow-duration="700"
            >
                <h1 class="intro__title">
                    {{ $t('pages.home.title') }}
                </h1>
                <p class="intro__content">
                    {{ $t('pages.home.subtitle') }}
                </p>
                <v-btn
                    color="white"
                    :to="{name: $options.ROUTE.MAP}"
                    class="mx-0 mt-4"
                    large
                    type="button"
                >
                    {{ $t('pages.home.menu.map') }}
                </v-btn>
            </div>
        </section>

        <section class="wrapper section section--00">
            <div class="section__row section__row--reverse">
                <div class="bubble wow fadeInTop bubble--extreme"></div>

                <div class="section__column">
                    <div class="section__container">
                        <h2 class="section__title">
                            {{ $t('pages.home.heading0') }}
                        </h2>
                        <!--eslint-disable-next-line vue/no-v-html-->
                        <div v-html="$t('pages.home.content0')">
                        </div>
                    </div>
                </div>
                <div class="section__column">
                    <div class="bubble wow fadeInTop bubble--small bubble--bottom"></div>

                    <div
                        class="section__image wow fadeInUp"
                        data-wow-offset="50"
                        data-wow-duration="700"
                    >
                        <img
                            src="@/assets/home/img-00.jpg"
                            alt=""
                            loading="lazy"
                        >
                    </div>
                </div>
            </div>
        </section>

        <section
            id="how"
            class="wrapper section section--01"
        >
            <div class="section__row">
                <div class="bubble wow fadeInTop bubble--big bubble--left"></div>

                <div class="section__column">
                    <div class="section__container">
                        <h2 class="section__title">
                            {{ $t('pages.home.heading1') }}
                        </h2>
                        <!--eslint-disable-next-line vue/no-v-html-->
                        <div v-html="$t('pages.home.content1')">
                        </div>

                        <div class="section__mobile">
                            <img
                                src="@/assets/home/mobile-home.png"
                                class="mobile--01 wow fadeInLeft"
                                loading="lazy"
                            >
                            <img
                                src="@/assets/home/mobile-profile.png"
                                class="mobile--02 wow fadeInLeft"
                                loading="lazy"
                            >
                        </div>
                    </div>
                </div>
                <div class="section__column">
                    <div class="bubble wow fadeInTop bubble--small bubble--bottom"></div>

                    <div
                        class="section__image wow fadeInUp"
                        data-wow-offset="50"
                        data-wow-duration="700"
                    >
                        <img
                            src="@/assets/home/img-01.jpg"
                            alt=""
                            loading="lazy"
                        >
                    </div>
                </div>
            </div>
        </section>

        <section class="wrapper section section--02">
            <div class="section__row section__row--reverse">
                <div class="bubble wow fadeInTop bubble--extreme"></div>

                <div class="section__column">
                    <div class="section__container">
                        <h2 class="section__title">
                            {{ $t('pages.home.heading2') }}
                        </h2>
                        <!--eslint-disable-next-line vue/no-v-html-->
                        <div v-html="$t('pages.home.content2')">
                        </div>

                        <div class="section__mobile">
                            <img
                                src="@/assets/home/mobile-mapping.png"
                                class="mobile--03 wow fadeInRight"
                                loading="lazy"
                            >
                            <img
                                src="@/assets/home/mobile-answer-detail.png?0"
                                class="mobile--04 wow fadeInRight"
                                loading="lazy"
                            >
                        </div>
                    </div>
                </div>
                <div class="section__column">
                    <div class="bubble wow fadeInTop bubble--small bubble--bottom"></div>

                    <div
                        class="section__image wow fadeInUp"
                        data-wow-offset="50"
                        data-wow-duration="700"
                    >
                        <img
                            src="@/assets/home/img-02.jpg"
                            alt=""
                            loading="lazy"
                        >
                    </div>
                </div>
            </div>
        </section>

        <section class="wrapper section section--03">
            <div class="section__row">
                <div class="bubble wow fadeInTop bubble--big bubble--left"></div>

                <div class="section__column">
                    <div class="section__container">
                        <h2 class="section__title">
                            {{ $t('pages.home.heading3') }}
                        </h2>
                        <!--eslint-disable-next-line vue/no-v-html-->
                        <div v-html="$t('pages.home.content3')">
                        </div>

                        <div class="section__mac">
                            <img
                                src="@/assets/home/mac-home.png?1"
                                class="wow fadeInUp"
                                data-wow-offset="50"
                                data-wow-duration="700"
                                loading="lazy"
                            >
                        </div>
                    </div>
                </div>
                <div class="section__column">
                    <div class="bubble wow fadeInTop bubble--small bubble--bottom"></div>

                    <div
                        class="section__image wow fadeInUp"
                        data-wow-offset="50"
                        data-wow-duration="700"
                    >
                        <img
                            src="@/assets/home/img-03.jpg"
                            loading="lazy"
                        >
                    </div>
                </div>
            </div>
        </section>

        <section class="wrapper section section--04">
            <div class="section__row section__row--reverse">
                <div class="bubble wow fadeInTop bubble--extreme"></div>

                <div class="section__column">
                    <div class="section__container">
                        <h2 class="section__title">
                            {{ $t('pages.home.heading4') }}
                        </h2>
                        <!--eslint-disable-next-line vue/no-v-html-->
                        <div v-html="$t('pages.home.content4')">
                        </div>

                        <div class="section__mac">
                            <img
                                src="@/assets/home/mac-layers.png"
                                class="wow fadeInUp"
                                data-wow-offset="50"
                                data-wow-duration="700"
                                loading="lazy"
                            >
                        </div>
                    </div>
                </div>
                <div class="section__column">
                    <div class="bubble wow fadeInTop bubble--small bubble--bottom"></div>

                    <div
                        class="section__image wow fadeInUp"
                        data-wow-offset="50"
                        data-wow-duration="700"
                    >
                        <img
                            src="@/assets/home/img-04.jpg"
                            loading="lazy"
                        >
                    </div>
                </div>
            </div>
        </section>

        <!--
        <section class="wrapper section section--05">
            <div class="section__row">
                <div class="bubble wow fadeInTop bubble--big bubble--left"></div>

                <div class="section__column">
                    <div class="section__container">
                        <h2 class="section__title">
                            {{ $t('pages.home.heading5') }}
                        </h2>
                        <div v-html="$t('pages.home.content5')">
                        </div>

                        <div class="section__mac">
                            <img
                                src="@/assets/home/mac-donut.png?0"
                                alt=""
                                class="wow fnUpdeIn" data-wow-offset=50
                            >
                        </div>
                    </div>
                </div>
                <div class="section__column">
                    <div class="bubble wow fadeInTop bubble--small bubble--bottom"></div>

                    <div class="wow fnUpdeIn  data-wow-offset=50nfc">
                        <img
                            src="@/assets/home/img-nfc-bg.jpg"
                            alt=""
                            class="section__image nfc__image"
                        >
                        <img
                            src="@/assets/home/img-nfc.png"
                            alt=""
                            class="nfc__icon"
                        >
                    </div>
                </div>
            </div>
        </section>

        <div
            id="info"
            class="wrapper contact"
        >
            <div class="container">
                <div class="flex flex--grid">
                    <div class="flex-1-2">
                        <h3 class="contact__title">
                            Stay informed
                        </h3>
                        <p>
                            If you want to experience the CareWave educational
                            and prevention program at your school, do not
                            hesitate to leave us a message. Thank you for your support!
                        </p>
                    </div>
                    <div class="flex-1-2">
                        <form
                            class="form js-contact"
                            method="POST"
                            action="contact.php"
                        >
                            <label class="form__label">
                                <span class="form__title">Your e-mail</span>
                                <input
                                    class="form__input"
                                    name="mail"
                                >
                            </label>
                            <label class="form__label">
                                <span class="form__title">School name</span>
                                <input
                                    class="form__input"
                                    name="school"
                                >
                            </label>
                            <label class="form__label">
                                <span class="form__title">City</span>
                                <input
                                    class="form__input"
                                    name="city"
                                >
                            </label>
                            <label class="form__label">
                                <span class="form__title">Country</span>
                                <input
                                    class="form__input"
                                    name="country"
                                >
                            </label>
                            <div class="form__success"></div>
                            <div class="form__error"></div>
                            <button class="btn btn--submit form__btn js-contact-submit">
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        -->
    </div>
</template>

<script>
import ecarewave from '@/js/mixins/ecarewave';

export default {
    name: 'EcarewaveIndex',
    mixins: [ecarewave],
};
</script>

<style lang="less" scoped>
@import '~wow.js/css/libs/animate.css';
@import (reference) '../../less/variables';
@import '../../less/pages/home/app';

.main-wrapper {
    width: 100%;

    color: #728695;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 1.67;
    -webkit-font-smoothing: antialiased;

    @media (min-width: @break-t-min) {
        font-size: 18px;
    }
}

a.v-btn {
    text-decoration: none !important;
}

.section__image {
    max-width: 700px;

    img {
        width: 100%;
        display: block;
    }
}
</style>
