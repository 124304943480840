export const ROUTE = {
    HOME: 'home',
    LOGIN: 'login',
    LOGIN_EXTERNAL: 'login-external',
    LOGOUT: 'logout',
    REGISTER: 'register',
    ACTIVATE: 'activate',
    FORGOT: 'forgot',
    RESET: 'reset',
    MAP: 'map',
    FEELING_MAPS: 'feeling-maps',
    ECAREWAVE: '/',
    ECAREWAVE_INDEX: '/',
    ECAREWAVE_ABOUT: 'about',

    PROFILE: 'profile',
    PROFILE_NAME: 'profile-name',
    PROFILE_MAIL: 'profile-mail',
    PROFILE_PASSWORD: 'profile-password',
    PROFILE_PHONE: 'profile-phone',
    PROFILE_CITY: 'profile-city',
    PROFILE_REMOVE_ACCOUNT: 'profile-remove-account',

    GUIDELINES: 'guidelines',
    CAMPAIGNS: 'campaigns',
    CAMPAIGN: 'campaign',
    QUESTIONS: 'questions',
    QUESTION: 'question',
    DEMOGRAPHY: 'demography',
};
