import { initEcarewave } from '@/js/helpers/ecarewave/app';

export default {
    mounted() {
        document.body.classList.remove('has-active-slide-menu');

        this.$nextTick(() => {
            initEcarewave();
        });
    },
};
