<template>
    <v-container>
        <v-flex
            md6
            mx-auto
            class="content"
        >
            <h1
                class="content__title"
            >
                {{ $t('demography.aboutYou') }}
            </h1>

            <v-form
                v-if="questions"
                ref="form"
                @submit.prevent="validate"
            >
                <v-radio-group
                    v-model="formData.ageCategoryId"
                    :name="$options.ITEMS.age.name"
                    :rules="trans($options.ITEMS.age.validators)"
                >
                    <template #label>
                        {{ $t($options.ITEMS.age.label) }}&nbsp;
                        <span class="error--text">*</span>
                    </template>
                    <v-radio
                        v-for="option in questions.ageCategories"
                        :key="`age-${option.id}`"
                        :value="option.id"
                        color="primary"
                    >
                        <template #label>
                            <template
                                v-if="option.max > 100"
                            >
                                {{ option.min }}+
                            </template>
                            <template
                                v-else
                            >
                                {{ option.min }} - {{ option.max }}
                            </template>
                        </template>
                    </v-radio>

                    <template #message="{message}">
                        <v-icon
                            color="red"
                            size="16"
                            class="error-message__icon"
                        >
                            error
                        </v-icon>
                        {{ message }}
                    </template>
                </v-radio-group>

                <v-radio-group
                    v-model="formData.gender"
                    :name="$options.ITEMS.gender.name"
                    :rules="trans($options.ITEMS.gender.validators)"
                >
                    <template #label>
                        {{ $t($options.ITEMS.gender.label) }}&nbsp;
                        <span class="error--text">*</span>
                    </template>
                    <v-radio
                        v-for="(option, i) in questions.genders"
                        :key="`gender-${i}`"
                        :label="getGender(option)"
                        :value="option"
                        color="primary"
                    ></v-radio>

                    <template #message="{message}">
                        <v-icon
                            color="red"
                            size="16"
                            class="error-message__icon"
                        >
                            error
                        </v-icon>
                        {{ message }}
                    </template>
                </v-radio-group>

                <v-text-field
                    v-model="formData.email"
                    :prepend-icon="$options.ITEMS.email.icon"
                    :type="$options.ITEMS.email.type"
                    :rules="trans($options.ITEMS.email.validators)"
                    :required="$options.ITEMS.email.required"
                    error-count="3"
                    autocomplete="email"
                >
                    <template #label>
                        {{ $t($options.ITEMS.email.label) }}
                        <span
                            v-if="$options.ITEMS.email.required"
                            class="error--text"
                        >*</span>
                    </template>
                </v-text-field>

                <v-checkbox
                    v-model="formData.newsletterApproval"
                    :label="this.$t('demography.newsletter')"
                    color="primary"
                ></v-checkbox>

                <p class="content__text">
                    {{ $t('demography.disclaimer') }}
                </p>

                <p class="content__text">
                    <span class="error--text">*</span>
                    {{ $t('form.mandatoryFields') }}
                </p>

                <v-layout
                    justify-end
                    row
                >
                    <v-btn
                        rounded
                        color="secondary"
                        type="submit"
                        :loading="isLoading"
                        :disabled="isLoading"
                    >
                        {{ $t('demography.submit') }}
                    </v-btn>
                </v-layout>
            </v-form>
        </v-flex>
    </v-container>
</template>

<script>
import axios from 'axios';
import { API } from '@/js/constants/api';
import { DEMOGRAPHY } from '@/js/constants/form';
import { ROUTE } from '@/js/constants/route';

export default {
    ITEMS: DEMOGRAPHY.ITEMS,

    props: {
        campaign: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isLoading: false,
            questions: null,
            formData: {
                ageCategoryId: null,
                gender: null,
                email: this.$store.state.user.user.email,
                newsletterApproval: false,
            },
        };
    },
    created() {
        this.getQuestions();
    },
    mounted() {
        this.$emit('on-set-title', this.campaign.name);
    },
    methods: {
        async getQuestions() {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;
            this.$store.commit('addLayoutLoadingCount');

            try {
                const { data } = await axios.get(API.DEMOGRAPHY);

                Object.values(data).forEach((datum) => {
                    datum.value = null;
                });

                this.questions = data;
            } catch (error) {
                this.$store.dispatch('doAlert', {
                    text: this.errorMessage(error),
                    type: 'error',
                });
            }

            this.isLoading = false;
            this.$store.commit('decreaseLayoutLoadingCount');
        },
        getGender(value) {
            switch (value) {
            case 'MALE':
                return this.$t('form.gender.options.male');
            case 'FEMALE':
                return this.$t('form.gender.options.female');
            case 'DIVERSE':
                return this.$t('form.gender.options.diverse');
            default:
                return value;
            }
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.send();
            }
        },
        send() {
            if (this.isLoading) return;
            this.isLoading = true;

            axios({
                url: API.SAVE_DEMOGRAPHY,
                method: 'post',
                data: {
                    ...this.formData,
                    userId: this.$store.state.user.user.id,
                    campaignId: +this.$route.params.cid,
                },
            })
                .then(() => {
                    this.$store.dispatch('doAlert', {
                        type: 'success',
                        text: this.$t('demography.success'),
                    });

                    this.$emit('on-refresh-campaign-details');

                    this.$router.push({
                        name: ROUTE.QUESTIONS,
                        params: {
                            cid: this.$route.params.cid,
                        },
                    });
                })
                .catch((error) => {
                    this.$store.dispatch('doAlert', {
                        text: this.errorMessage(error),
                        type: 'error',
                    });
                })
                .then(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
